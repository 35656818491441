import { RouteObject } from "react-router-dom";
import RealTimeDashboard from "../Pages/RealTimeDashboard/RealTimeDashboard";

export const REAL_TIME_DASHBOARD = "realtimedashboard";

const realTimeDashboardRoutes: RouteObject[] = [
  { path: REAL_TIME_DASHBOARD, element: <RealTimeDashboard /> },
];

export default realTimeDashboardRoutes;

import { RouteObject } from "react-router-dom";
import List from "../Pages/Visitor/List";
import View from "../Pages/Visitor/View";
import RoomView from "../Pages/Visitor/RoomView";
import AiimagesView from "../Pages/Visitor/AiimagesView";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  VIEW_AI_IMAGE,
  VIEW_ROOM,
  VIEW_VISITOR,
} from "../Utils/PermissionsEnum";

export const VISITOR_LIST = "visitor";
export const VISITOR_VIEW = ":id/view";
export const VISITOR_ROOMVIEW = ":roomId/room";
export const VISITOR_AIIMAGESVIEW = ":id/aiimage";
export const ORGANIZATION_VISITOR_VIEW =
  "/" + VISITOR_LIST + "/" + VISITOR_VIEW;

const visitorRoutes: RouteObject[] = [
  {
    path: VISITOR_LIST,
    element: <AuthorizedRoute element={<List />} permission={VIEW_VISITOR} />,
  },
  {
    path: `${VISITOR_LIST}/${VISITOR_VIEW}`,
    element: <AuthorizedRoute element={<View />} permission={VIEW_VISITOR} />,
  },
  {
    path: `${VISITOR_LIST}/${VISITOR_VIEW}/${VISITOR_ROOMVIEW}`,
    element: <AuthorizedRoute element={<RoomView />} permission={VIEW_ROOM} />,
  },
  {
    path: `${VISITOR_LIST}/${VISITOR_VIEW}/${VISITOR_ROOMVIEW}/${VISITOR_AIIMAGESVIEW}`,
    element: (
      <AuthorizedRoute element={<AiimagesView />} permission={VIEW_AI_IMAGE} />
    ),
  },
];

export default visitorRoutes;

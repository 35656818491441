import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Col, Container, Modal, Row, Image, Card } from "react-bootstrap";
import { setToast } from "../../Utils/Toast";
import { useOrganization } from "../../Utils/OrganizationContext";
import useAxiosInstance from "../../Utils/Headers";
import { Products } from "../../Models/Products";
import { handleImageError } from "../../Components/Noimage";
import CustomSkeleton from "../../Components/CustomSkeleton";

const initialProductState = {
  id: "",
  title: "",
  description: "",
  width: "",
  height: "",
  metadata: "",
  is_for_wall: "",
  is_for_floor: "",
  product_type_id: "",
  image_url: "",
  image_url_mid: "",
  image_url_small: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  product_name: "",
  product_name_slug: "",
  product_type: {
    id: "",
    title: "",
    parent_type_id: "",
    organization_id: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    user_id: "",
  },
};

function View() {
  const navigate = useNavigate();
  const { id }: any = useParams();
  let { selectedOrganizationId }: any = useOrganization();
  const axiosInstance = useAxiosInstance();

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(initialProductState);
  const [hasImageError, setHasImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fallBackSrc = "/images/svg/noimage.svg";
  const apiUrl = `${process.env.REACT_APP_API_URL}api/products/${id}`;

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line
  }, [selectedOrganizationId]);

  const fetchProduct = async () => {
    try {
      const product: any = await Products.$query()
        .with(["productType"])
        .find(id);
      setData(product?.$response?.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(apiUrl);
      setToast("success", "Product deleted successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
  useEffect(() => {
    if (data.image_url !== fallBackSrc) {
      setHasImageError(false);
    }
  }, [data.image_url]);
  const handleImageErrorWithState = (e: any) => {
    handleImageError(e, fallBackSrc, "100%", "100px");
    setHasImageError(true);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col lg={12} className="text-end mt-2">
          <button className="btn back-btn btn-sm" onClick={() => navigate(-1)}>
            Back
          </button>
          <button
            className="btn delete-btn btn-sm ms-2"
            onClick={() => setShowModal(true)}
          >
            Delete
          </button>
        </Col>

        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header className="border-0 justify-content-end">
            <img
              src="/images/svg/closeicon.svg"
              alt="close"
              className="custom-close-icon"
              onClick={() => setShowModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="text-center">
            <h3>Are you sure you want to delete?</h3>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <button
              className="btn delete-btn text-white btn-sm"
              onClick={handleDelete}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
        <Col
          lg={7}
          md={12}
          sm={12}
          className="d-flex justify-content-center card align-items-center mt-3 product-col"
        >
          {isLoading ? (
            <CustomSkeleton height={470} />
          ) : (
            <Image
              src={data.image_url}
              className={`img-fluid object-fit-contain ${
                !hasImageError ? "full-image" : ""
              }`}
              onError={handleImageErrorWithState}
            />
          )}
        </Col>

        <Col lg={4} className="mt-2 ms-3">
          <Card className="mt-1 product-details">
            {isLoading ? (
              <CustomSkeleton
                customInnerStyle={{ marginBottom: "8px" }}
                count={13}
                height={25}
              />
            ) : (
              <>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {data.product_name}
                </Card.Header>
                <Card.Body className="product-details-scroll">
                  <table className="table fs-6 mt-1">
                    <tbody>
                      <tr>
                        <td className="fw-bold col-sm-5">Id</td>
                        <td className="ms-3 col-sm-7">{data.id}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Metadata</td>
                        <td>{data.metadata}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Product name slug</td>
                        <td>{data.product_name_slug}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Product type</td>
                        <td>{data.product_type?.title}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Title</td>
                        <td>{data.title}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Width</td>
                        <td>{data.width}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Height</td>
                        <td>{data.height}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">For wall</td>
                        <td>{data.is_for_wall}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">For floor</td>
                        <td>{data.is_for_floor}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Created Date</td>
                        <td>{moment(data.created_at).format("DD-MMM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Updated Date</td>
                        <td>{moment(data.updated_at).format("DD-MMM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Deleted Date</td>
                        <td>{moment(data.deleted_at).format("DD-MMM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Description</td>
                        <td>{data.description}</td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default View;

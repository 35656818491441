import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utils/Headers";
import { Button, Card, Image } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { VISITOR_ROOMVIEW } from "../../../Routes/visitorRoutes";
import { handleImageError } from "../../../Components/Noimage";
import CustomSkeleton from "../../../Components/CustomSkeleton";

interface Rooms {
  id: number;
  name: string;
  image: string;
}

interface RoomProps {
  id: any;
}

const Room: React.FC<RoomProps> = ({ id }) => {
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [Rooms, setRooms] = useState<Rooms[]>([]);
  const [initialImages, setInitialImages] = useState(10);
  const [loadMoreImages] = useState(5);
  const [loading, setLoading] = useState(true);
  const paginatedRooms = Rooms.slice(0, initialImages);
  const fallBackSrc = "/images/svg/noimage.svg";

  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    roomID: number,
  ) => {
    e.preventDefault();
    navigate(
      VISITOR_ROOMVIEW.replace(":visitorId", id).replace(
        ":roomId",
        roomID.toString(),
      ),
    );
  };

  useEffect(() => {
    searchRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchRoom = async () => {
    const visitorid = `visitor_id=${id}`;
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/rooms/search?${visitorid}`,
        {
          scopes: [
            {
              name: "Organization",
            },
          ],
        },
      );

      const roomsData = response.data.data;
      setRooms(roomsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching room data:", error);
      setLoading(false);
    }
  };

  const loadMoreImagesHandler = () => {
    setInitialImages(initialImages + loadMoreImages);
  };

  return (
    <>
      <div className="mt-4">
        <Card className="min-height-card-visitor">
          <Card.Header className="m-1">Rooms</Card.Header>
          <Card.Body className="row  d-flex justify-content-evenly">
            {loading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <div
                  className="col-lg-2 col-md-3 col-sm-4 col-12 m-3 p-0"
                  key={index}
                >
                  <div className="w-100 d-flex justify-content-center align-items-center overflow-hidden room-box">
                    <CustomSkeleton
                      height={130}
                      width={"100%"}
                      borderRadius="4px"
                    />
                  </div>
                </div>
              ))
            ) : paginatedRooms.length === 0 ? (
              <div className="text-center text-dark">No rooms to display</div>
            ) : (
              paginatedRooms.map((roomsData) => (
                <div
                  className="col-lg-2 col-md-3 col-sm-4 col-12 m-3 p-0"
                  key={roomsData.id}
                >
                  <div className="w-100 d-flex justify-content-center align-items-center overflow-hidden room-box">
                    <Image
                      src={roomsData.image}
                      className="image-box"
                      onError={(e) =>
                        handleImageError(e, fallBackSrc, "100%", "100px")
                      }
                    />
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      variant="primary-light"
                      size="sm"
                      className="me-2 btn-sm"
                      onClick={(e) => handleRowShowButtonClick(e, roomsData.id)}
                    >
                      <Eye /> View
                    </Button>
                  </div>
                </div>
              ))
            )}
            {paginatedRooms.length > 0 && (
              <div className="text-center mt-3">
                {initialImages < Rooms.length ? (
                  <button
                    className="btn addnew-btn btn-sm"
                    onClick={loadMoreImagesHandler}
                  >
                    Load More
                  </button>
                ) : (
                  <button className="btn back-btn btn-sm" disabled>
                    Load More
                  </button>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Room;

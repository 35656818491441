import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Image, Modal } from "react-bootstrap";
import BalanceChart from "./Charts.tsx/BalanceChart";
import useAxiosInstance from "../../../Utils/Headers";
import moment from "moment";
import { setToast } from "../../../Utils/Toast";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGOUT } from "../../../Routes/AppRoutes";
import VisitorLocationChart from "./Charts.tsx/VisitorLocationChart";
import { Cookies } from "typescript-cookie";
import { PencilFill } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import { handleImageError } from "../../../Components/Noimage";

interface OverviewProps {
  organizationID: number;
  data: any;
  startDate: any;
  endDate: any;
}

interface CountData {
  aiImage_count: number;
  visitor_count: number;
  balance: number;
}

const Overview: React.FC<OverviewProps> = ({
  organizationID,
  data,
  startDate,
  endDate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosInstance = useAxiosInstance();
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const SelectedAccount = Cookies.get("selectedAccount");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [selectedFastImage, setSelectedFastImage] = useState<File | null>(null);
  const [selectedLastImage, setSelectedLastImage] = useState<File | null>(null);
  // eslint-disable-next-line
  const [firstlastimageUploaded, setfirstlastimageUploaded] = useState(false);
  const [countData, setCountData] = useState<CountData>({
    aiImage_count: 0,
    visitor_count: 0,
    balance: 0,
  });
  const fileInputRefFast = useRef<HTMLInputElement | null>(null);
  const fileInputRefLast = useRef<HTMLInputElement | null>(null);
  const fallBackSrc = "/images/svg/noimage.svg";

  const handleNavigate = (key: any) => {
    const newUrl = `${location.pathname}?activeKey=settings`;
    navigate(newUrl);
  };

  useEffect(() => {
    FetchCountData();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedFastImage || selectedLastImage) {
      handleFastandLastImageUpload();
    }
    // eslint-disable-next-line
  }, [selectedFastImage, selectedLastImage]);

  const FetchCountData = async () => {
    const requestBody = {
      organization_id: organizationID,
      start_date: moment(startDate).format("MM-DD-YYYY"),
      end_date: moment(endDate).format("MM-DD-YYYY"),
    };

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/all_count_by_organization`,
        requestBody,
      );

      if (response.status === 200) {
        const data = response.data;
        setCountData({
          aiImage_count: Number(data.data.aiImage_count),
          visitor_count: Number(data.data.visitor_count),
          balance: Number(data.data.balance),
        });
      } else {
        console.error("Error fetching profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (Number(inputValue) < 0) {
      setError(true);
    } else {
      setError(false);
      try {
        const addbalance = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}api/organization/${organizationID}/add_balance`,
          { amount: inputValue },
        );

        setToast("success", addbalance.data.message);
        FetchCountData();
        setShowModal(false);
        setInputValue(" ");
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const statusCode = error.response.status;
            if (statusCode === 401) {
              navigate(LOGOUT);
            }
          }
        }
      }
    }
  };

  const handleFileInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<File | null>>,
  ) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setImage(selectedFile);
  };

  const handleUploadClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleImageUpload = async (
    formData: FormData,
    apiEndpoint: string,
    successMessage: string,
  ) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}${apiEndpoint}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setToast("success", response.data.message);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFastandLastImageUpload = async () => {
    if (selectedFastImage || selectedLastImage) {
      const fastData = new FormData();
      fastData.append("uuid", data.uuid); // Use data.uuid
      if (selectedFastImage) {
        fastData.append("catalog_front_page", selectedFastImage);
      }
      if (selectedLastImage) {
        fastData.append("catalog_last_page", selectedLastImage);
      }
      handleImageUpload(
        fastData,
        "api/upload_catalog_pages",
        "Image uploaded successfully",
      );
      setfirstlastimageUploaded(true);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h1 className="m-0">Organization Details</h1>
              <button
                className="btn edit-btn btn-sm border-0"
                onClick={() => handleNavigate("settings")}
              >
                Edit Profile
              </button>
            </Card.Header>
            <Card.Body>
              <div className="profile-details">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Organization Name:
                      </th>
                      <td>
                        <strong>{data.name}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Organization Handle:
                      </th>
                      <td>
                        <strong>{data.organization_handle}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Contact Number:
                      </th>
                      <td>
                        <strong>{data.contact_number}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Email:
                      </th>
                      <td>
                        <strong>{data.email}</strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Address:
                      </th>
                      <td>
                        <strong>
                          {data.address},&nbsp;
                          {data.city}, &nbsp;
                          {data.state},&nbsp;{data.country?.name},&nbsp;
                          {data.postal_code}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Industries type:
                      </th>
                      <td>
                        {data.industries_type?.title ? (
                          <strong>{data.industries_type?.title}</strong>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Frontground Color:
                      </th>
                      <td>
                        <div className="d-flex align-items-center mt-1 p-1 bg-white rounded-2 border-0">
                          <input
                            type="color"
                            className="p-0 form-control ms-2 color-box"
                            value={data.theme_fg_color || ""}
                            disabled
                            readOnly
                          />
                          <input
                            type="text"
                            className="form-control border-0"
                            value={data.theme_fg_color || ""}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="text-secondary">
                        Background color:
                      </th>
                      <td>
                        <div className="d-flex align-items-center mt-1 p-1 bg-white rounded-2 border-0">
                          <input
                            type="color"
                            className="p-0 form-control ms-2 color-box"
                            value={data.theme_bg_color || ""}
                            disabled
                            readOnly
                          />
                          <input
                            type="text"
                            className="form-control border-0"
                            value={data.theme_bg_color || ""}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-4 col-md-12">
          <Card className="float-end w-98">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h1 className="m-0">Balance</h1>
              <button
                className="btn addnew-btn btn-sm"
                onClick={handleShowModal}
              >
                Add Balance
              </button>
            </Card.Header>
            <Card.Body className="align-middle mt-3 mb-1">
              <div className="mb-5">
                <BalanceChart />
              </div>
              <div className="text-center balancecount">
                <span className="fs-1">
                  Available Balance: <strong>{countData.balance}</strong>
                </span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <Card className="float-start w-98">
            <Card.Header className="text-center">
              <h1 className="m-0">Visitors by Locations</h1>
            </Card.Header>
            <Card.Body>
              <VisitorLocationChart />
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-8 col-md-12">
          <Card>
            <Card.Header className="text-center">
              <h1 className="m-0">Catalog Image</h1>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap mt-2 justify-content-center">
              <div className="col-12 col-md-6 mt-5 catalog-image-div">
                <h3 className="text-center fw-bold mt-2">Catalog First Page</h3>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <div className="catalog d-flex justify-content-center align-items-center p-2 shadow-lg rounded">
                    {(organizationIdFromCookie || SelectedAccount) && (
                      <div
                        className="shadow catalog-page"
                        onClick={() => handleUploadClick(fileInputRefFast)}
                      >
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={(e: any) =>
                            handleFileInputChange(e, setSelectedFastImage)
                          }
                          ref={fileInputRefFast}
                          className="d-none"
                        />
                        <PencilFill className="text-secondary text-center mt-2" />
                      </div>
                    )}
                    {data.catalog_front_page ? (
                      <Image
                        className="img-fluid"
                        src={data.catalog_front_page}
                        rounded
                        onError={(e) =>
                          handleImageError(e, fallBackSrc, "100%", "100px")
                        }
                      />
                    ) : (
                      <Skeleton width={80} height={80} />
                    )}
                  </div>
                </div>
                <p className="text-center mt-4 mb-5 text-secondary">
                  Set the logo image. Only*.png,*.jpg and <br /> *.jpeg image
                  files are accepted
                </p>
              </div>
              <div className="col-12 col-md-6 mt-5 catalog-image-div">
                <h3 className="text-center fw-bold mt-2">Catalog Last Page</h3>
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <div className="catalog d-flex justify-content-center align-items-center p-2 shadow-lg rounded">
                    {(organizationIdFromCookie || SelectedAccount) && (
                      <div
                        className="shadow catalog-page"
                        onClick={() => handleUploadClick(fileInputRefLast)}
                      >
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={(e: any) =>
                            handleFileInputChange(e, setSelectedLastImage)
                          }
                          ref={fileInputRefLast}
                          className="d-none"
                        />
                        <PencilFill className="text-secondary text-center mt-2" />
                      </div>
                    )}
                    {data.catalog_last_page ? (
                      <Image
                        className="img-fluid"
                        src={data.catalog_last_page}
                        rounded
                        onError={(e) =>
                          handleImageError(e, fallBackSrc, "100%", "100px")
                        }
                      />
                    ) : (
                      <Skeleton width={80} height={80} />
                    )}
                  </div>
                </div>
                <p className="text-center mt-4 mb-5 text-secondary">
                  Set the logo image. Only*.png,*.jpg and <br /> *.jpeg image
                  files are accepted
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
          <Modal.Title>Add Balance</Modal.Title>
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon mb-4"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="inputValue">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control
                type="number"
                className="form-control form-control-lg form-control-solid"
                placeholder="Enter Amount"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                isInvalid={error}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid amount.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex mt-4 justify-content-end">
              <button type="submit" className="btn addnew-btn btn-sm">
                Add Balance
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Overview;

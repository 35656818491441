import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { TileCoverages } from "../../Models/TileCoverages";
import { Link, useNavigate } from "react-router-dom";
import { Pencil } from "react-bootstrap-icons";
import axios from "axios";
import { fetchData } from "../../Utils/OrionList";
import {
  TILE_COVERAGES_ADD,
  TILE_COVERAGES_EDIT,
} from "../../Routes/TileCoveragesRoutes";
import { LOGOUT } from "../../Routes/AppRoutes";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface TileCoverageData {
  id: number;
  width: string;
  height: string;
  coverage_percentage: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "Tiles Coverages";

  const [data, setData] = useState<TileCoverageData[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues] = useState({});
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleRowEditButtonClick = (id: number) => {
    navigate(TILE_COVERAGES_EDIT.replace(":id", id.toString()));
  };

  const columns: TableColumn<TileCoverageData>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "20%",
      wrap: true,
    },
    {
      name: "Width",
      selector: (row) => row.width,
      maxWidth: "30%",
      sortable: true,
      sortField: "width",
    },
    {
      name: "Height",
      selector: (row) => row.height,
      maxWidth: "30%",
      sortable: true,
      sortField: "height",
    },
    {
      name: "Actions",
      minWidth: "20%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];
  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
    </DropdownButton>
  );

  // ==============================================
  const fetchTileCoverages = async (page: number | undefined) => {
    setLoading(true);

    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new TileCoverages(),
        sortField,
        sortOrder,
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            navigate(LOGOUT);
          }
        } else {
          console.log("An error occurred:", error.message);
        }
      } else {
        console.log("An unknown error occurred:", error);
      }
    }
  };

  const handlePageChange = (page: number | undefined) => {
    fetchTileCoverages(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new TileCoverages(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <h2>{title} List</h2>
          </Col>
          <Col className="text-md-end">
            <Link to={`${TILE_COVERAGES_ADD}`}>
              <button className="btn addnew-btn mb-2 btn-sm">
                Add&nbsp;{title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Eye } from "react-bootstrap-icons";
import moment from "moment";
import { Visitor } from "../../Models/Visitor";
import { fetchData } from "../../Utils/OrionList";
import { VISITOR_VIEW } from "../../Routes/visitorRoutes";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import { getUser } from "../../Utils/Auth";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import CustomSkeleton from "../../Components/CustomSkeleton";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
import useAxiosInstance from "../../Utils/Headers";
import { setToast } from "../../Utils/Toast";

interface VisitorItem {
  visitor_id: string;
  visitor_ip: string;
  email: string;
  mobile_number: string;
  organization_id: number;
  id: number;
  created_at: string;
  is_blocked: number;
}

interface UserData {
  name: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();

  let title = "Visitors";
  const [data, setData] = useState<VisitorItem[]>([]); // Use the appropriate type here
  const [currentPage, setCurrentPage] = useState<any>(); // Add this state for current page
  const axiosInstance = useAxiosInstance();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [user, setUser] = useState<UserData | string | undefined>(undefined);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [showModal, setShowModal] = useState(false);
  const [selectedVisitorId, setSelectedVisitorid] = useState<number | null>(
    null,
  );
  const [actionType, setActionType] = useState<"block" | "unblock">();
  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();

    navigate(VISITOR_VIEW.replace(":id", id.toString()));
  };
  const columns: TableColumn<VisitorItem>[] = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
    },
    {
      name: "Mobile Number",
      wrap: true,
      selector: (row) => row.mobile_number,
    },
    {
      name: "Date",
      wrap: true,
      sortable: true,
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <ButtonGroup>
            <Button
              variant="primary-light"
              size="sm"
              className="me-2 btn-sm btn-font"
              onClick={(e) => handleRowShowButtonClick(e, row.id)}
            >
              <Eye /> View
            </Button>
            {row.is_blocked === 0 ? (
              <Button
                variant="danger-light"
                size="sm"
                className="ms-2 btn-sm btn-font"
                style={{ width: "99px" }}
                onClick={() => handleActionClick(row.id, "block")}
              >
                <MdBlock className="mb-1" /> Block
              </Button>
            ) : (
              <Button
                variant="success-light"
                size="sm"
                className="ms-2 btn-sm btn-font"
                onClick={() => handleActionClick(row.id, "unblock")}
              >
                <CgUnblock className="mb-1" /> UnBlock
              </Button>
            )}
          </ButtonGroup>
        </>
      ),
    },
  ];

  // ==============================================
  const fetchVisitor = async (page: number | undefined) => {
    setLoading(true);
    setCurrentPage(page);
    const fetchedUserData = getUser();
    if (fetchedUserData) {
      setUser(fetchedUserData);
      const name = typeof user === "object" ? user.name : user;
      selectedOrganizationId =
        name === "Super Admin"
          ? selectedOrganizationId
          : fetchedUserData.organization_id;
    }
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Visitor(),
        sortField,
        sortOrder,
      );
    } catch (error) {}
  };

  const handlePageChange = (page: number | undefined) => {
    fetchVisitor(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "email",
      value: "email",
      label: "Email",
      fieldType: "email",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "mobile_number",
      value: "mobile_number",
      label: "Mobile Number",
      fieldType: "tel",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created At ",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Visitor(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================
  const handleBlockVisitor = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/visitor/${id}/block`,
      );
      setToast("success", response.data.message);
      fetchVisitor(currentPage);
    } catch (error: any) {
      setToast("error", error);
    }
  };

  const handleUnBlockVisitor = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/visitor/${id}/unblock`,
      );
      setToast("success", response.data.message);
      fetchVisitor(currentPage);
    } catch (error: any) {
      setToast("error", error);
    }
  };
  const handleActionClick = (userId: number, action: "block" | "unblock") => {
    setSelectedVisitorid(userId);
    setActionType(action);
    setShowModal(true); // Show confirmation modal
  };

  const handleConfirmAction = () => {
    if (selectedVisitorId && actionType) {
      if (actionType === "block") {
        handleBlockVisitor(selectedVisitorId);
      } else {
        handleUnBlockVisitor(selectedVisitorId);
      }
    }
    setShowModal(false); // Close the modal after confirmation
  };
  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>
        <Row className="mt-4">
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={fetchVisitor}
          />
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="text-center p-1">
          <div>
            <h3>Confirm Action</h3>
            <h5> Are you sure you want to {actionType} this Visitor?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-4 d-flex justify-content-center">
          <button
            className="btn addnew-btn btn-sm"
            onClick={handleConfirmAction}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default List;

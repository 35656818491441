import { useState, useRef, useEffect } from "react";
import { Col, Collapse, Form, Row } from "react-bootstrap";
import Select, { components, OptionProps, StylesConfig } from "react-select";
export type InputValues = { [key: string]: {} };

interface CustomFilterProps {
  options: any[];
  inputValues: InputValues;
  setInputValues: Function;
  selectedOptions: any[];
  setSelectedOptions: Function;
  getData: Function;
}

type CustomOptionProps = OptionProps<any, true, any>;
export default function CustomFilter({
  options,
  inputValues,
  setInputValues,
  selectedOptions,
  setSelectedOptions,
  getData,
}: CustomFilterProps) {
  const [open, setOpen] = useState(false);
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSearch = () => {
    getData();
  };

  const handleInputChange = (optionId: any, inputKey: any, inputValue: any) => {
    if (inputValue) {
      setInputValues((prevInputValues: { [x: string]: any }) => ({
        ...prevInputValues,
        [optionId]: {
          ...prevInputValues[optionId],
          [inputKey]: inputValue,
        },
      }));
    } else {
      setInputValues((prevInputValues: { [x: string]: any }) => {
        const updatedInputValues = { ...prevInputValues };
        delete updatedInputValues[optionId][inputKey];
        return updatedInputValues;
      });
      if (searchButtonRef.current) {
        searchButtonRef.current.click();
      }
    }
  };

  const handleOptionChange = (selected: any) => {
    setSelectedOptions(selected);
    const unselected = selectedOptions.filter(
      (prevOption) =>
        !selected.some(
          (currentOption: { value: any }) =>
            currentOption.value === prevOption.value,
        ),
    );

    unselected.forEach((option) => {
      setInputValues((prevInputValues: { [x: string]: any }) => {
        const updatedInputValues = { ...prevInputValues };
        delete updatedInputValues[option.id];
        return updatedInputValues;
      });
    });
  };

  useEffect(() => {
    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, [inputValues]);

  const Option = (props: CustomOptionProps) => (
    <div className="">
      <components.Option {...props}>
        <div>
          <p>{props.data.label}</p>
        </div>
      </components.Option>
    </div>
  );

  const customStyles: StylesConfig<any, true, any> = {
    control: (provided: any) => ({
      ...provided,
      background: "#F9F9F9",
      border: "0px",
    }),
    // You can add more style customizations here as needed
  };

  return (
    <div className="col-lg-10 col-md-8 mb-2">
      <button
        onClick={() => setOpen(!open)}
        aria-controls="Filter"
        aria-expanded={open}
        className="btn addnew-btn btn-sm"
      >
        <b>Filter</b>
      </button>
      <Collapse in={open} className="m-3 p-3 filter-collapse">
        <Row>
          <Col>
            <div className="d-flex align-items-start w-100">
              <div className="w-100 me-4">
                <Select
                  isMulti
                  options={options}
                  isSearchable
                  placeholder="Select options..."
                  components={{ Option: Option as any }}
                  onChange={handleOptionChange}
                  value={selectedOptions}
                  styles={customStyles}
                />
              </div>
              <button
                className="btn edit-btn"
                onClick={handleSearch}
                ref={searchButtonRef}
              >
                Search
              </button>
            </div>
            <Row className="mt-3">
              {selectedOptions.map((option: any) => (
                <Col sm={10} className="m-2 " key={option.id}>
                  <Row>
                    <Col className="col-lg-2 d-flex align-items-center">
                      <span
                        className="align-middle fw-bold filter-label"
                        id="addon-wrapping"
                      >
                        {option.label}
                      </span>
                    </Col>
                    <Col className="col-lg-4">
                      <div>
                        <Form.Select
                          className="filter-input"
                          placeholder="Filter Operation"
                          aria-label="Filter Operation"
                          onChange={(e) => {
                            handleInputChange(
                              option.id,
                              "filter_operation",
                              e.target.value,
                            );
                          }}
                        >
                          {Object.entries(option.allowedFilter).map(
                            ([key, valueOpt]) => (
                              <option key={key} value={valueOpt as string}>
                                {key}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col className="col-lg-4">
                      <input
                        type={option.fieldType}
                        className="form-control form-control-lg form-control-solid rounded h-100 filter-input"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        onChange={(e) => {
                          handleInputChange(option.id, "value", e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
}

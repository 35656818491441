import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { setToast } from "../../Utils/Toast";
import { getUser } from "../../Utils/Auth";
import { setCookie } from "typescript-cookie";
import useAxiosInstance from "../../Utils/Headers";
import axios from "axios";
import { Card, Col, Container, Row, Form } from "react-bootstrap";
import { useUser } from "../../Utils/UserContext";

const EditProfile = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization_name: "",
    mobile_no: "",
  });
  console.log(formData, "meet");
  const { setUser } = useUser();
  const [profileStatus, setProfileStatus] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const axiosInstance = useAxiosInstance();
  let title = "Your Profile";

  useEffect(() => {
    const user = getUser();

    setFormData({
      ...user,
    });
  }, [profileStatus]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/edit_profile";
      const requestBody = {
        name: formData.name,
        email: formData.email,
        mobile_no: formData.mobile_no,
      };

      const response = await axiosInstance.post(apiUrl, requestBody, {});

      const data = response.data;
      if (response.status === 200) {
        const expirationTime: any = Date.now() + 3600 * 1000;

        const userFromStorage = getUser();
        setUser({ ...userFromStorage, name: formData.name });
        setCookie("user", JSON.stringify(userFromStorage), {
          expires: expirationTime,
        });
        setProfileStatus(true);
        setIsEditable(false);
        console.log(data.message);

        setToast("success", data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error("Error:", error.response?.data || error.message);
        setToast(
          "error",
          error.response?.data?.message || "Something went wrong",
        );
      } else if (error instanceof Error) {
        console.error("Error:", error);
        setToast("error", error.message || "Something went wrong");
      } else {
        console.error("Unknown error:", error);
        setToast("error", "Something went wrong");
      }
    }
  };

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleSubmit(event);
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };
  return (
    <Container className="mt-5">
      <Card>
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName">
                  <Form.Label className="fs-6 ms-1">Name*</Form.Label>
                  <Form.Control
                    type="text"
                    className="bordero-0 form-control form-control-lg form-control-solid"
                    placeholder="Enter Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    disabled={!isEditable}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail">
                  <Form.Label className="fs-6 ms-1">Email*</Form.Label>
                  <Form.Control
                    className="form-control form-control-lg form-control-solid"
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    disabled={!isEditable}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label className="fs-6 ms-1">Mobile Number*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Mobile Number"
                    name="mobile_no"
                    className="form-control form-control-lg form-control-solid"
                    value={formData.mobile_no}
                    onChange={(e) =>
                      setFormData({ ...formData, mobile_no: e.target.value })
                    }
                    disabled={!isEditable}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3 float-end">
              {isEditable ? (
                <button
                  type="button"
                  className="btn addnew-btn btn-sm"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn edit-btn btn-sm"
                  onClick={handleEditClick}
                >
                  Edit Profile
                </button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditProfile;

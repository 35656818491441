import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import DataTable, { TableColumn } from "react-data-table-component";
import useAxiosInstance from "../../../Utils/Headers";
import CustomSkeleton from "../../../Components/CustomSkeleton";
interface catalog {
  id: number;
  catalog_url: string;
}
interface CatalogProps {
  id: any;
}

const Catalog: React.FC<CatalogProps> = ({ id }) => {
  const [catalogueData, setCatalogueData] = useState<catalog[]>([]);
  const [loading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    getCatalogue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  const getCatalogue = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/visitors/${id}/catalog?page=${page}&limit=${limit}`,
      );

      const catalog = response.data.data.map((catalog: catalog) => {
        return {
          ...catalog,
        };
      });
      setCatalogueData(catalog);
      setTotalRows(response.data.meta.total);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const columns: TableColumn<catalog>[] = [
    {
      name: "ID",
      maxWidth: "33%",
      selector: (Row) => Row.id,
      sortable: true,
    },
    {
      name: "url",
      maxWidth: "33%",
      cell: (Row) => <span className="fs-6">Download PDF</span>,
      sortable: true,
      wrap: true,
    },
    {
      name: "View",
      button: true,
      minWidth: "33%",
      cell: (row) => (
        <>
          <a
            href={row.catalog_url}
            target="_blank"
            className="border-0 bg-transparent text-primary fs-5"
            title="View PDF"
            rel="noreferrer"
          >
            <Button
              variant="primary-light"
              size="sm"
              className="me-2 btn-sm btn-font"
            >
              <Eye /> View
            </Button>
          </a>
        </>
      ),
    },
  ];

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLimit(limit);
    setPage(1);
  };

  const handlePageChange = async (page: any) => {
    setPage(page);
  };

  return (
    <>
      <div className="mt-4 mb-3">
        <Card className="min-height-card-visitor">
          <Card.Header className="m-1">Catalog</Card.Header>
          <DataTable
            columns={columns}
            data={catalogueData}
            progressPending={loading}
            pagination
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            noDataComponent={
              <>
                <img src="/images/svg/No_data_available.svg" alt="" />
              </>
            }
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
            }}
            progressComponent={
              <CustomSkeleton
                customInnerStyle={{ marginBottom: "9.5px" }}
                count={10}
                height={36}
              />
            }
          />
        </Card>
      </div>
    </>
  );
};

export default Catalog;

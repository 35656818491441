import { RouteObject } from "react-router-dom";
import List from "../Pages/Organization/List";
import AddUpdate from "../Pages/Organization/AddUpdate";
import View from "../Pages/Organization/View";
import SubUserView from "../Pages/Organization/SubUserView";
import AddSubUserUpdate from "../Pages/Organization/AddSubUserUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import CampaignDetails from "../Pages/Organization/CampaignDetails";
import {
  UPDATE_ORGANIZATION,
  VIEW_ORGANIZATION,
  VIEW_USER,
} from "../Utils/PermissionsEnum";
import { CampaignIdentifierList } from "../Pages/Organization/CampaignIdentifierList";

export const ADD_BALANCE = ":id/add-balance";
export const ORGANIZATION_LIST = "organization";
export const ORGANIZATION_ADD = "add";
export const ORGANIZATION_VIEW = ":id/view";
export const SUB_USER_VIEW = ":usersID/SubUser";
export const SUB_USER_ADD = "SubUser/add";
export const CAMPAIGN_LIST = "CampaignIdentifierList";
export const CAMPAIGN_DETAILS = "CampaignDetails/:id";
export const CAMPAIGN_VIEW_LIST = "CampaignIdentifierList";

const organizationRoutes: RouteObject[] = [
  {
    path: ORGANIZATION_LIST,
    element: (
      <AuthorizedRoute element={<List />} permission={VIEW_ORGANIZATION} />
    ),
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_ADD}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_ORGANIZATION}
      />
    ),
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW}`,
    element: (
      <AuthorizedRoute element={<View />} permission={VIEW_ORGANIZATION} />
    ),
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW}/${SUB_USER_VIEW}`,
    element: (
      <AuthorizedRoute element={<SubUserView />} permission={VIEW_USER} />
    ),
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW}/${SUB_USER_ADD}`,
    element: <AddSubUserUpdate />,
  },
  {
    path: `${ORGANIZATION_LIST}/${CAMPAIGN_LIST}`,
    element: <CampaignIdentifierList />,
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW}/${CAMPAIGN_VIEW_LIST}`,
    element: <CampaignIdentifierList />,
  },
  {
    path: `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW}/${CAMPAIGN_DETAILS}`,
    element: <CampaignDetails />,
  },
];

export default organizationRoutes;

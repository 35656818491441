import { RouteObject } from "react-router-dom";
import List from "../Pages/ProductColor/List";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_PRODUCT_COLOR,
  UPDATE_PRODUCT_COLOR,
  VIEW_PRODUCT_COLOR,
} from "../Utils/PermissionsEnum";
import AddUpdate from "../Pages/ProductColor/AddUpdate";

export const PRODUCT_COLOR_LIST = "product-colors";
export const PRODUCT_COLOR_ADD = "add";
export const PRODUCT_COLOR_EDIT = ":id/edit";

const ProductColorRoutes: RouteObject[] = [
  {
    path: PRODUCT_COLOR_LIST,
    element: (
      <AuthorizedRoute element={<List />} permission={VIEW_PRODUCT_COLOR} />
    ),
  },
  {
    path: `${PRODUCT_COLOR_LIST}/${PRODUCT_COLOR_ADD}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={ADD_PRODUCT_COLOR} />
    ),
  },
  {
    path: `${PRODUCT_COLOR_LIST}/${PRODUCT_COLOR_EDIT}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_PRODUCT_COLOR}
      />
    ),
  },
];
export default ProductColorRoutes;

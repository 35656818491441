import React, { createContext, useContext, useState, useEffect } from "react";
import useAxiosInstance from "../Utils/Headers";
import { useOrganization } from "./OrganizationContext";

interface NotificationContextProps {
  hasUnreadNotifications: Notification[];
  fetchUnreadNotifications: () => void;
  updateUnreadNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined,
);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const axiosInstance = useAxiosInstance();
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState<
    Notification[]
  >([]);
  const { selectedOrganizationId, organizationId }: any = useOrganization();
  const selectorga = selectedOrganizationId || organizationId;

  const fetchUnreadNotifications = async () => {
    if (!selectorga) {
      console.error("Organization ID is not defined");
      return;
    }
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/notifications/search?organization_id=${selectorga}`,
        {
          filters: [{ field: "read_at", operator: "=", value: null }],
        },
      );
      setHasUnreadNotifications(response.data.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  useEffect(() => {
    fetchUnreadNotifications();
    // eslint-disable-next-line
  }, [selectorga]);

  const updateUnreadNotifications = () => {
    fetchUnreadNotifications();
  };

  return (
    <NotificationContext.Provider
      value={{
        hasUnreadNotifications,
        fetchUnreadNotifications,
        updateUnreadNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
};

import React, { useEffect, useState } from "react";
import { Card, Table, Modal, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  SUB_USER_ADD,
  SUB_USER_VIEW,
} from "../../../Routes/organizationRoutes";
import { AxiosResponse } from "axios";
import useAxiosInstance from "../../../Utils/Headers";
import { Eye } from "react-bootstrap-icons";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";

import CustomSkeleton from "../../../Components/CustomSkeleton";
import { Cookies } from "typescript-cookie";
import { setToast } from "../../../Utils/Toast";

interface UserType {
  id: number;
  name: string;
  email: string;
  mobile_no: string;
}

const SubUser = () => {
  const { id } = useParams();
  const axiosInstance = useAxiosInstance();
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const SelectedAccount = Cookies.get("selectedAccount");
  const navigate = useNavigate();

  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [actionType, setActionType] = useState<"block" | "unblock">();

  useEffect(() => {
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUser = async () => {
    try {
      setLoading(true);
      const response: AxiosResponse<{ data: UserType[] }> =
        await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}api/sub_user?organization_id=${id}`,
        );
      setUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/user/${id}/block`,
      );
      setToast("success", response.data.message);
      handleUser();
    } catch (error: any) {
      setToast("error", error);
    }
  };

  const handleUnBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/user/${id}/unblock`,
      );
      setToast("success", response.data.message);
      handleUser();
    } catch (error: any) {
      setToast("error", error);
    }
  };

  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    usersID: number,
  ) => {
    e.preventDefault();
    navigate(SUB_USER_VIEW.replace(":usersID", usersID.toString()));
  };

  const handleActionClick = (userId: number, action: "block" | "unblock") => {
    setSelectedUserId(userId);
    setActionType(action);
    setShowModal(true); // Show confirmation modal
  };

  const handleConfirmAction = () => {
    if (selectedUserId && actionType) {
      if (actionType === "block") {
        handleBlockUser(selectedUserId);
      } else {
        handleUnBlockUser(selectedUserId);
      }
    }
    setShowModal(false); // Close the modal after confirmation
  };

  return (
    <>
      <Card className="min-height-card">
        <Card.Header className="d-flex align-items-center">
          <div className="flex-grow-1">Sub User</div>
          {(organizationIdFromCookie || SelectedAccount) && (
            <div className="text-end">
              <Link to={`${SUB_USER_ADD}`}>
                <button
                  className="btn addnew-btn btn-sm mx-3"
                  title="add Sub User"
                >
                  Add SubUser
                </button>
              </Link>
            </div>
          )}
        </Card.Header>

        <Card.Body>
          {loading ? (
            <CustomSkeleton
              customInnerStyle={{ marginBottom: "9.5px" }}
              count={5}
              height={36}
            />
          ) : users.data && users.data.length > 0 ? (
            <Table responsive hover size="sm">
              <thead>
                <tr className="fs-6 text-secondary fw-bold">
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Block</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {users?.data?.map((users: any) => (
                  <tr key={users.id} className="fs-6">
                    <td className="p-2">{users.id}</td>
                    <td>{users.name}</td>
                    <td>{users.email}</td>
                    <td>{users.mobile_no}</td>
                    <td>
                      {users.is_blocked === 0 ? (
                        <span className="badge badge-light-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge badge-light-danger">Block</span>
                      )}
                    </td>
                    <td className="d-flex justify-content-center">
                      <Button
                        variant="primary-light"
                        size="sm"
                        className="me-2 btn-sm btn-font"
                        onClick={(e: any) =>
                          handleRowShowButtonClick(e, users.id)
                        }
                      >
                        <Eye /> View
                      </Button>
                      {users.is_blocked === 0 ? (
                        <Button
                          variant="danger-light"
                          size="sm"
                          className="me-2 btn-sm btn-font"
                          style={{ width: "99px" }}
                          onClick={() => handleActionClick(users.id, "block")}
                        >
                          <MdBlock className="mb-1" /> Block
                        </Button>
                      ) : (
                        <Button
                          variant="success-light"
                          size="sm"
                          className="me-2 btn-sm btn-font"
                          onClick={() => handleActionClick(users.id, "unblock")}
                        >
                          <CgUnblock className="mb-1" /> UnBlock
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">
              <img src="/images/svg/No_data_available.svg" alt="" />
            </div>
          )}
        </Card.Body>
      </Card>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="text-center p-1">
          <div>
            <h3>Confirm Action</h3>
            <h5> Are you sure you want to {actionType} this user?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-4 d-flex justify-content-center">
          <button
            className="btn addnew-btn btn-sm"
            onClick={handleConfirmAction}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubUser;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircle, Trash3, Eye } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useOrganization } from "../../Utils/OrganizationContext";
import useAxiosInstance from "../../Utils/Headers";
import { ORGANIZATION_VISITOR_VIEW } from "../../Routes/visitorRoutes";

interface Notification {
  id: number;
  data: {
    message: string;
    email: string;
    mobile_number: number;
    visitorId: number;
  };
  read_at: Date | null;
}

const greenTextStyle = {
  background: "#F2F6FC",
};

interface UnreadNotificationsProps {
  onMarkAsRead: (notificationId: number) => void;
  onDelete: (notificationId: number) => void;
}

const UnreadNotifications: React.FC<UnreadNotificationsProps> = ({
  onMarkAsRead,
  onDelete,
}) => {
  const { selectedOrganizationId, organizationId }: any = useOrganization();
  const [unreadNotifications, setUnreadNotifications] = useState<
    Notification[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxiosInstance();

  const fetchUnreadNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/search?organization_id=${
          selectedOrganizationId || organizationId
        }`,
        {
          filters: [{ field: "read_at", operator: "=", value: null }],
        },
      );
      setUnreadNotifications(response.data.data || []);
    } catch (error) {
      console.error("Error fetching unread notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();
    // eslint-disable-next-line
  }, [selectedOrganizationId, organizationId]);

  const markNotificationAsRead = async (notificationId: number) => {
    try {
      await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }api/notification_read/${notificationId}?organization_id=${
          selectedOrganizationId || organizationId
        }`,
      );
      setUnreadNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
      onMarkAsRead(notificationId);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const deleteNotificationData = async (notificationId: number) => {
    try {
      await axiosInstance.delete(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/${notificationId}?organization_id=${
          selectedOrganizationId || organizationId
        }`,
      );
      setUnreadNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
      onDelete(notificationId);
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  return (
    <div className="p-0">
      {isLoading ? (
        <Skeleton count={5} height={80} />
      ) : unreadNotifications.length > 0 ? (
        unreadNotifications.map((notification) => (
          <div
            key={notification.id}
            style={!notification.read_at ? greenTextStyle : {}}
            className="border d-flex justify-content-between align-items-center p-2"
          >
            <div className="d-flex pt-2 ms-3">
              <h4>
                {notification.data.email} {notification.data.mobile_number}
              </h4>
              <h6 className="text-secondary ms-4 mt-1">
                {notification.data.message}
              </h6>
            </div>
            <div className="button-container d-flex">
              {!notification.read_at && (
                <Button
                  variant="success-light"
                  className="btn btn-sm"
                  onClick={() => markNotificationAsRead(notification.id)}
                >
                  <CheckCircle size={15} /> Read
                </Button>
              )}
              <Link
                to={ORGANIZATION_VISITOR_VIEW.replace(
                  ":id",
                  notification.data.visitorId?.toString(),
                )}
              >
                <Button
                  variant="primary-light"
                  className="btn btn-sm fw-bold ms-2"
                >
                  <Eye size={15} /> View
                </Button>
              </Link>
              <Button
                variant="danger-light"
                className="btn-sm ms-2 fw-bold me-2"
                onClick={() => deleteNotificationData(notification.id)}
              >
                <Trash3 size={14} /> Delete
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <img src="/images/svg/No_data_available.svg" alt="" />
        </div>
      )}
    </div>
  );
};

export default UnreadNotifications;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Eye } from "react-bootstrap-icons";
import { VISITOR_AIIMAGESVIEW } from "../../Routes/visitorRoutes";
import { Card, Button, Col, Image } from "react-bootstrap";
import moment from "moment";
import useAxiosInstance from "../../Utils/Headers";
import { handleImageError } from "../../Components/Noimage";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface RoomViewProps {
  image: string;
  name: string;
  id: string;
  visitor: {
    email: string;
    mobile_number: string;
  };
  organization: {
    name: string;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface AiImage {
  output_image: string;
  id: number;
}

function RoomView() {
  const [data, setData] = useState<RoomViewProps | null>(null);
  const { visitorId, roomId } = useParams();
  const [aiImages, setAiImages] = useState<AiImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [limit] = useState(10);
  const [totalRows] = useState(0);
  const [initialImages, setInitialImages] = useState(10);
  const [loadMoreImages] = useState(10);
  const paginatedaiImages = aiImages.slice(0, initialImages);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(
      VISITOR_AIIMAGESVIEW.replace(
        ":visitorId",
        visitorId ? visitorId.toString() : "defaultVisitorId",
      )
        .replace(":roomId", roomId ? roomId.toString() : "defaultRoomId")
        .replace(":id", id ? id.toString() : "defaultId"),
    );
  };

  const fetchRoomData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/rooms/${roomId}?include=organization,visitor`,
      );
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchRoomData();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const roomAiimage = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/rooms/${roomId}/aiImages`,
      );
      setAiImages(response.data.data);
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      roomAiimage();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, totalRows]);

  const loadMoreImagesHandler = () => {
    setInitialImages(initialImages + loadMoreImages);
  };

  const fallBackSrc = "/images/svg/noimage.svg";

  return (
    <div className="container-fluid">
      <div className="text-end">
        <button
          type="button"
          className="btn back-btn btn-sm mb-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      <div className="row align-items-center">
        <div className="d-flex justify-content-between">
          <div className="card col-12 col-md-6 border-0 mt-2 bg-white">
            {loading ? (
              <CustomSkeleton height={320} />
            ) : (
              data && (
                <Col className="d-flex justify-content-center align-items-center w-100 roomimage-div">
                  <Image
                    src={data.image}
                    className="room-image mt-5"
                    onError={(e) =>
                      handleImageError(e, fallBackSrc, "100%", "160px")
                    }
                  />
                </Col>
              )
            )}
          </div>
          <div className="col-12 col-md-6 mt-2 campaign-width">
            <Card>
              {loading ? (
                <CustomSkeleton
                  customInnerStyle={{ marginBottom: "8px" }}
                  count={9}
                  height={25}
                />
              ) : (
                <>
                  <Card.Header className="card-header">
                    Rooms Image Details
                  </Card.Header>
                  <Card.Body className="pb-1 room-details">
                    <table className="table fs-6">
                      <tbody>
                        <tr>
                          <td className="fw-bold text-dark">Room Name </td>
                          <td>{data?.name}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold text-dark">
                            Organization Name{" "}
                          </td>
                          <td>{data?.organization?.name}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold text-dark">Visitor email </td>
                          <td>{data?.visitor?.email}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold text-dark">
                            Visitor Mobile Number
                          </td>
                          <td>{data?.visitor?.mobile_number}</td>
                        </tr>

                        <tr>
                          <td className="fw-bold text-dark">Created At</td>
                          <td>
                            {moment(data?.created_at).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold text-dark">Updated At</td>
                          <td>
                            {moment(data?.updated_at).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold text-dark">Deleted At</td>
                          <td>
                            {moment(data?.deleted_at).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                </>
              )}
            </Card>
          </div>
        </div>
        <div className="mt-5 mb-5">
          <Card>
            <Card.Header className="m-1">
              <h4 className="card-title fw-semibold">Ai Images</h4>
            </Card.Header>
            <Card.Body className="row d-flex justify-content-evenly">
              {loading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <div
                    className="col-lg-2 col-md-3 col-sm-4 col-12 m-3 p-0"
                    key={index}
                  >
                    <div className="w-100 d-flex justify-content-center align-items-center overflow-hidden output-image-div">
                      <CustomSkeleton
                        height={130}
                        width={"100%"}
                        borderRadius="4px"
                      />
                    </div>
                  </div>
                ))
              ) : paginatedaiImages.length === 0 ? (
                <div className="text-center text-dark">
                  No Ai Images to display
                </div>
              ) : (
                paginatedaiImages.map((aiImage: AiImage) => (
                  <div
                    className="col-lg-2 col-md-3 col-sm-4 col-12 m-3 p-0"
                    key={aiImage.id}
                  >
                    <div className="output-image-div d-flex justify-content-center align-items-center overflow-hidden">
                      <Image
                        src={aiImage.output_image}
                        onError={(e) =>
                          handleImageError(e, fallBackSrc, "100%", "100px")
                        }
                      />
                    </div>
                    <div className="text-center mt-3">
                      <Button
                        variant="primary-light"
                        size="sm"
                        className="me-2 btn-sm"
                        onClick={(e) => handleRowShowButtonClick(e, aiImage.id)}
                      >
                        <Eye /> View
                      </Button>
                    </div>
                  </div>
                ))
              )}

              <div className="text-center mt-3">
                {initialImages < aiImages.length ? (
                  <button
                    className="btn addnew-btn btn-sm"
                    onClick={loadMoreImagesHandler}
                  >
                    Load More
                  </button>
                ) : (
                  <button className="btn back-btn btn-sm" disabled>
                    Load More
                  </button>
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default RoomView;

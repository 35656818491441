import React, { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import { Visitor } from "../../../Models/Visitor";
import { ORGANIZATION_VISITOR_VIEW } from "../../../Routes/visitorRoutes";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../../../Components/CustomSkeleton";

const NewArrivals = () => {
  const navigate = useNavigate();
  const [visitorData, setVisitorData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchVisitorForOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVisitorForOrganization = async () => {
    try {
      setLoading(true);
      const query: any = await Visitor.$query();
      query.httpClient.baseUrl = await query.httpClient.baseUrl;
      const visitor = await query.sortBy("id", "desc").search();

      const lastFive = visitor[0]?.$response?.data.data.slice(-5);
      setVisitorData(lastFive);
    } catch (error) {
      console.error("Error fetching visitor data:", error);
    } finally {
      setLoading(false);
    }
  };

  const viewVisitor = (id: any) => {
    navigate(ORGANIZATION_VISITOR_VIEW.replace(":id", id.toString()));
  };

  return (
    <>
      <Card className="min-height-card">
        <Card.Header className="rounded-2 ">New Arrivals</Card.Header>
        <Card.Body>
          {loading ? (
            <CustomSkeleton
              customInnerStyle={{ marginBottom: "9.5px" }}
              count={5}
              height={36}
            />
          ) : visitorData && visitorData.length ? (
            <Table responsive hover size="sm">
              <thead>
                <tr className="fs-6 text-secondary fw-bold">
                  <th>SR.NO</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th className="text-center">View</th>
                </tr>
              </thead>
              <tbody>
                {visitorData.map((d: any, index: any) => (
                  <tr key={index} className="fs-6">
                    <td>{index + 1}</td>
                    <td>{d.email}</td>
                    <td>{d.mobile_number}</td>
                    <td className="text-center">
                      <Button
                        variant="primary-light"
                        size="sm"
                        className="me-2 btn-sm btn-font"
                        onClick={() => {
                          viewVisitor(d.id);
                        }}
                      >
                        <Eye /> View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center">
              <img src="/images/svg/No_data_available.svg" alt="" />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default NewArrivals;

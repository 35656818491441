import React from "react";
import AddUpdateForm from "../../Components/AddUpdateForm";
import { ProductColor } from "../../Models/ProductColor";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import ActivityLogs from "../../Components/ActivityLogs";

interface AddUpdateProps {
  title?: any;
}

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  placeholder?: string;
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
}
const AddUpdate: React.FC<AddUpdateProps> = () => {
  let title = "Product color";
  const { id } = useParams<{ id: string }>();
  const formFields: Field[] = [
    {
      id: "title",
      title: "Title",
      type: "text",
      col: 12,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Width is required",
        },
      ],
      placeholder: "Enter the title of the product color",
      required: true,
    },
  ];
  return (
    <>
      <div>
        <AddUpdateForm
          formFields={formFields}
          title={title}
          model={ProductColor}
        />
      </div>
      {id && (
        <Card className="border-0 activity-card">
          <Card.Header className="mb-4">Activity Logs</Card.Header>
          <ActivityLogs auditable_type={"PRODUCT_COLOR"} id={id} />
        </Card>
      )}
    </>
  );
};

export default AddUpdate;

import React, { useState, useEffect } from "react";
import { setToast } from "../Utils/Toast";
import { Modal } from "react-bootstrap";

interface CustomDeleteModalProps {
  title: string;
  model: any;
  getData: (page: number) => void;
  deleteItemId: number | null;
  setDeleteItemId: React.Dispatch<React.SetStateAction<number | null>>;
  showDeleteModal: boolean;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteClick?: Function | null;
}

export default function CustomDeleteModal({
  title,
  model,
  getData,
  deleteItemId,
  setDeleteItemId,
  showDeleteModal,
  setShowDeleteModal,
  handleDeleteClick,
}: CustomDeleteModalProps) {
  const [inputValue, setInputValue] = useState("");
  const isDeleteEnabled = inputValue.toLowerCase() === "delete";

  const handleRowDelete = async () => {
    setShowDeleteModal(false);

    try {
      await model.$query().destroy(deleteItemId);
      getData(1);
      setDeleteItemId(null);
      setToast("success", title + " Successfully Deleted");
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "Something went wrong");
      }
    }
  };
  useEffect(() => {
    if (showDeleteModal) {
      setInputValue("");
    }
  }, [showDeleteModal]);
  return (
    <>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
          <Modal.Title>Confirm Delete</Modal.Title>
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon mb-4"
            onClick={() => setShowDeleteModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              placeholder="Type 'delete' to Confirm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-100 form-control form-control-lg form-control-solid"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          {isDeleteEnabled && (
            <button
              className="btn delete-btn btn-sm"
              onClick={() =>
                handleDeleteClick ? handleDeleteClick() : handleRowDelete()
              }
            >
              Delete
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

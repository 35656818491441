import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { Pencil } from "react-bootstrap-icons";
import DataTable, { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_TEMPLATE_EDIT } from "../../Routes/notificationTemplateRoutes";
import { fetchData } from "../../Utils/OrionList";
import { InputValues } from "../../Components/CustomFilter";
import { NotificationTemplate } from "../../Models/NotificationTemplate";
import CustomSkeleton from "../../Components/CustomSkeleton";
import { useOrganization } from "../../Utils/OrganizationContext";

interface NotificationTemplateItem {
  id: number;
  organization_id: string;
  subject: string;
  created_at: string;
}

function List() {
  let title = "Notification Template";

  const [data, setData] = useState<NotificationTemplateItem[]>([]); // Use the appropriate type here
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues] = useState<InputValues>({});
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  let { organizationId }: any = useOrganization();

  const handleRowEditButtonClick = (id: number) => {
    navigate(NOTIFICATION_TEMPLATE_EDIT.replace(":id", id.toString()));
  };

  const columns: TableColumn<NotificationTemplateItem>[] = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      sortField: "subject",
    },
    {
      name: "Organization id",
      selector: (row) => row.organization_id,
      sortable: true,
      sortField: "organization_id",
    },
    {
      name: "Date",
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY"),
      sortable: true,
    },
    {
      name: "Actions",
      minWidth: "10%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];

  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
    </DropdownButton>
  );

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new NotificationTemplate(),
      sortField,
      sortOrder,
    );
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new NotificationTemplate(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>
        <Row className="mt-4"></Row>
      </Container>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={getData}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

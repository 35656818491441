import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, PencilFill } from "react-bootstrap-icons";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Products } from "../../Models/Products";
import { fetchData } from "../../Utils/OrionList";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import {
  PRODUCT_ZIP_ADD,
  PRODUCT_VIEW,
  PRODUCT_ADD,
  PRODUCT_EDIT,
} from "../../Routes/ProductsRoutes";
import { useOrganization } from "../../Utils/OrganizationContext";
import { getUser } from "../../Utils/Auth";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface ProductsItem {
  id: number;
  product_name: string;
  title: string;
  image_url_small: string;
  height: number;
  width: number;
}

interface UserData {
  name: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();

  let title = "Products";
  const [data, setData] = useState<ProductsItem[]>([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [user, setUser] = useState<UserData | string | undefined>(undefined);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (data && data.length === totalRows) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [data, totalRows, inputValues]);

  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_VIEW.replace(":id", id.toString()));
  };

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PRODUCT_EDIT.replace(":id", id.toString()));
  };

  const fetchProducts = async (
    page: number | undefined,
    append: boolean = false,
  ) => {
    setLoading(true);
    const fetchedUserData = getUser();
    if (fetchedUserData) {
      setUser(fetchedUserData);
      const name = typeof user === "object" ? user.name : user;
      selectedOrganizationId =
        name === "Super Admin"
          ? selectedOrganizationId
          : fetchedUserData.organization_id;
    }
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      (fetchedData: ProductsItem[]) => {
        const validData = fetchedData || [];
        setData((prevData) =>
          append ? [...prevData, ...validData] : validData,
        );
      },
      // setData,
      setTotalRows,
      navigate,
      new Products(),
      sortField,
      sortOrder,
    );
    setLoading(false);
  };
  // eslint-disable-next-line
  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const filterOptions: any = [
    {
      id: "product_name",
      value: "product_name",
      label: "Product Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "width",
      value: "width",
      label: "Width",
      fieldType: "number",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "height",
      value: "height",
      label: "Height",
      fieldType: "number",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
  ];

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Products(),
      sortField,
      sortOrder,
    );
  };
  // eslint-disable-next-line
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  const loadMoreProducts = () => {
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      fetchProducts(newPage, true);
      return newPage;
    });
  };

  const fallBackSrc = "/images/svg/noimage.svg";

  return (
    <>
      <Container fluid>
        <Row className="align-items-center">
          <Col className="mt-4">
            <h2>{title} List</h2>
          </Col>
          <Col className="d-flex justify-content-end">
            <Link to={`${PRODUCT_ZIP_ADD}`}>
              <button className="btn addnew-btn btn-sm">
                Upload {title} Zip
              </button>
            </Link>
            <Link to={`${PRODUCT_ADD}`}>
              <button className="btn addnew-btn btn-sm ms-3">
                Upload {title}
              </button>
            </Link>
          </Col>
        </Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchProducts}
        />
      </Container>

      <Container fluid>
        <Row className="d-flex justify-content-center">
          {data && data.length > 0 ? (
            data.map((product) => (
              <Col
                key={product.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                className="m-3 card p-2 d-flex flex-column justify-content-between"
              >
                {loading ? (
                  <CustomSkeleton
                    height={130}
                    width={"100%"}
                    borderRadius="4px"
                  />
                ) : (
                  <>
                    <div>
                      <div className="rounded d-flex justify-content-center align-items-center overflow-hidden product-image">
                        <Image
                          className="img-fluid"
                          src={product.image_url_small}
                          onError={(e) => (e.currentTarget.src = fallBackSrc)}
                        />
                      </div>
                      <div className="text-center pt-3">
                        <h5 className="text-center fw-bold">
                          {product.product_name}
                        </h5>
                        <div className="d-flex justify-content-evenly  mt-3">
                          <h5 className="text-secondary">
                            Width-{product.width}
                          </h5>
                          <h5 className="text-secondary">
                            Height-{product.height}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mt-2 card-footer border-0">
                      <Button
                        variant="primary-light"
                        size="sm"
                        className="me-2 btn-sm btn-font"
                        onClick={(e) => handleRowShowButtonClick(e, product.id)}
                      >
                        <Eye /> View
                      </Button>
                      <Button
                        variant="success-light"
                        onClick={(e) => handleRowEditButtonClick(e, product.id)}
                      >
                        <PencilFill /> Edit
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            ))
          ) : (
            <div className="text-center">
              <img src="/images/svg/No_data_available.svg" alt="" />
            </div>
          )}
        </Row>
        {data.length > 0 && (
          <Row className="text-center mt-3">
            <Col>
              <button
                className={`btn btn-sm ${hasMore ? "addnew-btn" : "back-btn"}`}
                onClick={loadMoreProducts}
                disabled={!hasMore}
              >
                Load More
              </button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default List;

import React, { useEffect, useState } from "react";
import { Organization } from "../Models/Organization";
import { useOrganization } from "../Utils/OrganizationContext";

import { getUser } from "../Utils/Auth";
import { Cookies } from "typescript-cookie";
import { BoxArrowRight } from "react-bootstrap-icons";

interface Option {
  id: string;
  value: string;
  label: string;
  fieldType: string;
}

function OrganizationDropdown() {
  // eslint-disable-next-line
  const [options, setOptions] = useState<Option[]>([]);
  // eslint-disable-next-line
  let { selectedOrganizationId, setSelectedOrganizationId }: any =
    useOrganization();
  const [selectedValue, setSelectedValue] = useState<any | null>(null);
  // const { setSelectedOrganizationId }: any = useOrganization();
  const [organizationID, setOrganizationID] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const organizationNameFromCookie = Cookies.get("OrganizationNameForView");

  useEffect(() => {
    if (organizationIdFromCookie) {
      setSelectedValue(organizationIdFromCookie);
    } else {
      getProfileData();
    }
  }, [organizationIdFromCookie]);

  useEffect(() => {
    if (organizationID) {
      setSelectedValue(organizationID);
    }
  }, [organizationID]);

  useEffect(() => {
    if (selectedValue) {
      setSelectedOrganizationId(selectedValue);
      if (options.length === 0) {
        fetchOrganization();
      }
    }
    // eslint-disable-next-line
  }, [selectedValue, organizationIdFromCookie, options]);
  const fetchOrganization = async () => {
    try {
      const organization: any = await Organization.$query()
        .lookFor("")
        .search();
      const organizationData: any = organization;

      if (organizationData) {
        const mappedOptions = organizationData.map((org: any) => ({
          id: org.$attributes.id,
          value: org.$attributes.id,
          label: org.$attributes.name,
          fieldType: "text",
        }));
        setOptions(mappedOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfileData = async () => {
    try {
      if (getUser()) {
        const fetchedUser = getUser() as any | undefined;
        setOrganizationID(fetchedUser.organization_id);
        setOrganizationName(fetchedUser.name);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const handleExit = () => {
    Cookies.remove("OrganizationIdForView");
    Cookies.remove("OrganizationNameForView");

    window.location.reload();
  };

  return (
    <div className="d-flex justify-content-end align-items-center w-100">
      {organizationIdFromCookie && (
        <>
          <h5 className="me-2 mt-3 text-secondary">logged as :</h5>
          <div className="d-flex justify-content-end align-items-center border p-2 rounded bg-body-secondary">
            <h4 className="pt-3 fw-bold">
              {organizationNameFromCookie !== null
                ? String(organizationNameFromCookie)
                : organizationName}
            </h4>

            <button
              type="submit"
              className="btn delete-btn btn-sm ms-2 p-2"
              title="Exit From This Organization"
              onClick={() => {
                handleExit();
              }}
            >
              Exit
              <BoxArrowRight size={14} className="ms-2" />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default OrganizationDropdown;

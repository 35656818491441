import { RouteObject } from "react-router-dom";
import List from "../Pages/Setting/Role/List";
import AddUpdate from "../Pages/Setting/Role/AddUpdate";
import AssignRolePermission from "../Pages/Setting/Role/AssignRolePermission";

export const ROLES_LIST = "roles";
export const ROLES_ADD = "add";
export const ROLES_EDIT = ":id/edit";
export const ROLES_PERMISSION_ATTECHED = ":id/permission";

const Role: RouteObject[] = [
  { path: ROLES_LIST, element: <List /> },
  {
    path: `${ROLES_LIST}/${ROLES_ADD}`,
    element: <AddUpdate />,
  },
  {
    path: `${ROLES_LIST}/${ROLES_EDIT}`,
    element: <AddUpdate />,
  },
  {
    path: `${ROLES_LIST}/${ROLES_PERMISSION_ATTECHED}`,
    element: <AssignRolePermission />,
  },
];

export default Role;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Container, Modal, Nav } from "react-bootstrap";
import useAxiosInstance from "../../Utils/Headers";
import Details from "./Components/Details";
import Catalog from "./Components/Catalog";
import Room from "./Components/Room";
import ActivityLog from "./Components/ActivityLog";
import Notes from "../../Components/Notes";
import { Visitor } from "../../Models/Visitor";
import { PencilSquare } from "react-bootstrap-icons";
import { setToast } from "../../Utils/Toast";
import { Note } from "../../Models/Note";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
function View() {
  const { id }: any = useParams();
  const location = useLocation();
  const axiosInstance = useAxiosInstance();
  const activeKeyParam = new URLSearchParams(location.search).get("activeKey");
  const activeKey = activeKeyParam || "details";
  const [count, setCount] = useState({
    room: "",
    aiImage: "",
    catalog: "",
    product: "",
  });
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const handleShowModal = () => setShowNotesModal(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedVisitorId, setSelectedVisitorid] = useState<number | null>(
    null,
  );
  const [actionType, setActionType] = useState<"block" | "unblock">();
  const [data, setData] = useState<any>({
    id: "",
    visitor_id: "",
    visitor_ip: "",
    email: "",
    mobile_number: "",
    tracking_code: "",
    iframe_id: "",
    vendor_code: "",
    organization_id: "",
    product_type_id: "",
    domain: "",
    is_blocked: "",
    is_in_top_window: "",
    use_history_padding: "",
    deleted_at: "",
    created_at: "",
    updated_at: "",
  });
  useEffect(() => {
    fetchVisitor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchVisitor = async () => {
    const visitor: any = await Visitor.$query().find(id);
    setData(visitor?.$response?.data.data);
  };
  const handleCloseModal = () => {
    setShowNotesModal(false);
    setNoteValue("");
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchTotalCount = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/total_count/${id}`,
      );
      setCount(response.data);
    } catch (error) {
      console.error("Error fetching total count:", error);
    }
  };

  const handleNavigate = (key: any) => {
    const newUrl = `${location.pathname}?activeKey=${key}`;
    navigate(newUrl);
  };

  const handlenotesSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const noteData: any = {
        content: noteValue,
        visitor_id: id,
      };
      await Note.$query().store(noteData);
      setToast("success", `Note Successfully Added`);
      handleCloseModal();
    } catch (error: any) {
      console.error("API Error:", error);
      if (error.response && error.response.status === 400) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "Something went wrong");
      }
    }
  };
  const handleBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/visitor/${id}/block`,
      );
      setToast("success", response.data.message);
      fetchVisitor();
    } catch (error: any) {
      setToast("error", error);
    }
  };

  const handleUnBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/visitor/${id}/unblock`,
      );
      setToast("success", response.data.message);
      fetchVisitor();
    } catch (error: any) {
      setToast("error", error);
    }
  };
  const handleActionClick = (userId: number, action: "block" | "unblock") => {
    setSelectedVisitorid(userId);
    setActionType(action);
    setShowModal(true);
  };

  const handleConfirmAction = () => {
    if (selectedVisitorId && actionType) {
      if (actionType === "block") {
        handleBlockUser(selectedVisitorId);
      } else {
        handleUnBlockUser(selectedVisitorId);
      }
    }
    setShowModal(false);
  };
  return (
    <>
      <Container fluid>
        <Card className="mt-5">
          <Container fluid>
            <Card.Body className="visitor-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h1>Visitor</h1>
                </div>
                <div>
                  {data.is_blocked === 0 ? (
                    <Button
                      variant="danger-light"
                      size="sm"
                      className="ms-2 mb-2 btn-sm btn-font"
                      style={{ width: "99px" }}
                      onClick={() => handleActionClick(id, "block")}
                    >
                      <MdBlock className="mb-1" /> Block
                    </Button>
                  ) : (
                    <Button
                      variant="success-light"
                      size="sm"
                      className="ms-2 mb-2 btn-sm btn-font"
                      onClick={() => handleActionClick(id, "unblock")}
                    >
                      <CgUnblock className="mb-1" /> UnBlock
                    </Button>
                  )}
                  <button
                    className="btn edit-btn btn-sm mb-2 mx-3"
                    onClick={handleShowModal}
                  >
                    Add Note <PencilSquare className="mx-1" size={15} />
                  </button>
                  <button
                    type="button"
                    className="btn back-btn btn-sm mb-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                <div className="col">
                  <div className="custom-col rounded border border-dashed border-2">
                    <div className="card-statistic-3 p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <h5 className="card-title mb-0">Rooms</h5>
                          <h2 className="d-flex align-items-center mb-0">
                            {count?.room ? count.room : "0"}
                          </h2>
                        </div>
                        <div className="visitor-div rounded border border-dashed border-1 d-flex mt-2 justify-content-center align-items-center">
                          <img
                            src="/images/svg/room.svg"
                            alt="Room Icon"
                            className="visitor-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="custom-col rounded border border-dashed border-2">
                    <div className="card-statistic-3 p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <h5 className="card-title mb-0">Ai Images</h5>
                          <h2 className="d-flex align-items-center mb-0">
                            {count?.aiImage ? count.aiImage : "0"}
                          </h2>
                        </div>
                        <div className="visitor-div rounded border border-dashed border-1 d-flex mt-2 justify-content-center align-items-center">
                          <img
                            src="/images/svg/ai_image.svg"
                            alt="Room Icon"
                            className="visitor-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="custom-col rounded border border-dashed border-2">
                    <div className="card-statistic-3 p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <h5 className="card-title mb-0">Catalog</h5>
                          <h2 className="d-flex align-items-center mb-0">
                            {count?.catalog ? count.catalog : "0"}
                          </h2>
                        </div>
                        <div className="visitor-div rounded border border-dashed border-1 d-flex mt-2 justify-content-center align-items-center">
                          <img
                            src="/images/svg/catalogue.svg"
                            alt="Room Icon"
                            className="visitor-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="custom-col rounded border border-dashed border-2">
                    <div className="card-statistic-3 p-4">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <h5 className="card-title mb-0">Photos</h5>
                          <h2 className="d-flex align-items-center mb-0">
                            {count?.product ? count.product : "0"}
                          </h2>
                        </div>
                        <div className="visitor-div rounded border border-dashed border-1 d-flex mt-2 justify-content-center align-items-center">
                          <img
                            src="/images/svg/photosicon.svg"
                            alt="Room Icon"
                            className="visitor-image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-scroll-container">
                <Nav
                  variant="tabs"
                  className="nav nav-stretch mt-3 nav-line-tabs nav-line-tabs-2x border-transparent mt-5 fs-5 fw-bolder flex-nowrap"
                  activeKey={activeKey}
                >
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handleNavigate("details")}
                      eventKey="overview"
                      className={`nav-link text-active-primary me-6 ${
                        activeKey === "details" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2">Details</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handleNavigate("rooms")}
                      eventKey="rooms"
                      className={`nav-link text-active-primary me-6 ${
                        activeKey === "rooms" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2">Rooms</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handleNavigate("catalog")}
                      eventKey="catalog"
                      className={`nav-link text-active-primary me-6 ${
                        activeKey === "catalog" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2">Catalog</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handleNavigate("activity-logs")}
                      eventKey="activity-logs"
                      className={`nav-link text-active-primary me-6 ${
                        activeKey === "activity-logs" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2">Activity Logs</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => handleNavigate("notes")}
                      eventKey="notes"
                      className={`nav-link text-active-primary me-6 ${
                        activeKey === "notes" ? "active" : ""
                      }`}
                    >
                      <span className="mb-2"> Notes</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Card.Body>
          </Container>
        </Card>
        {activeKey === "details" && <Details data={data} />}
        {activeKey === "catalog" && <Catalog id={id} />}
        {activeKey === "rooms" && <Room id={id} />}
        {activeKey === "activity-logs" && <ActivityLog id={id} />}
        {activeKey === "notes" && (
          <Notes
            id={id}
            model={Visitor}
            fixheight={true}
            customModel={"visitor_id"}
          />
        )}
        <Modal centered show={showNotesModal} onHide={handleCloseModal}>
          <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
            <Modal.Title>Add Note</Modal.Title>
            <img
              src="/images/svg/closeicon.svg"
              alt="close"
              className="custom-close-icon mb-4"
              onClick={handleCloseModal}
            />
          </Modal.Header>
          <Modal.Body>
            <form
              className="d-flex flex-column align-items-end"
              onSubmit={handlenotesSubmit}
            >
              <textarea
                id="note"
                placeholder="Enter Your Note"
                className="form-control form-control-lg form-control-solid border-0"
                onChange={(e) => {
                  setNoteValue(e.target.value);
                }}
                style={{
                  width: "100%",
                  height: "135px",
                  padding: "5px 5px 10px 10px",
                  resize: "none",
                  borderColor: "#B3B3B3",
                  borderRadius: "4px",
                }}
                required
              ></textarea>
              <span style={{ margin: "15px 0" }}>
                <button type="submit" className="btn addnew-btn btn-sm">
                  Save
                </button>
              </span>
            </form>
          </Modal.Body>
        </Modal>
      </Container>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="text-center p-1">
          <div>
            <h3>Confirm Action</h3>
            <h5> Are you sure you want to {actionType} this Visitor?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-4 d-flex justify-content-center">
          <button
            className="btn addnew-btn btn-sm"
            onClick={handleConfirmAction}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default View;

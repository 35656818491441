import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../Utils/Toast";
import useAxiosInstance from "../../Utils/Headers";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";

interface UserType {
  name: string;
  id: number;
}

const AddSubUserUpdate = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    mobile_no: "",
  });
  // eslint-disable-next-line
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    mobile_no: "",
  });

  const navigate = useNavigate();

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      mobile_no: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
      valid = false;
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-]).{8,10}/.test(
        formData.password,
      )
    ) {
      newErrors.password =
        "Password must have: Uppercase, lowercase, digit, special character, 8-10 characters.";
      valid = false;
    }

    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords do not match";
      valid = false;
    }

    if (!formData.mobile_no.trim()) {
      newErrors.mobile_no = "Mobile number is required";
      valid = false;
    } else if (!/^\d{6,}$/.test(formData.mobile_no)) {
      newErrors.mobile_no = "Mobile number must be 6 digits or more";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubUser = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}api/sub_user`,
          formData,
        );
        if (response.status === 200) {
          setToast("success", "SubUser Added Successfully.");
        } else {
          console.error("Error SubUser Add");
          setToast("error", "Error SubUser Add");
        }

        console.log(response.data);
      } catch (error: any) {
        console.error("Error fetching user data:", error);
        setToast("error", error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUser = async () => {
    try {
      const response = await axiosInstance.get<UserType[]>(
        `${process.env.REACT_APP_API_URL}api/users`,
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    handleUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Card className="mt-5">
        <Card.Header>Add SubUser</Card.Header>
        <Card.Body>
          <form>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label fs-6">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid fs-6"
                    id="name"
                    placeholder="Please Enter Name"
                    required
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fs-6">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg form-control-solid fs-6"
                    id="email"
                    placeholder="Please Enter Email"
                    required
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label fs-6">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid fs-6"
                    id="mobileNo"
                    placeholder="Please Enter Mobile Number"
                    required
                    value={formData.mobile_no}
                    onChange={(e) =>
                      setFormData({ ...formData, mobile_no: e.target.value })
                    }
                  />
                  {errors.mobile_no && (
                    <div className="text-danger">{errors.mobile_no}</div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label fs-6">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid fs-6"
                    id="password"
                    placeholder="Please Enter Password"
                    required
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  {errors.password && (
                    <div className="text-danger">{errors.password}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label fs-6">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg form-control-solid fs-6"
                    id="confirmPassword"
                    placeholder="Please Enter Confirm Password"
                    required
                    value={formData.confirm_password}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                  {errors.confirm_password && (
                    <div className="text-danger">{errors.confirm_password}</div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn back-btn btn-sm"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn addnew-btn btn-sm"
                onClick={handleSubUser}
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    className="mt-1 mx-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddSubUserUpdate;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Pencil, PencilSquare } from "react-bootstrap-icons";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { fetchData } from "../../../Utils/OrionList";
import { Roles } from "../../../Models/Roles";
import { Role } from "../../../Models/Role";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import {
  ROLES_ADD,
  ROLES_EDIT,
  ROLES_PERMISSION_ATTECHED,
} from "../../../Routes/Role";
import { useOrganization } from "../../../Utils/OrganizationContext";
import { getUser } from "../../../Utils/Auth";
import CustomSkeleton from "../../../Components/CustomSkeleton";

interface RoleItem {
  id: number;
  name: string;
  slug: string;
}

interface UserData {
  name: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "Role";
  const [data, setData] = useState<RoleItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | string | undefined>(undefined);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const fetchedUserData = getUser();
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const filterOptions: any = [
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
  ];

  const handleRowAssignRolePermissionButtonClick = (id: number) => {
    navigate(ROLES_PERMISSION_ATTECHED.replace(":id", id.toString()));
  };

  const handleRowEditButtonClick = (id: number) => {
    navigate(ROLES_EDIT.replace(":id", id.toString()));
  };

  const columns: TableColumn<RoleItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      sortable: true,
      sortField: "slug",
    },
    {
      name: "Actions",
      minWidth: "10%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];

  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowAssignRolePermissionButtonClick(row.id)}
      >
        <PencilSquare />
        Assign Permissions
      </Dropdown.Item>
    </DropdownButton>
  );

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Roles(),
      selectedOrganizationId,
    );
  };
  // ==============================================
  const fetchrole = async (page: number | undefined) => {
    setLoading(true);

    if (fetchedUserData) {
      setUser(fetchedUserData);
      const name = typeof user === "object" ? user.name : user;
      selectedOrganizationId =
        name === "Super Admin"
          ? selectedOrganizationId
          : fetchedUserData.organization_id;
    }
    await fetchData(
      page,
      limit,
      {},
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Role(),
      sortField,
      sortOrder,
    );
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      {},
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Role(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================

  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>
        <Row className="mt-4">
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={getData}
          />
          <Col className="text-md-end">
            <Link to={`${ROLES_ADD}`}>
              <button className="btn addnew-btn mb-2 btn-sm">
                Add&nbsp;{title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={fetchrole}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Roles extends Model<{
  name: string;
  role_id: number;
}> {
  public $resource(): string {
    return "roles";
  }
}

import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AddUpdateForm from "../../Components/AddUpdateForm";
import { TileCoverages } from "../../Models/TileCoverages";
import ActivityLogs from "../../Components/ActivityLogs";

interface AddUpdateProps {
  title?: any;
}

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  placeholder?: string;
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
}

const AddUpdate: React.FC<AddUpdateProps> = () => {
  const { id } = useParams();
  let title = "Tile Coverages";
  const formFields: Field[] = [
    {
      id: "width",
      title: "Width*",
      type: "number",
      placeholder: "Enter Width",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Width is required",
        },
      ],
      required: true,
    },
    {
      id: "height",
      title: "Height*",
      type: "number",
      col: 6,
      placeholder: "Enter Height",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Height is required",
        },
      ],
      required: true,
    },
  ];

  return (
    <>
      <div>
        <AddUpdateForm
          formFields={formFields}
          model={TileCoverages}
          title={title}
        />
      </div>

      {id && (
        <Card className="border-0 activity-card">
          <Card.Header className="mb-4">Activity Logs</Card.Header>
          <ActivityLogs auditable_type={"TILES_SIZE"} id={id} />
        </Card>
      )}
    </>
  );
};

export default AddUpdate;

import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { IndustriesType } from "../../Models/IndustriesType";
import { Link, useNavigate } from "react-router-dom";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import { InputValues } from "../../Components/CustomFilter";
import { fetchData } from "../../Utils/OrionList";
import CustomDeleteModal from "../../Components/CustomDeleteModal";
import {
  INDUSTRIES_TYPES_ADD,
  INDUSTRIES_TYPES_EDIT,
} from "../../Routes/industriesTypesRoutes";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface IndustriesTypeItem {
  id: number;
  title: string;
  description: string;
}

function List() {
  let title = "Industries Type";
  const [data, setData] = useState<IndustriesTypeItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
  const [inputValues] = useState<InputValues>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  let { selectedOrganizationId, organizationId }: any = useOrganization();

  const handleRowEditButtonClick = (id: number) => {
    navigate(INDUSTRIES_TYPES_EDIT.replace(":id", id.toString()));
  };

  const handleRowDeleteButtonClick = (id: number) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<IndustriesTypeItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "10%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "20%",
      sortable: true,
      wrap: true,
      sortField: "title",
      sortFunction: (a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      maxWidth: "50%",
      wrap: true,
    },
    {
      name: "Actions",
      minWidth: "20%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];
  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="btn-font"
        onClick={() => handleRowDeleteButtonClick(row.id)}
      >
        <Trash3 /> <span className="mt-1">Delete</span>
      </Dropdown.Item>
    </DropdownButton>
  );

  const fetchIndustriesType = async (page: number | undefined) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new IndustriesType(),
      sortField,
      sortOrder,
    );
  };
  const handlePageChange = (page: number | undefined) => {
    fetchIndustriesType(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLimit(limit);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, selectedOrganizationId, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new IndustriesType(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <h2>{title} List</h2>
          </Col>
          <Col className="text-md-end">
            <Link to={`${INDUSTRIES_TYPES_ADD}`}>
              <button className="btn addnew-btn mb-2 btn-sm">
                Add&nbsp;{title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />

      <CustomDeleteModal
        title={title}
        model={IndustriesType}
        getData={fetchIndustriesType}
        deleteItemId={deleteItemId}
        setDeleteItemId={setDeleteItemId}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
}

export default List;

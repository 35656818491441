//ORGANIZATION
export const VIEW_ORGANIZATION = "VIEW_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const VIEW_USER = "VIEW_USER";

//VISITOR
export const VIEW_VISITOR = "VIEW_VISITOR";
export const VIEW_ROOM = "VIEW_ROOM";
export const VIEW_AI_IMAGE = "VIEW_AI_IMAGE";

//PRODUCT TYPE
export const VIEW_PRODUCT_TYPE = "VIEW_PRODUCT_TYPE";
export const ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
export const UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";

//PRODUCT
export const VIEW_PRODUCT = "VIEW_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PRODUCT_EDIT = "PRODUCT_EDIT";

//INDUSTRIES TYPE
export const VIEW_INDUSTRIES_TYPE = "VIEW_INDUSTRIES_TYPE";
export const ADD_INDUSTRIES_TYPE = "ADD_INDUSTRIES_TYPE";
export const UPDATE_INDUSTRIES_TYPE = "UPDATE_INDUSTRIES_TYPE";

//TAX TYPE
export const VIEW_TAX_TYPE = "VIEW_TAX_TYPE";
export const ADD_TAX_TYPE = "ADD_TAX_TYPE";
export const UPDATE_TAX_TYPE = "UPDATE_TAX_TYPE";

//TILE COVERGES
export const VIEW_TILE_SIZE = "VIEW_TILE_SIZE";
export const ADD_TILE_SIZE = "ADD_TILE_SIZE";
export const UPDATE_TILE_SIZE = "UPDATE_TILE_SIZE";

//NOTIFICATION TEMPLATE
export const VIEW_NOTIFICATION_TEMPLATE = "VIEW_NOTIFICATION_TEMPLATE";
export const ADD_NOTIFICATION_TEMPLATE = "ADD_NOTIFICATION_TEMPLATE";
export const UPDATE_NOTIFICATION_TEMPLATE = "UPDATE_NOTIFICATION_TEMPLATE";

//COUNTRY
export const VIEW_COUNTRY = "VIEW_COUNTRY";

//NOTIFICATION
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION";

// PRODUCT COLOR
export const VIEW_PRODUCT_COLOR = "VIEW_PRODUCT_COLOR";
export const ADD_PRODUCT_COLOR = "ADD_PRODUCT_COLOR";
export const UPDATE_PRODUCT_COLOR = "UPDATE_PRODUCT_COLOR";

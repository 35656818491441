import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country } from "../../Models/Country";
import DataTable, { TableColumn } from "react-data-table-component";
import { fetchData } from "../../Utils/OrionList";
import { Container } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface CountryItem {
  id: number;
  name: string;
  code: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues] = useState({});
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchCountry = async (page: number | undefined) => {
    setLoading(true);

    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Country(),
        sortField,
        sortOrder,
      );
      setLoading(false);
    } catch (error) {}
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };
  const handlePageChange = (page: number | undefined) => {
    fetchCountry(page);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const columns: TableColumn<CountryItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "45%",
      sortField: "id",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      maxWidth: "50%",
      sortField: "name",
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      maxWidth: "4%",
      sortField: "code",
    },
  ];

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Country(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  return (
    <>
      <Container fluid className="mb-4">
        <h2>Country List</h2>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

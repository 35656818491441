import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../Utils/Toast";
import { NotificationTemplate } from "../../Models/NotificationTemplate";
import JoditEditor from "jodit-react";
import { NOTIFICATION_TEMPLATE_LIST } from "../../Routes/notificationTemplateRoutes";
import { Spinner } from "react-bootstrap";

function AddUpdate({ value }: any) {
  let title = "Notification Template";
  const [loading, setLoading] = useState(false);
  const [config] = useState({
    readonly: false,
    placeholder: "Start typing...",
  });

  const editor: any = useRef(null);
  const [content, setContent] = useState({
    value: "",
    subject: "",
  });
  const { id }: any = useParams();
  const navigate = useNavigate();

  const [subject, setSubject] = useState("");
  const [
    company_name,
    //  setCompany_name
  ] = useState("peanut square");
  const [
    company_logo,
    // setCompany_logo
  ] = useState("/images/svg/Space_ai_logo.svg");
  const [
    name,
    //  setName
  ] = useState("Name");
  const [
    action_by,
    //  setAction_by
  ] = useState("Action by");
  const [
    receiver_name,
    //  setReceiver_name
  ] = useState("Receiver name");
  const [
    resource_url,
    //  setResource_url
  ] = useState("Resource url");

  async function fetchNotificationTemplate() {
    const response: any = await NotificationTemplate.$query().find(id);
    content.value = response?.$attributes?.default_content;
    content.subject = response?.$attributes?.subject;
    setSubject(response?.$attributes?.subject);
  }

  useEffect(() => {
    fetchNotificationTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getValue(data: any) {
    let newValue: any = content.value;

    switch (data) {
      case "company_name":
        newValue = newValue += `${company_name}`;
        setContent({ ...content, value: newValue });
        break;

      case "name":
        newValue = newValue += ` ${name}`;
        setContent({ ...content, value: newValue });
        break;

      case "action_by":
        newValue = newValue += ` ${action_by}`;
        setContent({ ...content, value: newValue });
        break;

      case "receiver_name":
        newValue = newValue += ` ${receiver_name}`;
        setContent({ ...content, value: newValue });
        break;

      case "resource_url":
        newValue = newValue += ` ${resource_url}`;
        setContent({ ...content, value: newValue });
        break;
      case "company_logo":
        newValue =
          newValue += `<img src="${company_logo}" style="height: 33px" alt="Company Logo" />`;
        setContent({ ...content, value: newValue });
        break;

      default:
        break;
    }
  }

  async function sendEmail() {
    setLoading(true);
    try {
      const values: any = {
        subject: subject,
        default_content: content.value,
        custom_content: content.value,
        type: "mail",
      };
      await NotificationTemplate.$query().update(id, values);
      setToast("success", `${title} Successfully Updated`);
      navigate(`${NOTIFICATION_TEMPLATE_LIST}`);
    } catch (error: any) {
      setToast("error", `${error.response.data.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="row fluid">
      <div className="col-12 ">
        <div>
          <button
            type="button"
            className="btn back-btn float-end btn-sm"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>
        <h1 className="text-2xl mb-3">Template: User Invited</h1>
        <div className="p-4 w-full border rounded-md">
          <input
            className="mb-2 form-control-plaintext"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          ></input>

          <div tabIndex={1}>
            <JoditEditor
              ref={editor}
              value={content.value}
              config={config}
              onBlur={(newContent) => {}}
              onChange={(newContent) => {
                setContent({ ...content, value: newContent });
              }}
            />
          </div>
          <div className=" py-6 mx-auto">
            <button className="btn btn-success text-white px-2 py-2 mt-4 mb-4 ">
              View Resource
            </button>
          </div>
          <div className="mt-6">
            <p
              className="btn btn-success text-light h-full p-2 rounded-lg fit-content"
              onClick={() => {
                getValue("company_name");
              }}
            >
              {company_name}
            </p>{" "}
            <br />
            <img
              className=" bg-white text-white h-full p-2 rounded-lg mb-2 company-logo"
              onClick={() => {
                getValue("company_logo");
              }}
              src="/images/svg/Space_ai_logo.svg"
              alt="Company Logo"
            />
            <br />
            <p
              className="btn btn-success text-light h-full p-2 rounded-lg fit-content"
              onClick={() => {
                getValue("name");
              }}
            >
              {" "}
              {name}
            </p>
            <br />
            <p
              className="btn btn-success text-light h-full p-2 rounded-lg fit-content"
              onClick={() => {
                getValue("action_by");
              }}
            >
              {action_by}
            </p>
            <br />
            <p
              className="btn btn-success text-light h-full p-2 rounded-lg fit-content"
              onClick={() => {
                getValue("receiver_name");
              }}
            >
              {receiver_name}
            </p>
            <br />
            <button
              className="btn btn-success  text-light h-full p-2 rounded-lg text-blue-500 hover:underline"
              onClick={() => {
                getValue("resource_url");
              }}
            >
              {resource_url}
            </button>
          </div>
        </div>

        <div className="flex justify-end  align-center mr-4 my-5 pb-5">
          <button
            className="btn addnew-btn text-white hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 ml-4 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 btn-sm px-4 py-2"
            onClick={sendEmail}
            disabled={loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddUpdate;

import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import { Card, Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { setToast } from "../../Utils/Toast";
import { Eye } from "react-bootstrap-icons";
import { CAMPAIGN_DETAILS } from "../../Routes/organizationRoutes";
import { useNavigate, useParams } from "react-router-dom";
import { InputValues } from "../../Components/CustomFilter";
import { fetchData } from "../../Utils/OrionList";
import { ViewAppUrl } from "../../Models/ViewApp";
import { v4 as uuidv4 } from "uuid";
import useAxiosInstance from "../../Utils/Headers";
import CustomSkeleton from "../../Components/CustomSkeleton";
import { Cookies } from "typescript-cookie";

interface campaigns {
  id: string;
  campaign_url: string;
  track_id: string;
  iframe_id: string;
  organization_id: string;
  updated_at: string;
  created_at: string;
  title: string;
}

export const CampaignIdentifierList = () => {
  const { id } = useParams();
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const SelectedAccount = Cookies.get("selectedAccount");
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState<campaigns[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [trackId, setTrackId] = useState<string>(uuidv4());
  // eslint-disable-next-line
  const [inputValues, setInputValues] = useState<InputValues>({});
  const navigate = useNavigate();
  const [showCreateUrlModal, setShowCreateUrlModal] = useState(false);
  const [city, setCity] = useState("");
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  let title = "Campaign List";
  // eslint-disable-next-line
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
  const [loadingforsubmit, setLoadingforsubmit] = useState(false);
  const axiosInstance = useAxiosInstance();

  const handleCopyClick = (url: string) => {
    const tempInput = document.createElement("input");
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setToast("success", "URL copied successfully!");
  };
  const generateRandomAlphanumeric = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  const setDigitTrackId = () => {
    const currentDate = moment().format("DDMMYYYY-");
    const sixDigitRandomAlphaNumeric = generateRandomAlphanumeric();

    const formattedTrackId = currentDate + sixDigitRandomAlphaNumeric;
    setFormData({
      ...formData,
      track_id: formattedTrackId + city,
    });
  };
  const handleCreateUrlClick = () => {
    setDigitTrackId();
    setShowCreateUrlModal(true);
  };
  const handleCloseModal = () => {
    setSelectedUrl(null);
    setShowCreateUrlModal(false);
  };
  const [formData, setFormData] = useState({
    title: "",
    track_id: "",
  });
  const [errors, setErrors] = useState({
    title: "",
    track_id: "",
    city: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      title: "",
      track_id: "",
      city: "",
    };
    if (!formData.title.trim()) {
      newErrors.title = "title is required";
      valid = false;
    }
    if (!city.trim()) {
      newErrors.city = "City is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = async () => {
    const trackid = formData.track_id + "-" + city;
    const trackersData = {
      title: formData.title,
      track_id: trackid,
    };

    if (validateForm()) {
      setLoadingforsubmit(true);
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}api/campaigns?organization_Id=${id}`,
          trackersData,
        );
        if (response.status === 201) {
          handleCloseModal();
          getData();
          setToast("success", "URL Created Successfully");
        } else {
          console.error("Error Create Add");
          setToast("error", "Something Went Wrong");
        }
      } catch (error: any) {
        setToast("error", error.message);
      } finally {
        setLoadingforsubmit(false);
      }
    }
  };

  useEffect(() => {
    setTrackId(uuidv4());
  }, []);

  const handleRowShowButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: { id: string },
  ) => {
    e.preventDefault();
    navigate(CAMPAIGN_DETAILS.replace(":id", data.id));
  };

  const columns: TableColumn<campaigns>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "25%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      maxWidth: "25%",
      sortable: true,
      wrap: true,
      sortField: "title",
    },
    {
      name: "Track Id",
      selector: (row) => row.track_id,
      maxWidth: "25%",
      sortable: true,
      wrap: true,
      sortField: "track_id",
    },
    {
      name: "Actions",
      button: true,
      minWidth: "25%",
      cell: (row) => (
        <>
          <Button
            title="Copy Url"
            variant="success-light"
            size="sm"
            className="me-2 btn-sm btn-font"
            onClick={() => handleCopyClick(row.campaign_url)}
          >
            <FontAwesomeIcon icon={faCopy} size="lg" /> Copy Url
          </Button>
          <Button
            variant="primary-light"
            size="sm"
            className="me-2 btn-sm btn-font"
            onClick={(e) => handleRowShowButtonClick(e, { id: row.id })}
          >
            <Eye /> View
          </Button>
        </>
      ),
    },
  ];

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new ViewAppUrl(),
      sortField,
      sortOrder,
    );
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new ViewAppUrl(),
      sortField,
      sortOrder,
    );
  };

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  return (
    <>
      <Card className="min-height-card">
        <Card.Header className="d-flex justify-content-between align-items-center mb-0">
          <h1 className="m-0">{title}</h1>
          {(organizationIdFromCookie || SelectedAccount) && (
            <button
              className="btn addnew-btn btn-sm"
              onClick={() => handleCreateUrlClick()}
            >
              Create Url
            </button>
          )}
        </Card.Header>
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={getData}
          onSort={handleSort}
          noDataComponent={
            <>
              <img src="/images/svg/No_data_available.svg" alt="" />
            </>
          }
          progressComponent={
            <CustomSkeleton
              customInnerStyle={{ marginBottom: "9.5px" }}
              count={10}
              height={36}
            />
          }
        />
      </Card>
      <Modal
        dialogClassName="custom-modal"
        centered
        show={showCreateUrlModal}
        onHide={handleCloseModal}
      >
        <Modal.Header className="border-0">
          <h3>Campaign Identifier Form</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              id="title"
              aria-describedby="Help"
              placeholder="Enter title"
              required
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
            {errors.title && <div className="text-danger">{errors.title}</div>}
          </div>
          <div className="d-flex justify-content-between">
            <div className="mb-3 campaign-width">
              <label htmlFor="track_id" className="form-label">
                Track ID
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                id="track_id"
                aria-describedby="Help"
                placeholder="Enter city"
                required
                value={formData.track_id}
                onChange={(e) =>
                  setFormData({ ...formData, track_id: e.target.value })
                }
              />

              {errors.track_id && (
                <div className="text-danger">{errors.track_id}</div>
              )}
            </div>
            <div className="mb-3 campaign-width">
              <label htmlFor="city" className="form-label">
                Campaign City
              </label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                id="city"
                placeholder="Enter Campaign City"
                required
                onChange={(e) => setCity(e.target.value)}
              />
              {errors.city && <div className="text-danger">{errors.city}</div>}
            </div>
          </div>
          <p className="text-justify">
            <small id="Help" className="form-text text-muted">
              <span className="fw-bold">Note:-</span> Campaign Identifier This
              field contains either the city name abbreviation (e.g., AHM for
              Ahmedabad) or the sales team's mobile number (e.g., 123456789),
              along with campaign details, enabling identification of the
              originating team or location in the campaign sharing URL and
              facilitating targeted tracking and analysis of promotional
              campaigns.
            </small>
          </p>
          <div className="card fs-2 d-flex justify-content-center align-items-center form-control form-control-lg form-control-solid">
            <span className="">
              {formData.track_id}-{city}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="btn back-btn btn-sm px-4 py-1"
            onClick={handleCloseModal}
          >
            Close
          </button>
          <button
            className="btn addnew-btn btn-sm px-4 py-1"
            onClick={handleSubmit}
          >
            {loadingforsubmit ? (
              <Spinner
                className="mt-1 mx-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

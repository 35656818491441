import { RouteObject } from "react-router-dom";
import Notifications from "../Components/Notifications/Notifications";
import AuthorizedRoute from "./AuthorizedRoute";
import { VIEW_NOTIFICATION } from "../Utils/PermissionsEnum";

export const NOTIFICATION_ALL_USERS = "Notifications";

const NotificationsRouter: RouteObject[] = [
  {
    path: NOTIFICATION_ALL_USERS,
    element: (
      <AuthorizedRoute
        element={<Notifications />}
        permission={VIEW_NOTIFICATION}
      />
    ),
  },
];

export default NotificationsRouter;

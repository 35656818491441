import { Model } from "@tailflow/laravel-orion/lib/model";

export class NotificationTemplate extends Model<{
  name: string;
  organization_id: number;
}> {
  public $resource(): string {
    return "notification_templates";
  }
}

import { setCookie, getCookie, removeCookie } from "typescript-cookie";
const TOKEN_KEY = "authToken";
const EXPIRES_TIME = 3600 / (24 * 60 * 60);
const ORGANIZATION_ID = "organizationId";

export const setToken = (token: string) => {
  setCookie(TOKEN_KEY, JSON.stringify(token), { expires: EXPIRES_TIME });
};

export const getToken = () => {
  return getCookie(TOKEN_KEY);
};

export const setOrganizationId = (organizationId: any) => {
  setCookie(ORGANIZATION_ID, organizationId, { expires: 1 });
};
export const resetOrganizationId = () => {
  removeCookie(ORGANIZATION_ID);
};

const userCookie = getCookie("user");
if (userCookie) {
  const user = JSON.parse(userCookie);
  setOrganizationId(user.user_organizations[0].id);
}

export const getUser = () => {
  const user = getCookie("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const removeToken = () => {
  removeCookie(TOKEN_KEY);
  removeCookie("user");
  removeCookie("selectedAccount");
  removeCookie("OrganizationIdForView");
  removeCookie("OrganizationNameForView");
  removeCookie("organizationId");
};

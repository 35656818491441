import React, { useState } from "react";
import { Form, Image, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { setCookie } from "typescript-cookie";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { setToast } from "../../Utils/Toast";

const SignIn = () => {
  const ORGANIZATION_ID = "organizationId";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const apiUrl = (process.env.REACT_APP_API_URL as string) + "api/login";
    const payload = { email, password };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const { token, user } = data;
        const expirationTime = Date.now() + 3600 * 1000;
        const organizationId = user.user_organizations[0].id;
        setCookie("authToken", token, { expires: expirationTime });
        setCookie(ORGANIZATION_ID, organizationId, { expires: expirationTime });
        setCookie("user", JSON.stringify(user), { expires: expirationTime });
        // eslint-disable-next-line
        if (user.roles?.some((item: any) => item.name == "ROLE_SUPER_ADMIN")) {
          navigate("/dashboard");
        } else {
          // eslint-disable-next-line
          if (user.roles?.some((item: any) => item.name == "ROLE_ADMIN")) {
            navigate("/selectaccount");
          } else {
            navigate("/");
          }
        }
        setToast("success", "Login successfully!");
      } else {
        setToast("error", "Invalid email or password. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row vh-100">
      <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
        <div className="signin-form-wrapper">
          <div className="text-center">
            <img
              src="/images/svg/Space_ai_logo.svg"
              className="d-inline-block align-top signin-logo mb-5"
              alt="Space AI Logo"
            />
          </div>
          <h1 className="mt-5 text-center">Sign In</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="pt-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="form-control form-control-lg form-control-solid"
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  className="form-control form-control-lg form-control-solid"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </span>
              </div>
            </Form.Group>

            <div className="float-end mt-1 mb-5">
              <Link
                to="/ForgotPassword"
                className="mt-2 text-center forgotpass"
              >
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="mt-3 mb-2 mb-md-0 btn addnew-btn btn-sm py-2 border-0 w-100"
              disabled={loading}
            >
              {loading ? (
                <span className="loadingspinnerbtn">
                  <Spinner
                    as="span"
                    animation="border"
                    className="m-1"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : (
                <span className="fs-5">Sign In</span>
              )}
            </button>
          </Form>
        </div>
      </div>
      <div className="col-md-6 col-12 d-none d-lg-flex justify-content-end bg-white">
        <Image
          src="/images/Console_sign_in_image.jpg"
          className="signin-image"
          alt="Placeholder Image"
        />
      </div>
    </div>
  );
};

export default SignIn;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Visitor extends Model<{
  visitor_id: string;
  visitor_ip: string;
  tracking_code: string;
  iframe_id: string;
  vendor_code: string;
  product_type_id: number;
  domain: string;
  is_in_top_window: boolean;
  use_history_padding: boolean;
  organization_id: number;
  id: number;
  created_at: string;
  deleted_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "visitors";
  }
}

import axios from "axios";
import { getToken } from "./Auth";
import { useMemo } from "react";
import { useOrganization } from "./OrganizationContext";

const useAxiosInstance = () => {
  let { selectedOrganizationId, organizationId }: any = useOrganization();

  const getInstance = (contentType?: string) => {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": contentType ? contentType : "application/json",
      "ORGANIZATION-ID": selectedOrganizationId
        ? selectedOrganizationId
        : organizationId,
    };

    return axios.create({
      headers,
    });
  };

  const axiosInstance = useMemo(
    () => getInstance(),
    // eslint-disable-next-line
    [getToken, organizationId, selectedOrganizationId],
  );

  return axiosInstance;
};

export default useAxiosInstance;

import { RouteObject } from "react-router-dom";
import List from "../Pages/Products/List";
import ProductImportFile from "../Pages/Products/ImportProduct";
import View from "../Pages/Products/view";
import AuthorizedRoute from "./AuthorizedRoute";
import { ADD_PRODUCT, VIEW_PRODUCT } from "../Utils/PermissionsEnum";
import UploadProduct from "../Pages/Products/UploadProduct";
import ProductEdit from "../Pages/Products/AddUpdate";

export const PRODUCT_LIST = "products";
export const PRODUCT_ZIP_ADD = "zip/add";
export const PRODUCT_ADD = "add";
export const PRODUCT_VIEW = ":id/view";
export const PRODUCT_EDIT = ":id/edit";

const ProductsRoutes: RouteObject[] = [
  {
    path: PRODUCT_LIST,
    element: <AuthorizedRoute element={<List />} permission={VIEW_PRODUCT} />,
  },
  {
    path: `${PRODUCT_LIST}/${PRODUCT_ZIP_ADD}`,
    element: (
      <AuthorizedRoute
        element={<ProductImportFile />}
        permission={ADD_PRODUCT}
      />
    ),
  },
  {
    path: `${PRODUCT_LIST}/${PRODUCT_ADD}`,
    element: (
      <AuthorizedRoute element={<UploadProduct />} permission={ADD_PRODUCT} />
    ),
  },
  {
    path: `${PRODUCT_LIST}/${PRODUCT_VIEW}`,
    element: <AuthorizedRoute element={<View />} permission={VIEW_PRODUCT} />,
  },
  {
    path: `${PRODUCT_LIST}/${PRODUCT_EDIT}`,
    element: (
      <AuthorizedRoute element={<ProductEdit />} permission={VIEW_PRODUCT} />
    ),
  },
];

export default ProductsRoutes;

import { getCookie } from "typescript-cookie";

export const getUserRolesFromCookie = (): string[] | null => {
  const userCookie = getCookie("user");
  if (!userCookie) {
    console.error("User cookie is not available");
    return null;
  }

  try {
    const user = JSON.parse(userCookie);
    return user.roles.map((role: any) => role.name);
  } catch (error) {
    console.error("Failed to parse user cookie", error);
    return null;
  }
};

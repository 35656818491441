import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Organization } from "../../Models/Organization";
import { setToast } from "../../Utils/Toast";
import AddUpdateForm from "../../Components/AddUpdateForm";
import { ProductType } from "../../Models/ProductType";
import { IndustriesType } from "../../Models/IndustriesType";
import useAxiosInstance from "../../Utils/Headers";

interface AddUpdateProps {
  title?: any;
}

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  description?: string;
  placeholder?: string;
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
  default: string;
  options?: any;
}

const AddUpdate: React.FC<AddUpdateProps> = () => {
  const apiUrl = (process.env.REACT_APP_API_URL as string) + "api/signup";
  const axiosInstance = useAxiosInstance();
  const { id } = useParams();
  const navigate = useNavigate();
  let title = "Organization";

  const defaultFormData = {
    name: "",
    email: "",
    organization_handle: "",
    theme_fg_color: "",
    theme_bg_color: "",
    front_short_description: "",
    password: "",
    confirm_password: "",
    organization_name: "",
    product_type_id: "",
    industries_type_id: "",
    mobile_no: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [data, setData] = useState<IndustriesType[]>([]);
  // eslint-disable-next-line
  const [productTypeSuggestions, setProductTypeSuggestions] = useState<
    ProductType[]
  >([]);
  const [errorMessages, setErrorMessages] = useState({});

  const fetchProductTypeSuggestions = async () => {
    try {
      const productTypes: ProductType[] = await ProductType.$query().get();
      setProductTypeSuggestions(productTypes);
    } catch (error) {
      console.error("Error fetching product type suggestions:", error);
    }
  };

  const getData = async () => {
    try {
      const industriesType: IndustriesType[] =
        await IndustriesType.$query().get();
      setData(industriesType);
    } catch (error) {
      console.error("Error fetching industries type data:", error);
    }
  };

  useEffect(() => {
    fetchProductTypeSuggestions();
    getData();
  }, []);

  const industriesTypeOptions: any[] = data.map((o: any) => ({
    value: o.$attributes.id,
    label: o.$attributes.title,
  }));

  const formFields: Field[] = [
    {
      id: "name",
      title: "Name*",
      type: "string",
      placeholder: "Enter Name",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      required: true,
      default: formData ? formData.name : defaultFormData.name,
    },
    ...(id
      ? [
          {
            id: "theme_fg_color",
            title: "Foreground Color",
            type: "string",
            placeholder: "Select a color...",
            col: 6,
            validate: [
              {
                rule: (value: any) => !!value,
                message: "Color is required",
              },
            ],
            required: true,
            default: formData
              ? formData.theme_fg_color
              : defaultFormData.theme_fg_color,
          },
          {
            id: "theme_bg_color",
            title: "Background Color",
            type: "string",
            placeholder: "Select a color...",
            col: 6,
            validate: [
              {
                rule: (value: any) => !!value,
                message: "Color is required",
              },
            ],
            required: true,
            default: formData
              ? formData.theme_bg_color
              : defaultFormData.theme_bg_color,
          },
          {
            id: "front_short_description",
            title: "Description",
            type: "string",
            placeholder: "Enter a description...",
            col: 6,
            validate: [
              {
                rule: (value: any) => !!value,
                message: "Description is required",
              },
            ],
            required: true,
            default: formData
              ? formData.front_short_description
              : defaultFormData.front_short_description,
          },
        ]
      : []),
    {
      id: "email",
      title: "Email",
      type: "string",
      placeholder: "Enter an email...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
      required: true,
      default: formData ? formData.email : defaultFormData.email,
    },

    {
      id: "organization_handle",
      title: "Organization Handle",
      type: "string",
      placeholder: "Enter an organization handle...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Organization Handle is required",
        },
      ],
      required: true,
      default: formData
        ? formData.organization_handle
        : defaultFormData.organization_handle,
    },
    {
      id: "organization_name",
      title: "Organization Name",
      type: "string",
      placeholder: "Enter an organization name...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Organization Name is required",
        },
      ],
      required: true,
      default: formData
        ? formData.organization_name
        : defaultFormData.organization_name,
    },
    {
      id: "password",
      title: "Password",
      type: "string",
      placeholder: "Enter a password...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Password is required",
        },
      ],
      required: true,
      default: formData ? formData.password : defaultFormData.password,
    },
    {
      id: "confirm_password",
      title: "Confirm Password",
      type: "string",
      placeholder: "Enter a confirm password...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Confirm Password is required",
        },
      ],
      required: true,
      default: formData
        ? formData.confirm_password
        : defaultFormData.confirm_password,
    },
    {
      id: "mobile_no",
      title: "Mobile Number",
      type: "string",
      placeholder: "Enter a mobile number...",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Mobile Number is required",
        },
      ],
      required: true,
      default: formData ? formData.mobile_no : defaultFormData.mobile_no,
    },
    {
      id: "industries_type_id",
      title: "Industry Type",
      type: "select",
      placeholder: "Select an industry type...",
      col: 6,
      options: industriesTypeOptions,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Industry Type is required",
        },
      ],
      required: true,
      default: formData
        ? formData.industries_type_id
        : defaultFormData.industries_type_id,
    },
    {
      id: "theme_fg_color",
      title: "Foreground Color",
      type: "color",
      placeholder: "Select color",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Color is required",
        },
      ],
      required: true,
      default: formData
        ? formData.theme_fg_color
        : defaultFormData.theme_fg_color,
    },
    {
      id: "theme_bg_color",
      title: "Background Color",
      type: "color",
      placeholder: "Select color",
      col: 6,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Color is required",
        },
      ],
      required: true,
      default: formData
        ? formData.theme_bg_color
        : defaultFormData.theme_bg_color,
    },
  ];

  const handleSubmit = async (formData: any) => {
    try {
      const isValid = formFields.every((field) => {
        if (field.validate) {
          const fieldIsValid = field.validate.every((rule) =>
            rule.rule(formData[field.id]),
          );
          if (!fieldIsValid) {
            setErrorMessages({
              ...errorMessages,
              [field.id]: field.validate[0].message,
            });
            return false;
          }
        }
        return true;
      });

      if (!isValid) return;
      // eslint-disable-next-line
      const response = await axiosInstance.post(apiUrl, formData);
      setToast("success", "Organization Successfully Added");
      navigate(-1);
    } catch (error: any) {
      console.error("Error adding organization:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessages(error.response.data.message);
      } else {
        setErrorMessages({ general: "Error adding organization" });
      }
      setFormData(formData);
    }
  };

  return (
    <AddUpdateForm
      model={Organization}
      title={title}
      customHandleSubmit={handleSubmit}
      customErrorMessages={errorMessages}
      formFields={formFields}
    />
  );
};

export default AddUpdate;

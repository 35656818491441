import { RouteObject } from "react-router-dom";
import EditProfile from "../Pages/Profile/EditProfile";

export const PROFILE_EDIT = "edit-profile";

const EditProfileRoutes: RouteObject[] = [
  { path: PROFILE_EDIT, element: <EditProfile /> },
];

export default EditProfileRoutes;

import { RouteObject } from "react-router-dom";
import List from "../Pages/TaxTypes/List";
import AddUpdate from "../Pages/TaxTypes/AddUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_TAX_TYPE,
  UPDATE_TAX_TYPE,
  VIEW_TAX_TYPE,
} from "../Utils/PermissionsEnum";

export const TAX_TYPES_LIST = "tax-types";
export const TAX_TYPES_ADD = "add";
export const TAX_TYPES_EDIT = ":id/edit";

const taxTypesRoutes: RouteObject[] = [
  {
    path: TAX_TYPES_LIST,
    element: <AuthorizedRoute element={<List />} permission={VIEW_TAX_TYPE} />,
  },
  {
    path: `${TAX_TYPES_LIST}/${TAX_TYPES_ADD}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={ADD_TAX_TYPE} />
    ),
  },
  {
    path: `${TAX_TYPES_LIST}/${TAX_TYPES_EDIT}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={UPDATE_TAX_TYPE} />
    ),
  },
];

export default taxTypesRoutes;

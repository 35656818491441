import { RouteObject } from "react-router-dom";
import List from "../Pages/Country/List";
import AuthorizedRoute from "./AuthorizedRoute";
import { VIEW_COUNTRY } from "../Utils/PermissionsEnum";

export const COUNTRY_LIST = "countries";

const countryRoutes: RouteObject[] = [
  {
    path: COUNTRY_LIST,
    element: <AuthorizedRoute element={<List />} permission={VIEW_COUNTRY} />,
  },
];

export default countryRoutes;

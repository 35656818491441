import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { ProductType } from "../../Models/ProductType";
import { Link, useNavigate } from "react-router-dom";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import moment from "moment";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { fetchData } from "../../Utils/OrionList";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomDeleteModal from "../../Components/CustomDeleteModal";
import {
  PRODUCT_TYPE_ADD,
  PRODUCT_TYPE_EDIT,
} from "../../Routes/ProductTypesRoutes";
import { useOrganization } from "../../Utils/OrganizationContext";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface ProductTypeItem {
  id: number;
  title: string;
  created_at: number;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "Product Type";

  const [data, setData] = useState<ProductTypeItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);

  const handleRowEditButtonClick = (id: number) => {
    navigate(PRODUCT_TYPE_EDIT.replace(":id", id.toString()));
  };

  const handleRowDeleteButtonClick = (id: number) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<ProductTypeItem>[] = [
    {
      name: "Id",
      maxWidth: "20%",
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Product type",
      maxWidth: "30%",
      wrap: true,
      selector: (row) => row.title,
      sortable: true,
      sortField: "title",
      sortFunction: (a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
    },
    {
      name: "Date",
      maxWidth: "30%",
      selector: (row: any) => moment(row.created_at).format("DD-MM-YYYY"),
    },
    {
      name: "Actions",
      minWidth: "20%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];
  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      variant="outline-primary"
      className="outline-border"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="btn-font"
        onClick={() => handleRowDeleteButtonClick(row.id)}
      >
        <Trash3 className="mb-1" /> Delete
      </Dropdown.Item>
    </DropdownButton>
  );

  // ==============================================
  const fetchProductType = async (page: number | undefined) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new ProductType(),
      sortField,
      sortOrder,
    );
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "title",
      value: "title",
      label: "Title",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new ProductType(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================

  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>
        <Row className="mt-4">
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={fetchProductType}
          />
          <Col className="text-md-end ">
            <Link to={`${PRODUCT_TYPE_ADD}`}>
              <button className="btn addnew-btn btn-sm mb-2">
                Add {title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={fetchProductType}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.2px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />

      <CustomDeleteModal
        title={title}
        model={ProductType}
        getData={fetchProductType}
        deleteItemId={deleteItemId}
        setDeleteItemId={setDeleteItemId}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
}

export default List;

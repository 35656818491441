import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Products } from "../../Models/Products";
import useAxiosInstance from "../../Utils/Headers";
import { Card, Container, Form } from "react-bootstrap";
import SelectField from "../../Components/AsyncSelect";
import ActivityLogs from "../../Components/ActivityLogs";
import { setToast } from "../../Utils/Toast";

const ProductEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [productColor, setProductColor] = useState<any[]>([]);
  const [productSize, setProductSize] = useState<any[]>([]);
  const axiosInstance = useAxiosInstance();
  const [productData, setProductData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<{ [key: string]: any }>({
    title: "",
    product_name: "",
    description: "",
    tiles_size_id: "",
    product_color_id: "",
    is_for_wall: false,
    is_for_floor: false,
    width: "",
    height: "",
  });

  useEffect(() => {
    if (id) {
      fetchProductSize();
      fetchProductColors();
      fetchProduct();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (productData) {
      setFormValues({
        title: productData.title,
        product_name: productData.product_name,
        description: productData.description,
        tiles_size_id: productData.tiles_size_id,
        product_color_id: productData.product_color_id,
        is_for_wall: productData.is_for_wall,
        is_for_floor: productData.is_for_floor,
      });
    }
  }, [productData]);

  const fetchProductSize = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/tiles_size`,
      );
      const formattedProductSize = response.data.data.map(
        (productsize: any) => ({
          value: productsize.id,
          label: `${productsize.width} * ${productsize.height}`,
          width: productsize.width,
          height: productsize.height,
        }),
      );
      setProductSize(formattedProductSize);
    } catch (error) {
      console.error("Error fetching product sizes:", error);
    }
  };

  const fetchProductColors = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/product_colors`,
      );
      const formattedColors = response.data.data.map((color: any) => ({
        value: color.id,
        label: color.title,
      }));
      setProductColor(formattedColors);
    } catch (error) {
      console.error("Error fetching product colors:", error);
    }
  };

  const fetchProduct = async () => {
    if (id) {
      try {
        const product: any = await Products.$query().find(id);
        setProductData(product.$attributes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    }
  };

  const handleSizeChange = (selectedOption: any | null) => {
    if (selectedOption) {
      setFormValues((prevState) => ({
        ...prevState,
        tiles_size_id: selectedOption.value,
        width: selectedOption.width,
        height: selectedOption.height,
      }));
    }
  };

  const handleColorChange = (selectedOption: any | null) => {
    if (selectedOption) {
      setFormValues((prevState) => ({
        ...prevState,
        product_color_id: selectedOption.value,
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value, type } = e.target;
    const isChecked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : null;

    setFormValues((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? isChecked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}api/products/${id}`,
        formValues,
      );
      if (response.status === 200) {
        setToast("success", "Product updated successfully");
        navigate(-1);
      }
      console.log("Form submitted with values:", formValues);
    } catch (error) {
      console.error("Error updating product:", error);
      setToast("error", "Error updating product");
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <Container>
        <Card className="mb-0">
          <Card.Header>Edit Product</Card.Header>
          <Card.Body>
            <Form className=" p-3" onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between">
                <Form.Group
                  controlId="title"
                  style={{ width: "47%" }}
                  className="pt-2"
                >
                  <Form.Label className="form-label">Title:</Form.Label>
                  <input
                    type="text"
                    id="title"
                    className="form-control form-control-lg form-control-solid"
                    name="title"
                    value={formValues.title}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  controlId="product_name"
                  style={{ width: "47%" }}
                  className="pt-2"
                >
                  <Form.Label className="form-label">Product Name:</Form.Label>
                  <input
                    type="text"
                    id="product_name"
                    className="form-control form-control-lg form-control-solid"
                    name="product_name"
                    value={formValues.product_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>

              <div className=" d-flex justify-content-between mt-4">
                <Form.Group
                  controlId="tiles_size_id"
                  style={{ width: "47%" }}
                  className="pt-2"
                >
                  <Form.Label className="form-label">Size:</Form.Label>
                  <SelectField
                    options={productSize}
                    value={productSize.find(
                      (option) => option.value === formValues.tiles_size_id,
                    )}
                    onChange={handleSizeChange}
                  />
                </Form.Group>

                <Form.Group
                  controlId="product_color_id"
                  style={{ width: "47%" }}
                  className="pt-2"
                >
                  <Form.Label className="form-label">Color:</Form.Label>
                  <SelectField
                    options={productColor}
                    value={productColor.find(
                      (option) => option.value === formValues.product_color_id,
                    )}
                    onChange={handleColorChange}
                  />
                </Form.Group>
              </div>
              <div className="d-flex mt-3">
                <div className="form-group">
                  <span className="mt-2">
                    <input
                      type="checkbox"
                      id="is_for_floor"
                      checked={formValues.is_for_wall}
                      onChange={handleChange}
                    />
                  </span>
                  <label htmlFor="is_for_floor">Is for Floor</label>
                </div>
                <div className="form-group mx-4">
                  <input
                    type="checkbox"
                    id="is_for_wall"
                    checked={formValues.is_for_floor}
                    onChange={handleChange}
                  />
                  <label htmlFor="is_for_wall">Is for Wall</label>
                </div>
              </div>
              <div className="mb-3">
                <Form.Label className="form-label">Description:</Form.Label>
                <textarea
                  id="description"
                  className="form-control form-control-lg form-control-solid"
                  name="description"
                  value={formValues.description}
                  onChange={handleChange}
                />
              </div>
              <div className=" d-flex justify-content-end text-center d-grid gap-2 mb-4">
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-sm back-btn me-2 mt-4 fs-6 text-white ps-5 pe-5"
                  type="button"
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="mt-4 btn addnew-btn btn-sm ps-5 pe-5"
                  disabled={loading}
                >
                  {loading ? "Uploading..." : "Submit"}
                </button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <div className="activity-log-for-product mt-5">
        {id && (
          <Card className="activity-card-product">
            <Card.Header className="mb-4">Activity Logs</Card.Header>
            <ActivityLogs auditable_type={"PRODUCT"} id={id} />
          </Card>
        )}
      </div>
    </>
  );
};

export default ProductEdit;

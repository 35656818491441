export const handleImageError = (
  e: React.SyntheticEvent<HTMLImageElement, Event>,
  fallbackSrc: string,
  width: string = "100%",
  height: string = "100px",
) => {
  const img = e.currentTarget;
  if (img.src !== fallbackSrc) {
    img.src = fallbackSrc;
    img.style.width = width;
    img.style.height = height;
    img.style.backgroundColor = "transparent";
  }
};

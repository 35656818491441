import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const VisitorLocationChart: React.FC = () => {
  const [chartData, setChartData] = useState({
    categories: [
      "South Korea",
      "Canada",
      "United Kingdom",
      "Netherlands",
      "Italy",
      "France",
    ],
    series: [
      {
        name: "",
        data: [90, 100, 5, 96, 140, 50],
      },
    ],
  });

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },
    legend: {
      show: false,
    },
  };

  useEffect(() => {
    // Simulate async data fetching
    // Replace with actual API call or data fetching logic
    setTimeout(() => {
      // Example of setting fetched data
      setChartData({
        categories: [
          "South Korea",
          "Canada",
          "United Kingdom",
          "Netherlands",
          "Italy",
          "France",
        ],
        series: [
          {
            name: "",
            data: [90, 100, 5, 96, 140, 50],
          },
        ],
      });
    }, 1000); // Simulated delay
  }, []);

  return (
    <div id="chart">
      <Chart
        options={options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default VisitorLocationChart;

import { Container } from "react-bootstrap";
import "@fontsource/open-sans";

interface NotFoundModalProps {
  errorCode: number;
  errorMessage: string;
  buttonName: string;
  redirect: string;
}

const NotFound: React.FC<NotFoundModalProps> = ({
  errorCode,
  errorMessage,
  buttonName,
  redirect,
}) => {
  return (
    <Container className=" border-5 not-found">
      <div className="row fluid ">
        <div className="col-3"></div>
        <div className="col-6; text-center">
          <div className="notfound">
            <div className="notfound-404">
              <h1 className="error-code">{errorCode}</h1>
              <h5> {errorMessage}</h5>
            </div>
            <button
              className="btn addnew-btn btn-sm"
              onClick={() => (window.location.href = redirect)}
            >
              {buttonName}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Organization extends Model<{
  name: string;
  created_at: string;
}> {
  public $resource(): string {
    return "organizations";
  }
}

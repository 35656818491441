import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const BalanceChart = () => {
  const chartOptions: ApexOptions = {
    chart: {
      id: "balance-chart",
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: "#00E396",
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: "#008FFB",
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        show: false,
        style: {
          colors: "#00E396",
          fontSize: "12px",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 4,
    },
    fill: {
      type: "gradient",
    },
    series: [
      {
        name: "Balance Add",
        data: [306, 460, 356, 506, 669, 206, 461],
      },
      {
        name: "Balance Used",
        data: [206, 360, 265, 460, 365, 460, 306],
      },
    ],
    colors: ["#008FFB", "#00E396"],
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    grid: {
      show: true,
      borderColor: "#e7e7e7",
      strokeDashArray: 4,
    },
    markers: {
      size: 0,
    },
    legend: {
      show: true,
      position: "bottom",
    },
  };

  return (
    <div className="chart-container mb-5">
      <Chart
        options={chartOptions}
        series={chartOptions.series}
        type="area"
        height={270}
      />
    </div>
  );
};

export default BalanceChart;

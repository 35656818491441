import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { setToast } from "../Utils/Toast";
import useAxiosInstance from "../Utils/Headers";

interface AssignPermissionModalProps {
  show: boolean;
  handleClose: () => void;
  organizationId: number;
}

const AssignPermissionModal: React.FC<AssignPermissionModalProps> = ({
  show,
  handleClose,
  organizationId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosInstance();

  const handleSubmit = async () => {
    if (inputValue.toLowerCase() === "ok") {
      console.log("Permission is 'ok'. Performing action...");
      setLoading(true);
      await handleAssignPermission(organizationId);
      setLoading(false);
    } else {
      console.log("Permission is not 'ok'.");
    }
  };

  const handleAssignPermission = async (organizationId: number) => {
    try {
      const response = await axiosInstance.post(
        `https://api-spaceai.test-apps.peanutsquare.com/api/add_permissions_for_organizations`,
        {
          organization_id: organizationId,
        },
      );

      if (response.status === 200) {
        setToast("success", "Assign Permission Successfully");
        handleClose();
      } else {
        setToast("error", "Failed to Assign Permission");
      }
    } catch (error: any) {
      console.error("API Error:", error);
      if (error.response && error.response.status === 400) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  useEffect(() => {
    if (show) {
      setInputValue("");
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
        <Modal.Title>Assign Permission</Modal.Title>
        <img
          src="/images/svg/closeicon.svg"
          alt="close"
          className="custom-close-icon mb-4"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          placeholder="Type 'OK' to confirm"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="w-100 form-control form-control-lg form-control-solid"
        />
      </Modal.Body>
      <Modal.Footer className="border-0">
        {loading ? (
          <button disabled className="btn edit-btn btn-sm">
            <Spinner animation="border" size="sm" /> Confirming...
          </button>
        ) : (
          inputValue.toLowerCase() === "ok" && (
            <button className="btn edit-btn btn-sm" onClick={handleSubmit}>
              Confirm
            </button>
          )
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AssignPermissionModal;

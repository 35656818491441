import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
interface DetailsProps {
  data: any;
}

const Details: React.FC<DetailsProps> = ({ data }) => {
  const [showCopymobileMessage, setShowCopymobileMessage] = useState(false);
  const [showCopyEmailMessage, setShowCopyEmailMessage] = useState(false);

  const copyMobileNumber = () => {
    const mobileNumber = data.mobile_number;
    if (mobileNumber) {
      navigator.clipboard
        .writeText(mobileNumber)
        .then(() => {
          setShowCopymobileMessage(true);
          setTimeout(() => setShowCopymobileMessage(false), 2000);
        })
        .catch((error) => {
          console.error("Failed to copy mobile number: ", error);
        });
    }
  };
  const copyEmail = () => {
    const email = data.email;
    if (email) {
      navigator.clipboard
        .writeText(email)
        .then(() => {
          setShowCopyEmailMessage(true);
          setTimeout(() => setShowCopyEmailMessage(false), 2000);
        })
        .catch((error) => {
          console.error("Failed to copy email ", error);
        });
    }
  };

  return (
    <>
      <Card className="mt-5">
        <Card.Header className="align-items-center">
          <h1 className="m-0">Details</h1>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table">
              <tbody className="col-12">
                <tr>
                  <td className="col-sm-2 text-secondary">Email:</td>
                  <td className="col-sm-4">
                    {data.email ? (
                      <>
                        <strong>
                          {data.email}
                          <button
                            title="Copy email"
                            className="fs bg-transparent border-0 btn-sm fs-7"
                            onClick={copyEmail}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </strong>
                      </>
                    ) : (
                      <span></span>
                    )}
                    {showCopyEmailMessage && (
                      <div className="copy-message">email copied!</div>
                    )}
                  </td>
                  <td className="col-sm-2 text-secondary">Mobile:</td>
                  <td className="col-sm-4">
                    {data.mobile_number ? (
                      <>
                        <strong>
                          {data.mobile_number}
                          <button
                            title="Copy number"
                            className="fs bg-transparent border-0 btn-sm fs-7"
                            onClick={copyMobileNumber}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </strong>
                      </>
                    ) : (
                      <span></span>
                    )}
                    {showCopymobileMessage && (
                      <div className="copy-message">Mobile number copied!</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="col-sm-2 text-secondary">Organization id:</td>
                  <td className="col-sm-4">
                    <strong>{data.organization_id}</strong>
                  </td>
                  <td className="col-sm-2 text-secondary">Tracking code:</td>
                  <td className="col-sm-4">
                    <strong>{data.tracking_code}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="col-sm-2 text-secondary">Vendor code:</td>
                  <td className="col-sm-4">
                    <strong>{data.vendor_code}</strong>
                  </td>
                  <td className="col-sm-2 text-secondary">History padding:</td>
                  <td className="col-sm-4">
                    <strong>{data.use_history_padding}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="col-sm-2 text-secondary">Blocked:</td>
                  <td className="col-sm-4">
                    <strong>{data.is_blocked}</strong>
                  </td>
                  <td className="col-sm-2 text-secondary">Top window:</td>
                  <td className="col-sm-4">
                    <strong>{data.is_in_top_window}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="col-sm-2 text-secondary">Created At:</td>
                  <td className="col-sm-4">
                    <strong>
                      {moment(data.created_at).format("DD-MMM-YYYY")}
                    </strong>
                  </td>
                  <td className="col-sm-2 text-secondary">Updated At:</td>
                  <td className="col-sm-4">
                    <strong>
                      {moment(data.updated_at).format("DD-MMM-YYYY")}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Details;

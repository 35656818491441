import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Card } from "react-bootstrap";
import { OrganizationBalanceLog } from "../../../Models/OrganizationBalanceLog";
import { useOrganization } from "../../../Utils/OrganizationContext";
import { fetchData } from "../../../Utils/OrionList";
import CustomFilter from "../../../Components/CustomFilter";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import moment from "moment";

interface OrganizationBalanceLogItem {
  id: number;
  organization_id: number;
  added_balance: string;
  added_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  user: any;
}

function BalanceLog() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "balance log";

  const [data, setData] = useState<OrganizationBalanceLogItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "added_balance",
      value: "added_balance",
      label: "Added Balance",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created At ",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const columns: TableColumn<OrganizationBalanceLogItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "33.33%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Date",
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY"),
      maxWidth: "33.33%",
      sortable: true,
      sortField: "created_at",
    },
    {
      name: "Added Balance",
      selector: (row) => row.added_balance,
      maxWidth: "33.33%",
      wrap: true,
      sortable: true,
      sortField: "added_balance",
    },
    {
      name: "Added By",
      selector: (row) => row.user?.name,
      maxWidth: "33.33%",
      wrap: true,
      sortable: false,
      sortField: "added_by",
    },
  ];

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new OrganizationBalanceLog(),
      ["user"],
    );
  };

  // ==============================================
  const fetchTaxType = async (page: number | undefined) => {
    setLoading(true);
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new OrganizationBalanceLog(),
        sortField,
        sortOrder,
        ["user"],
      );
    } catch (error) {
      console.log("An unknown error occurred:", error);
    }
  };

  const handlePageChange = (page: number | undefined) => {
    fetchTaxType(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new OrganizationBalanceLog(),
      sortField,
      sortOrder,
      ["user"],
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================

  return (
    <>
      <Card>
        <Card.Header>{title} List</Card.Header>
        <Card.Body>
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={getData}
          />
          <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={
              <CustomSkeleton
                customInnerStyle={{ marginBottom: "9.5px" }}
                count={totalRows || 10}
                height={36}
              />
            }
            onSort={handleSort}
            noDataComponent={
              <>
                <img src="/images/svg/No_data_available.svg" alt="" />
              </>
            }
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default BalanceLog;

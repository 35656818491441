import React, { useState } from "react";
import { Form, Image, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import { LOGIN } from "../Routes/AppRoutes";
import { setToast } from "../Utils/Toast";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");

  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const capitalLetterRegex = /[A-Z]/;
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const minLength = 6;

    if (password.length < minLength) {
      return "Password must be at least 6 characters long.";
    }
    if (!capitalLetterRegex.test(password)) {
      return "Password must contain at least one capital letter";
    }
    if (!symbolRegex.test(password)) {
      return "Password must contain at least one symbol";
    }

    return "";
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const validationError = validatePassword(password);
    if (validationError) {
      setValidationError(validationError);
      return;
    }

    if (password !== confirm_password) {
      setPasswordMatch(false);
      return;
    }

    const apiUrl =
      process.env.REACT_APP_API_URL +
      `api/reset_password?token=${token}&email=${email}`;
    const payload = { password, confirm_password };

    try {
      setLoading(true);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      console.log("Response:", response);

      if (response.ok) {
        const data = await response.json();
        setToast("success", data.message);

        navigate(LOGIN);
      } else {
        console.error("Reset password failed");
        setToast("error", "Something went wrong");
      }
    } catch (error: any) {
      setToast("error", error.message);
      console.error("An error occurred", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row vh-100">
      <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
        <div className="signin-form-wrapper">
          <div className="text-center">
            <img
              src="/images/svg/Space_ai_logo.svg"
              className="d-inline-block align-top signin-logo mb-5"
              alt="Space AI Logo"
            />
          </div>
          <h1 className="mt-5 text-center">Reset Password</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  className="form-control form-control-lg form-control-solid"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (
                      confirm_password &&
                      e.target.value !== confirm_password
                    ) {
                      setPasswordMatch(false);
                    } else {
                      setPasswordMatch(true);
                    }
                    setValidationError("");
                  }}
                  required
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashFill /> : <EyeFill />}
                </span>
              </div>
            </Form.Group>

            <Form.Group controlId="confirmpassword">
              <Form.Label>Confirm Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  className="form-control form-control-lg form-control-solid"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirm_password}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (password && e.target.value !== password) {
                      setPasswordMatch(false);
                    } else {
                      setPasswordMatch(true);
                    }
                  }}
                  required
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-2 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeSlashFill /> : <EyeFill />}
                </span>
              </div>
            </Form.Group>

            {!passwordMatch && (
              <div className="text-danger">Passwords do not match</div>
            )}

            {validationError && (
              <div className="text-danger">{validationError}</div>
            )}

            <div className="d-flex justify-content-between">
              <button
                type="submit"
                disabled={loading}
                className="mt-3 mb-2 mb-md-0 btn addnew-btn btn-sm py-2 border-0 w-100"
              >
                {loading ? (
                  <span className="loadingspinnerbtn">
                    <Spinner
                      as="span"
                      animation="border"
                      className="m-1"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  <span className="fs-5">Done</span>
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
      <div className="col-md-6 col-12 d-none d-lg-flex justify-content-end bg-white">
        <Image
          src="/images/Console_sign_in_image.jpg"
          className="signin-image"
          alt="Placeholder Image"
        />
      </div>
    </div>
  );
};

export default ResetPassword;

import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pencil } from "react-bootstrap-icons";
import { Permissions } from "../../../Models/Permission";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { PERMISSIONS_ADD, PERMISSIONS_EDIT } from "../../../Routes/Permission";
import { fetchData } from "../../../Utils/OrionList";
import moment from "moment";
import { useOrganization } from "../../../Utils/OrganizationContext";
import CustomSkeleton from "../../../Components/CustomSkeleton";

export interface PermissionItem {
  attached: boolean;
  data: any;
  $response: any;
  isAssigned: boolean | undefined;
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
  checkboxLabel: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "Permission";
  const [data, setData] = useState<PermissionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const handleRowEditButtonClick = (id: number) => {
    navigate(PERMISSIONS_EDIT.replace(":id", id.toString()));
  };

  const columns: TableColumn<PermissionItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      sortable: true,
      sortField: "slug",
    },
    {
      name: "Created at",
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY"),
    },
    {
      name: "Updated at",
      selector: (row) => moment(row.updated_at).format("DD-MMM-YYYY"),
    },
    {
      name: "Actions",
      minWidth: "10%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];
  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
    </DropdownButton>
  );

  // ==============================================

  const fetchPermissions = async (page: number | undefined) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      {},
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Permissions(),
      sortField,
      sortOrder,
    );
  };

  const handlePageChange = (page: number | undefined) => {
    fetchPermissions(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      {},
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Permissions(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  // ===========================================

  return (
    <>
      <Container fluid>
        <Row className="mt-4">
          <Col>
            <h2>{title} List</h2>
          </Col>
          <Col className="text-md-end">
            <Link to={`${PERMISSIONS_ADD}`}>
              <button className="btn addnew-btn mb-2 btn-sm">
                Add&nbsp;{title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { ChevronDoubleRight } from "react-bootstrap-icons";
import Dashboard from "./Dashboard";
import { useNavigate } from "react-router-dom";
import { getUser } from "../Utils/Auth";
import { Cookies } from "typescript-cookie";
import { useOrganization } from "../Utils/OrganizationContext";

interface UserOrganization {
  id: string;
  name: string;
}

const SelectAccount = () => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState(false);
  let { setSelectedOrganizationId }: any = useOrganization();
  const [userOrganizations, setUserOrganizations] = useState<
    UserOrganization[]
  >([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    UserOrganization[]
  >([]);
  const [selectedAccount, setSelectedAccount] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line
  }, []);

  const getProfileData = async () => {
    try {
      if (getUser()) {
        const fetchedUser = getUser() as any | undefined;
        const userData = fetchedUser.user_organizations.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setUserOrganizations(userData);
        setFilteredOrganizations(userData);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const handleAccountChange = (id: string) => {
    setSelectedAccount(id);
    setSelectedOrganizationId(id);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const filtered = userOrganizations.filter((org) =>
      org.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredOrganizations(filtered);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(`/${selectedAccount}/dashboard`);
    Cookies.set("selectedAccount", selectedAccount.toString());
    setDashboard(true);
  };

  return (
    <>
      {dashboard ? (
        <Dashboard />
      ) : (
        <Container className="d-flex justify-content-center align-items-center min-vh-100 selectAccount">
          <div className="p-4 bg-white shadow select-account">
            <h2 className="text-center fw-bold mb-4">Accounts</h2>
            <Form className="p-3" onSubmit={handleSubmit}>
              <Form.Group controlId="formSearch" className="mb-4 d-flex">
                <Form.Control
                  type="text"
                  className="me-2"
                  placeholder="Search accounts..."
                  aria-label="Search accounts"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button
                  className="btn addnew-btn btn-sm"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </Form.Group>
              <Form.Group controlId="formSearch">
                <div className="mb-4">
                  {filteredOrganizations.map((org) => (
                    <div
                      key={org.id}
                      className="form-check mb-4 account-div"
                      style={{
                        background:
                          selectedAccount === org.id ? "#7bf9d1" : "#d6f9e7",
                      }}
                      onClick={() => handleAccountChange(org.id)}
                    >
                      <input
                        className="form-check-input border-1 ms-1 mt-2"
                        type="radio"
                        id={org.id}
                        value={org.id}
                        onChange={() => handleAccountChange(org.id)}
                        checked={selectedAccount === org.id}
                      />
                      <label
                        className="form-check-label ms-2 fw-bold text-dark"
                        htmlFor={org.id}
                      >
                        {org.name}
                      </label>
                    </div>
                  ))}
                </div>
              </Form.Group>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn addnew-btn fs-6 btn-sm">
                  Enter
                  <ChevronDoubleRight size={12} className="text-white" />
                </button>
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

export default SelectAccount;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Permissions extends Model<{
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "permissions";
  }
}

import { Container } from "react-bootstrap";
import { getCookie } from "typescript-cookie";
import { Outlet, Navigate } from "react-router-dom";

function Layout() {
  if (getCookie("authToken")) {
    return <Navigate to="/" />;
  }
  return (
    <Container fluid>
      <Outlet />
    </Container>
  );
}

export default Layout;

import React from "react";
import { CampaignIdentifierList } from "../CampaignIdentifierList";
const Campaign = () => {
  return (
    <>
      <CampaignIdentifierList />
    </>
  );
};

export default Campaign;

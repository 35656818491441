import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { getToken, getUser } from "../Utils/Auth";
import { useOrganization } from "./OrganizationContext";

interface Role {
  id: number;
  name: string;
}

const RolesContext = createContext<Role[]>([]);

export const useRoles = (): Role[] => {
  return useContext(RolesContext);
};

interface RolesProviderProps {
  children: ReactNode;
}

export const RolesProvider: React.FC<RolesProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const { selectedOrganizationId }: any = useOrganization();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRoles = getUser()?.roles;

        if (userRoles) {
          const rolePromises = userRoles.map((role: { id: any }) =>
            rolesbypermissions(role.id, selectedOrganizationId),
          );

          const permissionData = await (await Promise.all(rolePromises)).flat();

          const uniqueSet = new Set(
            permissionData.map((obj) =>
              JSON.stringify({ id: obj.id, name: obj.name }),
            ),
          );
          const uniqueArray = Array.from(uniqueSet).map((strObj) =>
            JSON.parse(strObj),
          );

          setRoles(uniqueArray);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        setRoles([]);
      }
    };

    if (selectedOrganizationId) {
      fetchData();
    }
  }, [selectedOrganizationId]);

  return (
    <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
  );
};

const rolesbypermissions = async (
  id: number,
  selectedOrganizationId: string,
) => {
  const apiUrl =
    (process.env.REACT_APP_API_URL as string) +
    `api/roles/${id}/permissions?organization_id=${selectedOrganizationId}&limit=5000`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const { data } = await response.json();
      console.log(data, "data");

      return data;
    } else {
      throw new Error("Failed to fetch permissions");
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
    return [];
  }
};

import { FormEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { Pen, Trash3 } from "react-bootstrap-icons";
import { getCookie } from "typescript-cookie";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { setToast } from "../Utils/Toast";
import { Note } from "../Models/Note";
import pluralize from "pluralize";
import moment from "moment";
import { Card, Modal } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";

interface NotesProps {
  id: any;
  model: any;
  customModel?: any;
  fixheight?: boolean;
}

const Notes: React.FC<NotesProps> = ({ id, model, customModel, fixheight }) => {
  const [noteData, setNoteData] = useState<Array<any>>([]); // Specify the type as Array<any>
  const [noteValue, setNoteValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionId, setActionId] = useState("");
  const [loginUserId, setLoginUserId] = useState("");
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNoteValue("");
    setActionId("");
  };
  useEffect(() => {
    const user: any = getCookie("user");
    setLoginUserId(JSON.parse(user).id);
    getNoteData(id);
    // eslint-disable-next-line
  }, []);

  const getNoteData = async (itemId: string) => {
    try {
      let query: any = model.$query();
      const apiUrl = `${query.httpClient.baseUrl}/${itemId}/notes?trashed=false`;
      const item: any = await query.httpClient.get(apiUrl);
      setNoteData(item.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };

  const saveNotes = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setCustomSubmitting(true);
      const noteData: any = {
        content: noteValue,
        [(customModel
          ? customModel
          : pluralize.singular(new model().$resource()) + "_id"
        ).replace(/-/g, "_")]: id,
      };

      if (actionId) {
        let item = await Note.$query().find(actionId);

        item.$attributes = {
          ...item.$attributes,
          ...noteData,
        };

        item = await item.$save();
        getNoteData(id);

        setActionId("");
        setNoteValue("");
        setToast("success", `Note Successfully Updated`);
      } else {
        await Note.$query().store(noteData);
        getNoteData(id);
        setNoteValue("");
        setToast("success", `Note Successfully Added`);
      }
      setShowModal(false); // Close the modal after saving
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handleDeleteNote = (index: any) => {
    setActionId(index);
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setCustomSubmitting(true);
      await Note.$query().destroy(actionId);
      getNoteData(id);
      setNoteValue("");
      setActionId("");
      setShowConfirmation(false);
      setToast("success", `Note Successfully Deleted`);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handleEditNoteButtonClick = () => {
    setShowModal(true);
  };

  return (
    <div style={{ width: "100%", marginBottom: "10px" }}>
      <div className="overflow-hidden">
        {loading ? (
          <p>loading....</p>
        ) : (
          <Card className={fixheight ? "min-height-card-visitor" : ""}>
            <Card.Header className="d-flex justify-content-between">
              <span>Notes</span>
              <button className="btn edit-btn btn-sm" onClick={handleShowModal}>
                Add Note <PencilSquare className="mx-1" size={15} />
              </button>
            </Card.Header>
            <Card.Body>
              {!noteData || noteData.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    marginTop: "20px",
                  }}
                >
                  No notes available
                </div>
              ) : (
                <div>
                  {noteData?.map((res: any, index: any) => {
                    return (
                      <Timeline
                        key={index}
                        lineStyle={{
                          top: "21px",
                          borderLeft: "1px dashed #DBDFE9",
                        }}
                        lineColor="transparent"
                        style={{
                          height: "auto",
                          padding: "0",
                          marginBottom: "25px",
                        }}
                      >
                        <TimelineEvent
                          title=""
                          icon={
                            <FontAwesomeIcon
                              icon={faComment}
                              style={{
                                fontSize: "20px",
                                marginLeft: "7px",
                                color: "#DBDFE9",
                              }}
                            />
                          }
                          contentStyle={{
                            marginTop: "0.2rem",
                            padding: "0.7em 1em",
                            borderRadius: "3px",
                            height: "auto",
                            overflowY: "auto",
                            fontFamily: "var(--bs-font-sans-serif);",
                            color: "rgb(35 35 35)",
                            fontWeight: "400",
                            boxShadow: "0",
                            background: "transparent",
                            border: "1px dashed #DBDFE9",
                          }}
                          iconColor="#CED1DB"
                          bubbleStyle={{
                            height: "40px",
                            width: "40px",
                            margin: "0 0 0 -4px",
                            boxShadow: "0",
                            backgroundColor: "#ffffff",
                            border: "1px dashed #DBDFE9",
                          }}
                          buttons={
                            res.creator?.id === loginUserId && (
                              <span
                                style={{
                                  position: "absolute",
                                  bottom: "12px",
                                  right: "35px",
                                }}
                              >
                                <button
                                  id={index}
                                  className="border-0 bg-transparent mx-2"
                                  onClick={() => {
                                    setNoteValue(res.content);
                                    setActionId(res.id);
                                    handleEditNoteButtonClick();
                                  }}
                                >
                                  <Pen style={{ fill: "blue" }} size={17}></Pen>
                                </button>
                                <button
                                  className="border-0 bg-transparent"
                                  onClick={() => {
                                    handleDeleteNote(res.id);
                                  }}
                                >
                                  <Trash3
                                    style={{ fill: "red" }}
                                    size={17}
                                  ></Trash3>
                                </button>
                              </span>
                            )
                          }
                        >
                          <CustomContent content={res.content} />
                        </TimelineEvent>
                        <div
                          className="date-info"
                          style={{ marginBottom: "15px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              bottom: "3px",
                              left: "50px",
                              color: "#747678",
                              width: "100%",
                              boxSizing: "border-box",
                            }}
                          >
                            {`Created: ${moment(res.created_at).format(
                              "DD-MMM-YYYY",
                            )}`}
                            {res.updated_at &&
                              `  •   Updated: ${moment(res.updated_at).format(
                                "DD-MMM-YYYY",
                              )}`}
                            {"  •   "}
                            <span
                              className="text-primary"
                              style={{ fontWeight: "500" }}
                            >
                              {res.creator.name}
                            </span>
                          </span>
                        </div>
                      </Timeline>
                    );
                  })}
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </div>
      <Modal centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
          <Modal.Title>{actionId ? "Edit Note" : "Add Note"}</Modal.Title>
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon mb-4"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <form
            className="d-flex flex-column align-items-end"
            onSubmit={(event) => {
              saveNotes(event);
            }}
          >
            <textarea
              id="note"
              placeholder="Enter Your Note"
              className="form-control form-control-lg form-control-solid border-0"
              value={noteValue}
              onChange={(e) => {
                setNoteValue(e.target.value);
              }}
              style={{
                width: "100%",
                height: "135px",
                padding: "5px 5px 10px 10px",
                resize: "none",
                borderColor: "#B3B3B3",
                borderRadius: "4px",
              }}
              required
            ></textarea>
            <span style={{ margin: "15px 0" }}>
              <button
                type="submit"
                className="btn addnew-btn btn-sm"
                disabled={customSubmitting}
              >
                {actionId ? "Update" : "Save"}
              </button>
            </span>
          </form>
        </Modal.Body>
      </Modal>
      <Modal centered show={showConfirmation} onHide={handleCancelDelete}>
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={handleCancelDelete}
          />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>Are you sure you want to delete?</h3>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-1 d-flex justify-content-center">
          <button
            className="btn delete-btn text-white btn-sm"
            onClick={handleConfirmDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
function CustomContent({ content }: any) {
  const [expanded, setExpanded] = useState(false);

  const truncatedContent =
    content.length > 150 ? content.slice(0, 150) + "..." : content;

  return (
    <div className="fs-4">
      <span>{expanded ? content : truncatedContent}</span>
      {content.length > 150 && (
        <span
          style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? " (less)" : " (more)"}
        </span>
      )}
    </div>
  );
}

export default Notes;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class TaxTypes extends Model<{
  name: string;
  role_id: number;
}> {
  public $resource(): string {
    return "tax_types";
  }
}

import { useEffect, useState } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import SignIn from "../Pages/Auth/SignIn";
import Dashboard from "../Pages/Dashboard";
import industriesTypesRoutes from "./industriesTypesRoutes";
import ProductTypesRoutes from "./ProductTypesRoutes";
import Layout from "../Components/Layout";
import AuthLayout from "../Components/AuthLayout";
import organizationRoutes from "./organizationRoutes";
import taxTypesRoutes from "./taxTypesRoutes";
import visitorRoutes from "./visitorRoutes";
import NotFound from "../Components/NotFound";
import Signout from "../Pages/Auth/SignOut";
import Role from "./Role";
import Permission from "./Permission";
import ProductsRoutes from "./ProductsRoutes";
import EditProfileRoutes from "./EditProfileRoutes";
import countryRoutes from "./countryRoutes";
import notificationTemplateRoutes from "./notificationTemplateRoutes";
import TileCoveragesRoutes from "./TileCoveragesRoutes";
import ForgotPassword from "../Pages/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword";
import { useLocation } from "react-router-dom";
import NotificationsRouter from "./NotificationsRouters";
import { RolesProvider } from "../Utils/RolesContext";
import realTimeDashboardRoutes from "./RealTimeDashboard";
import SelectAccount from "../Pages/SelectAccount";
import Dashboardroute from "./DashboardRoute";
import { useOrganization } from "../Utils/OrganizationContext";
import { getUserRolesFromCookie } from "../Utils/GetRoles";
import ProductColorRoutes from "./ProductColorRoutes";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const FORGOTPASSWORD = "/ForgotPassword";

export default function AppRoutes() {
  const userRole = getUserRolesFromCookie();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  let { selectedOrganizationId }: any = useOrganization();

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line
  }, [location.pathname]);

  const getProfileData = async () => {
    try {
      if (userRole) {
        if (userRole.some((item: any) => item === "ROLE_SUPER_ADMIN")) {
          setIsSuperAdmin(true);
          setIsAdmin(false);
        } else {
          if (userRole.some((item: any) => item === "ROLE_ADMIN")) {
            setIsAdmin(true);
            setIsSuperAdmin(false);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  let commonRoute: RouteObject[] = [
    { index: true, element: <Dashboard /> },
    ...Dashboardroute,
    ...industriesTypesRoutes,
    ...organizationRoutes,
    ...taxTypesRoutes,
    ...ProductTypesRoutes,
    ...visitorRoutes,
    ...ProductsRoutes,
    ...EditProfileRoutes,
    ...notificationTemplateRoutes,
    ...TileCoveragesRoutes,
    ...NotificationsRouter,
    ...realTimeDashboardRoutes,
    ...ProductColorRoutes,
  ];

  const getDashboardPath = () => {
    if (isSuperAdmin) {
      return "/dashboard";
    } else if (isAdmin) {
      return `/selectaccount`;
    }
    return "/login";
  };

  let adminRoutes: RouteObject[] = [...Role, ...Permission, ...countryRoutes];
  const routes =
    isAdmin || isSuperAdmin ? [...commonRoute, ...adminRoutes] : commonRoute;

  const dashboardRoute: RouteObject[] = [
    {
      path: isAdmin ? `/${selectedOrganizationId}` : "/",
      element: (
        <RolesProvider>
          <Layout />
        </RolesProvider>
      ),
      children: routes,
    },
  ];

  let commonRoutes: RouteObject[] = [
    ...dashboardRoute,
    {
      path: LOGIN,
      element: <AuthLayout />,
      children: [
        { index: true, element: <SignIn /> },
        { element: <Signout /> },
      ],
    },
    {
      path: FORGOTPASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: "/api/reset_password",
      element: <AuthLayout />,
      children: [{ index: true, element: <ResetPassword /> }],
    },
    {
      path: LOGOUT,
      element: <Signout />,
    },
    {
      path: "/selectaccount",
      element: <SelectAccount />,
    },
    {
      path: "*",
      element: (
        <NotFound
          errorCode={404}
          errorMessage={"Oops! The Page can't be found"}
          buttonName={"Go To Homepage"}
          redirect={getDashboardPath()}
        />
      ),
    },
  ];

  let element = useRoutes(commonRoutes);

  return <div>{element}</div>;
}

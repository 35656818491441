import React, { useEffect, useState } from "react";
import "jsvectormap/dist/css/jsvectormap.css";
import { Chart } from "chart.js/auto";
import "jsvectormap/dist/maps/world.js";
import { useNavigate } from "react-router-dom";
import useAxiosInstance from "../../Utils/Headers";

const RealTimeDashboard = () => {
  const navigate = useNavigate();
  const [intervalDuration, setIntervalDuration] = useState(15000); // default to 15 seconds
  const [realTimeData, setRealTimeData] = useState({
    real_time_user: "",
    real_time_visitor: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    fetchRealtimeVisitorData();
    const interval = setInterval(fetchRealtimeVisitorData, intervalDuration);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [intervalDuration]);

  const handleIntervalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    setIntervalDuration(value);
  };

  // ----------------- realtime call---------------
  const fetchRealtimeVisitorData = async () => {
    try {
      const response = await axiosInstance.get(
        `${apiUrl}api/real_time_analytics`,
      );

      const data = response.data;
      setRealTimeData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ----------------------------------------------

  // --------------------Total visitors--------------------
  useEffect(() => {
    const salesChart = new Chart("saleschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [19, 10, 15, 8, 6, 10, 13],
            backgroundColor: [
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderColor: [
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
            ticks: { font: { size: 2 } },
          },
          y: { display: false, ticks: { display: false } },
        },
        elements: { point: { radius: 0 } },
      },
    });

    return () => {
      salesChart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------Profit chart----------------------
  useEffect(() => {
    const profitchart = new Chart("profitchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              45, 23, 32, 67, 49, 72, 52, 55, 46, 54, 32, 74, 88, 36, 36, 32,
              48, 54,
            ],
            backgroundColor: "transparent",
            borderColor: "#f46ef4",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            borderWidth: 2, // Adjust the border width as needed
          },
        ],
      },

      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {},
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: true,
              color: "transparent",
            },
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
            borderColor: "red",
          },
        },
      },
    });

    return () => {
      profitchart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const aiimageschart = new Chart("aiimageschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [10, 12, 5, 6, 3, 11, 12],
            backgroundColor: "#4ecc48",
            borderColor: "#4ecc48",
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      aiimageschart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const costchart = new Chart("costchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
          "Date 16",
          "Date 17",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              28, 56, 36, 32, 48, 54, 37, 58, 66, 53, 21, 24, 14, 45, 0, 32, 67,
              49, 52, 55, 46, 54, 130,
            ],
            backgroundColor: "transparent",
            borderColor: "#f7ba48",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      costchart.destroy();
    };
  }, []);
  // ------------------------------------------------------

  return (
    <div>
      <div className="mb-2 d-flex justify-content-between">
        <div className="mt-1">
          <h2 className="mt-4">Real Time Dashboard</h2>
        </div>
        <div className="mt-1 d-flex">
          <div className="mt-4 d-flex refresh">
            <label
              htmlFor="intervalSelect"
              className="form-label fs-6 lable-interval"
            >
              Refresh Interval:
            </label>
            <select
              id="intervalSelect"
              className="form-select select-interval"
              value={intervalDuration}
              onChange={handleIntervalChange}
            >
              <option value={15000}>15 seconds</option>
              <option value={30000}>30 seconds</option>
              <option value={45000}>45 seconds</option>
            </select>
          </div>
          <button
            className="btn edit-btn mt-4 btn-sm"
            onClick={() => navigate(-1)}
          >
            Dashboard
          </button>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Real Time Users</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {realTimeData?.real_time_user}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="saleschart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Real Time Visitors</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {realTimeData?.real_time_visitor}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="profitchart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Comming Soon</h6>
                    <h2 className="mb-0 text-dark fw-semibold">0</h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="aiimageschart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Comming Soon</h6>
                    <h2 className="mb-0 text-dark fw-semibold">0</h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="costchart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeDashboard;

import React from "react";
import ActivityLogs from "../../../Components/ActivityLogs";
import { Card } from "react-bootstrap";

interface ActivityLogsProps {
  id: number;
}

const ActivityLog: React.FC<ActivityLogsProps> = ({ id }) => {
  return (
    <>
      <Card className="min-height-card-visitor">
        <Card.Header>Activity Logs</Card.Header>
        <Card.Body>
          <ActivityLogs auditable_type={"VISITOR"} id={id} />
        </Card.Body>
      </Card>
    </>
  );
};

export default ActivityLog;

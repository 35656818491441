import DataTable, { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { TaxType } from "../../Models/TaxType";
import { Link, useNavigate } from "react-router-dom";
import { Pencil, Trash3 } from "react-bootstrap-icons";
import { fetchData } from "../../Utils/OrionList";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import CustomFilter from "../../Components/CustomFilter";
import CustomDeleteModal from "../../Components/CustomDeleteModal";
import { TAX_TYPES_ADD, TAX_TYPES_EDIT } from "../../Routes/taxTypesRoutes";
import { TaxTypes } from "../../Models/TaxTypes";
import { useOrganization } from "../../Utils/OrganizationContext";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface TaxTypeItem {
  id: number;
  name: string;
}

function List() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  let title = "Tax Type";

  const [data, setData] = useState<TaxTypeItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
  ];

  const handleRowEditButtonClick = (id: number) => {
    navigate(TAX_TYPES_EDIT.replace(":id", id.toString()));
  };

  const handleRowDeleteButtonClick = (id: number) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const columns: TableColumn<TaxTypeItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "33.33%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Title",
      selector: (row) => row.name,
      maxWidth: "33.33%",
      wrap: true,
      sortable: true,
      sortField: "name",
      sortFunction: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      name: "Actions",
      minWidth: "33.33%",
      cell: (row) => renderActionsDropdown(row),
    },
  ];

  const renderActionsDropdown = (row: any) => (
    <DropdownButton
      id={`dropdown-actions-${row.id}`}
      title="Actions"
      className="outline-border"
      variant="outline-primary"
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        className="btn-font"
        onClick={() => handleRowEditButtonClick(row.id)}
      >
        <Pencil /> Edit
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="btn-font"
        onClick={() => handleRowDeleteButtonClick(row.id)}
      >
        <Trash3 className="mb-1" /> Delete
      </Dropdown.Item>
    </DropdownButton>
  );

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new TaxTypes(),
    );
  };

  // ==============================================
  const fetchTaxType = async (page: number | undefined) => {
    setLoading(true);
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new TaxType(),
        sortField,
        sortOrder,
      );
    } catch (error) {
      console.log("An unknown error occurred:", error);
    }
  };

  const handlePageChange = (page: number | undefined) => {
    fetchTaxType(page);
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId, limit, organizationId]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new TaxType(),
      sortField,
      sortOrder,
    );
  };
  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };
  // ===========================================

  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>

        <Row className="mt-4">
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={getData}
          />
          <Col className="text-md-end">
            <Link to={`${TAX_TYPES_ADD}`}>
              <button className="btn addnew-btn btn-sm mt-2 mt-md-0 mb-2">
                Add {title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
      />

      <CustomDeleteModal
        title={title}
        model={TaxType}
        getData={fetchTaxType}
        deleteItemId={deleteItemId}
        setDeleteItemId={setDeleteItemId}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
}

export default List;

import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, Link45deg } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Col, Container, Row } from "react-bootstrap";
import { Organizations } from "../../Models/Organizations";
import { fetchData } from "../../Utils/OrionList";
import {
  ORGANIZATION_ADD,
  ORGANIZATION_VIEW,
} from "../../Routes/organizationRoutes";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { getUserRolesFromCookie } from "../../Utils/GetRoles";
import CustomSkeleton from "../../Components/CustomSkeleton";

interface OrganizationItem {
  id: number;
  name: string;
  created_at: string;
  shared_url: string;
}

function List() {
  const userRole = getUserRolesFromCookie();
  let title = "Organization";
  const [data, setData] = useState<OrganizationItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState<InputValues>({});
  // eslint-disable-next-line
  const [showButton, setShowButton] = useState(false);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    if (userRole) {
      setShowButton(userRole.some((role: string) => role === "ROLE_ADMIN"));
    }
    // eslint-disable-next-line
  }, []);

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
        Like: FilterOperator.Like,
        NotLike: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Date",
      fieldType: "date",
      allowedFilter: {
        Equal: FilterOperator.Equal,
        NotEqual: FilterOperator.NotEqual,
      },
    },
  ];

  const columns: TableColumn<OrganizationItem>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      maxWidth: "10%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Organization Name",
      maxWidth: "25%",
      wrap: true,
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
      sortFunction: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      name: "Date",
      selector: (row) => moment(row.created_at).format("DD-MMM-YYYY"),
      maxWidth: "25%",
    },
    {
      name: "Actions",
      button: true,
      minWidth: "40%",
      cell: (row) => (
        <>
          <Button
            variant="orange-light"
            size="sm"
            className="me-2 btn-sm btn-font"
            onClick={() => {
              navigate(
                `${ORGANIZATION_VIEW.replace(
                  ":id",
                  row.id.toString(),
                )}?activeKey=campaign`,
              );
            }}
          >
            <Link45deg /> Campaign
          </Button>
          <Button
            variant="primary-light"
            size="sm"
            className="me-2 btn-sm btn-font"
            onClick={() => {
              navigate(ORGANIZATION_VIEW.replace(":id", row.id.toString()));
            }}
          >
            <Eye /> View
          </Button>
        </>
      ),
    },
  ];

  const getData = async (page: number = 1) => {
    fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Organizations(),
      sortField,
      sortOrder,
    );
  };

  const handlePerRowsChange = async (limit: any, page: any) => {
    setLoading(true);
    setLimit(limit);
    setLoading(false);
  };

  useEffect(() => {
    fetchDataWithSorting(1, sortField, sortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const fetchDataWithSorting = async (
    page: number | undefined,
    sortField: any,
    sortOrder: any,
  ) => {
    setLoading(true);
    await fetchData(
      page,
      limit,
      inputValues,
      setLoading,
      setData,
      setTotalRows,
      navigate,
      new Organizations(),
      sortField,
      sortOrder,
    );
  };

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    fetchDataWithSorting(1, selectedColumn["sortField"], sortDirection);
    setSortField(selectedColumn["sortField"]);
    setSortOrder(sortDirection);
  };

  return (
    <>
      <Container fluid>
        <h2>{title} List</h2>
        <Row className="mt-4">
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={getData}
          />
          <Col className="text-md-end">
            <Link to={`${ORGANIZATION_ADD}`}>
              <button className="btn btn-sm addnew-btn btn-font">
                Add&nbsp;{title}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={getData}
        onSort={handleSort}
        noDataComponent={
          <>
            <img src="/images/svg/No_data_available.svg" alt="" />
          </>
        }
        progressComponent={
          <CustomSkeleton
            customInnerStyle={{ marginBottom: "9.5px" }}
            count={totalRows || 10}
            height={36}
          />
        }
      />
    </>
  );
}

export default List;

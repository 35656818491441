import React, { useEffect } from "react";
import "jsvectormap/dist/css/jsvectormap.css";
import { Chart } from "chart.js/auto";
import "jsvectormap/dist/maps/world.js";

type CountData = {
  ai_image_count: string;
  visitor_count: string;
  balance: string;
  catalog_count: string;
  product_count: string;
};

interface AnalyticsChartProps {
  countData: CountData;
}

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({ countData }) => {
  useEffect(() => {
    const salesChart = new Chart("saleschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [19, 10, 15, 8, 6, 10, 13],
            backgroundColor: [
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderColor: [
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
            ticks: { font: { size: 2 } },
          },
          y: { display: false, ticks: { display: false } },
        },
        elements: { point: { radius: 0 } },
      },
    });

    return () => {
      salesChart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------Profit chart----------------------
  useEffect(() => {
    const profitchart = new Chart("profitchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              45, 23, 32, 67, 49, 72, 52, 55, 46, 54, 32, 74, 88, 36, 36, 32,
              48, 54,
            ],
            backgroundColor: "transparent",
            borderColor: "#f46ef4",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            borderWidth: 2, // Adjust the border width as needed
          },
        ],
      },

      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {},
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: true,
              color: "transparent",
            },
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
            borderColor: "red",
          },
        },
      },
    });

    return () => {
      profitchart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const aiimageschart = new Chart("aiimageschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [10, 12, 5, 6, 3, 11, 12],
            backgroundColor: "#4ecc48",
            borderColor: "#4ecc48",
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      aiimageschart.destroy();
    };
  }, []);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const costchart = new Chart("costchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
          "Date 16",
          "Date 17",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              28, 56, 36, 32, 48, 54, 37, 58, 66, 53, 21, 24, 14, 45, 0, 32, 67,
              49, 52, 55, 46, 54, 130,
            ],
            backgroundColor: "transparent",
            borderColor: "#f7ba48",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      costchart.destroy();
    };
  }, []);
  // ------------------------------------------------------

  return (
    <div>
      <div>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="rounded overflow-hidden">
              <div className="card-body border border-dashed border-2">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Visitors</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {countData.visitor_count}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="saleschart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="rounded overflow-hidden">
              <div className="card-body border border-dashed border-2">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Ai Images</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {countData.ai_image_count}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="profitchart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="rounded overflow-hidden">
              <div className="card-body border border-dashed border-2">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Catalog</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {countData.catalog_count}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="aiimageschart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="rounded overflow-hidden">
              <div className="card-body border border-dashed border-2">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Products</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {countData.product_count}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="costchart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsChart;

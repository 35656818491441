import { useEffect } from "react";
import AppRoutes from "./Routes/AppRoutes";
// import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { clarity } from "clarity-js";
import { Helmet } from "react-helmet";

import "./Style/global.css";
import "./Style/icons.css";
// import "./Style/style.bundle.css";
import "./Style/metronic/MetronicGloble.css";
import "./Style/styles.css";
import "./Style/App.scss";

import { OrganizationProvider } from "./Utils/OrganizationContext";
import { NotificationProvider } from "./Utils/NotificationContext";
import { useLocation } from "react-router-dom";
import { UserProvider } from "./Utils/UserContext";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.CLARITY_ID && process.env.GA_TRACKING_ID) {
      clarity.setup({
        projectId: process.env.CLARITY_ID as string,
      });
      clarity.initialize(process.env.GA_TRACKING_ID as string);
    }
  }, []);

  return (
    <OrganizationProvider>
      <NotificationProvider>
        <UserProvider>
          <Helmet>
            <title>Space.ai</title>
            <meta
              name="description"
              content="This is the description for my page."
            />
          </Helmet>
          <div
            className={
              location.pathname !== "/login" &&
              location.pathname !== "/api/reset_password" &&
              location.pathname !== "/ForgotPassword"
                ? "mb-5"
                : ""
            }
          >
            <AppRoutes />
            <ToastContainer />
          </div>
        </UserProvider>
      </NotificationProvider>
    </OrganizationProvider>
  );
}

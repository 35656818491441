import { Model } from "@tailflow/laravel-orion/lib/model";

export class IndustriesType extends Model<{
  title: string;
  description: string;
}> {
  public $resource(): string {
    return "industries_type";
  }
}

import { RouteObject } from "react-router-dom";
import List from "../Pages/ProductTypes/List";
import AddUpdate from "../Pages/ProductTypes/AddUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_PRODUCT_TYPE,
  UPDATE_PRODUCT_TYPE,
  VIEW_PRODUCT_TYPE,
} from "../Utils/PermissionsEnum";

export const PRODUCT_TYPE_LIST = "product-types";
export const PRODUCT_TYPE_ADD = "add";
export const PRODUCT_TYPE_EDIT = ":id/edit";

const ProductTypesRoutes: RouteObject[] = [
  {
    path: PRODUCT_TYPE_LIST,
    element: (
      <AuthorizedRoute element={<List />} permission={VIEW_PRODUCT_TYPE} />
    ),
  },
  {
    path: `${PRODUCT_TYPE_LIST}/${PRODUCT_TYPE_ADD}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={ADD_PRODUCT_TYPE} />
    ),
  },
  {
    path: `${PRODUCT_TYPE_LIST}/${PRODUCT_TYPE_EDIT}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_PRODUCT_TYPE}
      />
    ),
  },
];

export default ProductTypesRoutes;

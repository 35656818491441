import React, { useEffect } from "react";
import { removeToken } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../Routes/AppRoutes";

function Signout() {
  const navigate = useNavigate();
  useEffect(() => {
    removeToken();

    navigate(LOGIN);
  });

  return (
    <div className="">
      <h2>Sign Out</h2>
      <p>You have been signed out successfully.</p>
    </div>
  );
}

export default Signout;

import { Container, Row, Col } from "react-bootstrap";
import { getCookie } from "typescript-cookie";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Orion } from "@tailflow/laravel-orion/lib/orion";
import NavBarLayout from "./NavBarLayout";
import { LOGIN, LOGOUT } from "../Routes/AppRoutes";
import axios, { AxiosInstance, CancelTokenSource, isCancel } from "axios";
import { useOrganization } from "../Utils/OrganizationContext";

function Layout() {
  const navigate = useNavigate();
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const token = getCookie("authToken");

  if (!token) {
    return <Navigate to={LOGIN} />;
  }
  let cancelToken: CancelTokenSource | undefined;
  let previousUrl: string | undefined;

  const createAxiosInstance: any = (): AxiosInstance => {
    const instance = axios.create();
    instance.interceptors.request.use(
      (config) => {
        const authToken = getCookie("authToken");
        if (authToken) {
          config.headers["Authorization"] = `Bearer ${authToken.replace(
            /"/g,
            "",
          )}`;
        }
        if (selectedOrganizationId ? selectedOrganizationId : organizationId) {
          config.headers["ORGANIZATION-ID"] = selectedOrganizationId
            ? selectedOrganizationId
            : organizationId;
        }
        if (config.url === "/search" && selectedOrganizationId) {
          config.url = `${config.url}?organization_id=${selectedOrganizationId}`;
        }

        if (cancelToken && config.url === previousUrl) {
          cancelToken.cancel("Request canceled due to new request");
        }

        cancelToken = axios.CancelToken.source();
        config.cancelToken = cancelToken.token;
        previousUrl = config.url;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (isCancel(error)) {
        } else if (error.response && error.response.status === 401) {
          navigate(LOGOUT);
        }
        return Promise.reject(error);
      },
    );

    return instance;
  };

  Orion.makeHttpClientUsing(createAxiosInstance);
  Orion.init(apiUrl);

  return (
    <>
      <NavBarLayout />
      <Container fluid>
        <Row className="mt-4 ">
          <Col md={12}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Layout;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Products extends Model<{
  title: string;
  description: string;
  width: string;
  height: string;
  metadata: string;
  is_for_wall: boolean;
  is_for_floor: boolean;
  product_type_id: string;
  image_url: string;
  image_url_mid: string;
  image_url_small: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  product_name: string;
  product_name_slug: string;
}> {
  static findById(id: string) {
    throw new Error("Method not implemented.");
  }
  public $resource(): string {
    return "products";
  }
}

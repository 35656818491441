import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../../Utils/Toast";
import { PermissionItem } from "../Permission/List";
import { useOrganization } from "../../../Utils/OrganizationContext";
import useAxiosInstance from "../../../Utils/Headers";
import { Card } from "react-bootstrap";

const AssignRolePermission = () => {
  let { selectedOrganizationId }: any = useOrganization();
  const [permissions, setPermissions] = useState<PermissionItem[]>([]);

  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
  // eslint-disable-next-line
  const [totalRows, setTotalRows] = useState(0);
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();

  const groupPermissions = (permissions: PermissionItem[]) => {
    return permissions.reduce(
      (acc, permission) => {
        const [checkboxLabel, ...rest] = permission.name.split("_");
        const heading = rest.join("_").replace(/_/g, " ");
        if (!acc[heading]) {
          acc[heading] = [];
        }
        acc[heading].push({ ...permission, checkboxLabel, id: permission.id });
        return acc;
      },
      {} as Record<string, PermissionItem[]>,
    );
  };

  const handlePermissionToggle = (permissionId: number) => {
    setSelectedPermissions((prevSelected) => {
      if (prevSelected.includes(permissionId)) {
        return prevSelected.filter((id) => id !== permissionId);
      } else {
        return [...prevSelected, permissionId];
      }
    });
  };

  const rolesbypermissions = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/roles/${id}/permissions?limit=500`;

    try {
      const response = await axiosInstance.get(apiUrl);

      const data = response.data;

      setSelectedPermissions((prevSelected) => [
        ...prevSelected,
        ...data.data.map((e: any) => e.id),
      ]);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const fetchPermissions = async (page: number | undefined) => {
    setLoading(true);
    let apiUrl = `${process.env.REACT_APP_API_URL}api/permissions?limit=50000`;

    if (selectedOrganizationId) {
      apiUrl += `&organization_id=${selectedOrganizationId}`;
    }

    try {
      const response = await axiosInstance.get(apiUrl);

      const data = response.data;
      setPermissions(data.data);
      setTotalRows(data.meta.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    rolesbypermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPermissions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const apiUrl =
      (process.env.REACT_APP_API_URL as string) +
      `api/roles/${id}/permissions/sync`;

    if (selectedPermissions.length === 0) {
      return;
    }

    try {
      const response = await axiosInstance.patch(apiUrl, {
        resources: Array.from(new Set(selectedPermissions)),
      });

      if (response.status === 200) {
        setToast("success", "Permissions attached successfully!");
        navigate("/roles/");
      } else {
        throw new Error("Failed to attach permissions");
      }
    } catch (error) {
      console.error("Error:", error);
      setToast("error", "Something went wrong");
    }
  };

  const handleSelectAllToggle = (heading: string) => {
    setSelectAllPermissions((prevSelectAllPermissions) => ({
      ...prevSelectAllPermissions,
      [heading]: !prevSelectAllPermissions[heading],
    }));

    const allPermissionIds = groupPermissions(permissions)[heading].map(
      (item: any) => item.id,
    );

    setSelectedPermissions((prevSelected) =>
      selectAllPermissions[heading]
        ? prevSelected.filter((id) => !allPermissionIds.includes(id))
        : [...prevSelected, ...allPermissionIds],
    );
  };

  const [selectAllPermissions, setSelectAllPermissions] = useState<{
    all: boolean;
    [key: string]: boolean;
  }>({ all: false });

  const handleSelectAllCheckboxToggle = () => {
    setSelectAllPermissions((prevSelectAllPermissions) => ({
      ...prevSelectAllPermissions,
      all: !prevSelectAllPermissions.all,
    }));

    setSelectedPermissions((prevSelected) =>
      selectAllPermissions.all ? [] : getAllPermissionIds(),
    );
  };
  const getAllPermissionIds = () => {
    const allPermissionIds: number[] = [];
    Object.values(groupPermissions(permissions)).forEach((permissions) => {
      permissions.forEach((item: any) => {
        allPermissionIds.push(item.id);
      });
    });
    return Array.from(new Set(allPermissionIds));
  };

  return (
    <>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="custom-checkbox mb-4 mx-5">
            <input
              type="checkbox"
              id="selectAllCheckbox"
              checked={selectAllPermissions.all}
              onChange={handleSelectAllCheckboxToggle}
            />
            <label htmlFor="selectAllCheckbox" className="fw-bolder">
              Select All Permissions
            </label>
          </div>
          {Object.entries(groupPermissions(permissions)).map(
            ([heading, permissions]: [string, PermissionItem[]]) => (
              <Card key={heading}>
                <Card.Header className="d-flex justify-content-between align-items-center py-0">
                  <span className="text-capitalize fs-4">
                    {heading.toLowerCase()}
                  </span>
                  <div className="custom-checkbox fs-4">
                    <input
                      type="checkbox"
                      id={`selectall-${heading}`}
                      checked={selectAllPermissions[heading]}
                      onChange={() => handleSelectAllToggle(heading)}
                    />

                    <label
                      htmlFor={`selectall-${heading}`}
                      className="fw-bolder select-all"
                    >
                      Select All
                    </label>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex flex-wrap justify-content-start">
                    {permissions.map((item: any) => (
                      <div
                        key={item.id}
                        className="d-inline-block col-2 mb-3 assign-role custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          id={`permission-${item.id}`}
                          checked={selectedPermissions.includes(item.id)}
                          onChange={() => handlePermissionToggle(item.id)}
                        />
                        <label
                          className="fw-bolder form-check-label ms-1 text-capitalize"
                          htmlFor={`permission-${item.id}`}
                        >
                          {item.checkboxLabel.toLowerCase()}
                        </label>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            ),
          )}

          <div className="my-3">
            <button type="submit" className="btn addnew-btn btn-sm">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AssignRolePermission;

import { RouteObject } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import { PRODUCT_VIEW } from "./ProductsRoutes";
import AuthorizedRoute from "./AuthorizedRoute";
import View from "../Pages/Products/view";
import { VIEW_PRODUCT } from "../Utils/PermissionsEnum";

export const DASHBOARD_ROUTE = "dashboard";

const dashboardroute: RouteObject[] = [
  { path: DASHBOARD_ROUTE, element: <Dashboard /> },
  {
    path: `${DASHBOARD_ROUTE}/${PRODUCT_VIEW}`,
    element: <AuthorizedRoute element={<View />} permission={VIEW_PRODUCT} />,
  },
];

export default dashboardroute;

import "bootstrap/dist/css/bootstrap.min.css";
import { Role } from "../../../Models/Role";
import { useParams } from "react-router-dom";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { Card } from "react-bootstrap";
import ActivityLogs from "../../../Components/ActivityLogs";

interface AddUpdateProps {
  title?: any;
}

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  description?: string;
  placeholder?: string;
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
}

const AddUpdate: React.FC<AddUpdateProps> = () => {
  const { id } = useParams();

  let title = "Role";

  const formFields: Field[] = [
    {
      id: "name",
      title: "Name*",
      type: "string",
      placeholder: "Enter name",
      col: 12,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Title is required",
        },
      ],
      required: true,
    },
  ];

  return (
    <>
      <div>
        <AddUpdateForm formFields={formFields} model={Role} title={title} />
      </div>
      {id && (
        <Card className="border-0 activity-card">
          <Card.Header className="mb-4">Activity Logs</Card.Header>
          <ActivityLogs auditable_type={"ROLE"} id={id} />
        </Card>
      )}
    </>
  );
};

export default AddUpdate;

import React, { ReactNode, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../Components/NotFound";
import { getToken } from "../Utils/Auth";
import axios from "axios";
import { useOrganization } from "../Utils/OrganizationContext";

const AuthorizedRoute = ({
  element,
  permission,
}: {
  element: ReactNode;
  permission: string;
}) => {
  let { organizationId }: any = useOrganization();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [delayPassed, setDelayPassed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      const delayTimer = setTimeout(() => {
        setDelayPassed(true);
      }, 1000);

      return () => clearTimeout(delayTimer);
    }
  }, [loading]);
  const token = getToken();
  const getProfileData = async () => {
    try {
      if (token && organizationId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/profile?organization_id=${organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        );

        const roles = response.data.data.roles;
        roles.forEach((role: any) => {
          const permissions = role.permissions;

          permissions.forEach((permission: any) => {
            setPermissions((prevPermissions) => [
              ...prevPermissions,
              permission.name,
            ]);
          });
        });
        if (roles.some((item: any) => item.name === "ROLE_SUPER_ADMIN")) {
          setIsSuperAdmin(true);
          setIsAdmin(false);
        } else {
          if (roles.some((item: any) => item.name === "ROLE_ADMIN")) {
            setIsAdmin(true);
            setIsSuperAdmin(false);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching profile data:", error);
      setLoading(false);
    }
  };
  const getDashboardPath = () => {
    if (isSuperAdmin) {
      return "/dashboard";
    } else if (isAdmin) {
      return `/:id/dashboard`;
    }
    return "/";
  };

  const isPermission = permissions.includes(permission);

  if (loading) {
    return <div>Loading...</div>;
  } else if (isPermission) {
    return <>{element}</>;
  } else if (delayPassed) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <NotFound
              errorCode={403}
              errorMessage={"Oops! The Page can't be found"}
              buttonName={"Go To Homepage"}
              redirect={getDashboardPath()}
            />
          }
        />
      </Routes>
    );
  } else {
    return null;
  }
};

export default AuthorizedRoute;

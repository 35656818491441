import { ProductType } from "../../Models/ProductType";
import { useParams } from "react-router-dom";
import AddUpdateForm from "../../Components/AddUpdateForm";
import ActivityLogs from "../../Components/ActivityLogs";
import { Card } from "react-bootstrap";

interface AddUpdateProps {
  title?: any;
}

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  placeholder?: string;
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
}

const AddUpdate: React.FC<AddUpdateProps> = () => {
  const { id } = useParams();

  let title = "Product Type";

  const formFields: Field[] = [
    {
      id: "title",
      title: "Title",
      type: "text",
      col: 12,
      placeholder: "Enter the title",
      validate: [
        {
          rule: (value: any) => value.length > 0,
          message: "Title is required",
        },
      ],
      required: true,
    },
  ];

  return (
    <>
      <div>
        <AddUpdateForm
          formFields={formFields}
          model={ProductType}
          title={title}
        />
      </div>
      {id && (
        <Card className="border-0 activity-card">
          <Card.Header className="mb-4">Activity Logs</Card.Header>
          <ActivityLogs auditable_type={"PRODUCT_TYPE"} id={id} />
        </Card>
      )}
    </>
  );
};

export default AddUpdate;

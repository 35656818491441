import { RouteObject } from "react-router-dom";
import List from "../Pages/IndustriesTypes/List";
import AddUpdate from "../Pages/IndustriesTypes/AddUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_INDUSTRIES_TYPE,
  UPDATE_INDUSTRIES_TYPE,
  VIEW_INDUSTRIES_TYPE,
} from "../Utils/PermissionsEnum";

export const INDUSTRIES_TYPES_LIST = "industries-types";
export const INDUSTRIES_TYPES_ADD = "add";
export const INDUSTRIES_TYPES_EDIT = ":id/edit";

const industriesTypesRoutes: RouteObject[] = [
  {
    path: INDUSTRIES_TYPES_LIST,
    element: (
      <AuthorizedRoute element={<List />} permission={VIEW_INDUSTRIES_TYPE} />
    ),
  },
  {
    path: `${INDUSTRIES_TYPES_LIST}/${INDUSTRIES_TYPES_ADD}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_INDUSTRIES_TYPE}
      />
    ),
  },
  {
    path: `${INDUSTRIES_TYPES_LIST}/${INDUSTRIES_TYPES_EDIT}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_INDUSTRIES_TYPE}
      />
    ),
  },
];

export default industriesTypesRoutes;

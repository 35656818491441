import { useState, useEffect } from "react";
import { Container, Card, Nav } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import { useNotification } from "../../Utils/NotificationContext";
import useAxiosInstance from "../../Utils/Headers";
import { useLocation, useNavigate } from "react-router-dom";
import AllNotifications from "./AllNotifications";
import UnreadNotifications from "./UnreadNotifications";

interface Notification {
  id: number;
  compareStatus: boolean;
  data: {
    message: string;
    email: string;
    mobile_number: number;
    visitorId: number;
  };
  created_at: string;
  read_at: Date | null;
}

function Notifications() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  const { updateUnreadNotifications }: any = useNotification();
  const title = "Notification";
  const [showNotification, setShowNotification] = useState<Notification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState<
    Notification[]
  >([]);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);
  const axiosInstance = useAxiosInstance();
  const location = useLocation();
  const activeKeyParam = new URLSearchParams(location.search).get("activeKey");
  const activeKey = activeKeyParam || "all";
  const navigate = useNavigate();
  const handleNavigate = (key: any) => {
    const newUrl = `${location.pathname}?activeKey=${key}`;
    navigate(newUrl);
  };

  const getNotificationData = async (page = 1) => {
    try {
      const response = await axiosInstance.post(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/search?organization_id=${
          selectedOrganizationId || organizationId
        }&page=${page}`,
        {
          sort: [{ field: "created_at", direction: "desc" }],
        },
      );

      const notifications = response.data.data.map(
        (notification: Notification) => {
          return {
            ...notification,
          };
        },
      );

      setShowNotification((prevNotifications) => [
        ...prevNotifications,
        ...notifications,
      ]);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  useEffect(() => {
    getNotificationData();
    setShowUnreadNotifications(!showUnreadNotifications);
    if (!showUnreadNotifications) {
      unreadNotification();
    }
    // eslint-disable-next-line
  }, []);

  const markAllNotificationsAsRead = async () => {
    const unread = showNotification.filter(
      (notification) => notification.read_at === null,
    );
    try {
      if (unread.length > 0) {
        await axiosInstance.get(
          `${
            process.env.REACT_APP_API_URL
          }api/notifications_read?organization_id=${
            selectedOrganizationId || organizationId
          }`,
        );
      }
      getNotificationData();
      updateUnreadNotifications(); // Trigger update
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const unreadNotification = async () => {
    try {
      const response = await axiosInstance.post(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/search?organization_id=${
          selectedOrganizationId || organizationId
        }`,
        {
          filters: [
            {
              field: "read_at",
              operator: "=",
              value: null,
            },
          ],
        },
      );

      setUnreadNotifications(response?.data?.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const unreadCount = unreadNotifications.filter(
    (notification: any) =>
      notification.read_at === null || notification.read_at === undefined,
  ).length;

  const handleMarkAsRead = (notificationId: number) => {
    setShowNotification((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationId
          ? { ...notification, read_at: new Date() }
          : notification,
      ),
    );
    updateUnreadNotifications();
  };

  const handleDeleteNotification = (notificationId: number) => {
    setShowNotification((prevNotifications) =>
      prevNotifications.filter(
        (notification) => notification.id !== notificationId,
      ),
    );
  };

  return (
    <Container className="container mt-5">
      <Card>
        <div className=" border rounded-top">
          <div className="d-flex justify-content-between w-100 mt-2 p-2">
            <h1 className="fw-bold ms-5 mt-4">{title}</h1>
            <h4
              className="Mark-all-read me-3"
              onClick={markAllNotificationsAsRead}
            >
              Mark all as read
            </h4>
          </div>
          <Nav
            variant="tabs"
            className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap ms-4"
            activeKey={activeKey}
          >
            <Nav.Item>
              <Nav.Link
                onClick={() => handleNavigate("all")}
                eventKey="all"
                className={`nav-link text-active-primary fs-3 ms-3 me-6 ${
                  activeKey === "all" ? "active" : ""
                }`}
              >
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => handleNavigate("unread")}
                eventKey="unread"
                className={`nav-link text-active-primary fs-3 me-6 ${
                  activeKey === "unread" ? "active" : ""
                }`}
              >
                Unread ({unreadCount})
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        {activeKey === "all" && (
          <AllNotifications
            onMarkAsRead={handleMarkAsRead}
            onDelete={handleDeleteNotification}
          />
        )}
        {activeKey === "unread" && (
          <UnreadNotifications
            onMarkAsRead={handleMarkAsRead}
            onDelete={handleDeleteNotification}
          />
        )}
      </Card>
    </Container>
  );
}

export default Notifications;

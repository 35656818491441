import { Model } from "@tailflow/laravel-orion/lib/model";

export class Role extends Model<{
  name: string;
  slug: string;
}> {
  tag() {
    throw new Error("Method not implemented.");
  }
  attach(arg0: number[]) {
    throw new Error("Method not implemented.");
  }
  public $resource(): string {
    return "roles";
  }
}

import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Organization } from "../../Models/Organization";
import { Container, Card, Image, Modal, Dropdown, Nav } from "react-bootstrap";
import {
  BuildingsFill,
  EnvelopeFill,
  Images,
  Lock,
  Trash3,
  TelephoneFill,
} from "react-bootstrap-icons";
import { setToast } from "../../Utils/Toast";
import Skeleton from "react-loading-skeleton";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useOrganization } from "../../Utils/OrganizationContext";
import { Cookies } from "typescript-cookie";
import "../../Style/App.css";
import useAxiosInstance from "../../Utils/Headers";
import AssignPermissionModal from "../../Components/AssignPermissionModal";
import CustomDeleteModal from "../../Components/CustomDeleteModal";
import { getUserRolesFromCookie } from "../../Utils/GetRoles";
import AnalyticsChart from "./AnalyticsChart";
import ActivityLog from "./Components/ActivityLog";
import Campaign from "./Components/Campaign";
import NewArrivals from "./Components/NewArrivals";
import Overview from "./Components/Overview";
import Settings from "./Components/Settings";
import SubUser from "./Components/SubUser";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleImageError } from "../../Components/Noimage";
import BalanceLog from "./Components/BalanceLog";

ChartJS.register(ArcElement, Tooltip, Legend);

function View() {
  const title = "Organization Details";
  const navigate = useNavigate();
  const location = useLocation();
  const activeKeyParam = new URLSearchParams(location.search).get("activeKey");
  const activeKey = activeKeyParam || "overview";
  const userRole = getUserRolesFromCookie();
  const [data, setData] = useState({
    address: "",
    balance: "",
    country_id: "",
    city: "",
    country: {
      name: "",
      id: "",
    },
    created_at: "",
    deleted_at: "",
    id: "",
    industries_type_id: "",
    industries_type: {
      title: "",
    },
    master_user_id: "",
    name: "",
    contact_numberpostal_code: "",
    state: "",
    tax_number: "",
    tax_type_id: "",
    updated_at: "",
    uuid: "",
    organiser_number: "",
    organiser_email: "",
    total_visitors: "",
    total_ai_image_generated: "",
    logo: "",
    catalog_front_page: "",
    catalog_last_page: "",
    email: "",
    theme_fg_color: "",
    theme_bg_color: "",
    front_short_description: "",
    organization_handle: "",
    postal_code: "",
    contact_number: "",
  });
  const [displayData, setDisplayData] = useState({
    address: "",
    balance: "",
    city: "",
    country_id: "",
    country: {
      name: "",
      id: "",
    },
    created_at: "",
    deleted_at: "",
    id: "",
    industries_type_id: "",
    industries_type: {
      title: "",
    },
    name: "",
    state: "",
    total_ai_image_generated: "",
    organization_handle: "",
    logo: "",
    email: "",
    theme_fg_color: "",
    theme_bg_color: "",
    front_short_description: "",
    postal_code: "",
    contact_number: "",
  });
  let { selectedOrganizationId, setSelectedOrganizationId }: any =
    useOrganization();
  const [organizationIdforpermission, setOrganizationIdforpermission] =
    useState<any | null>(null);
  const { id }: any = useParams();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [countData, setCountData] = useState({
    ai_image_count: "",
    visitor_count: "",
    balance: "",
    product_count: "",
    catalog_count: "",
  });
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const SelectedAccount = Cookies.get("selectedAccount");
  const [showViewConfirmModal, setShowViewConfirmModal] = useState(false);
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [organizationName, setOrganizationName] = useState<any | null>(null);
  const axiosInstance = useAxiosInstance();
  const [showAssignPermissionModal, setShowAssignPermissionModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState<number | null>(null);
  const [handleModalClick, setHandleModalClick] = useState<Function>(() => {});
  const currentDate = new Date();
  const defaultStartDate = new Date();
  const fallBackSrc = "/images/svg/noimage.svg";
  defaultStartDate.setDate(currentDate.getDate() - 15);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(currentDate);

  const closeAssignPermissionModal = () => {
    setShowAssignPermissionModal(false);
  };
  const openAssignPermissionModal = (id: any) => {
    setOrganizationIdforpermission(id);
    setShowAssignPermissionModal(true);
  };

  const openConfirmModal = (id: number) => {
    setOrganizationId(id);
    setOrganizationName(data.name);
    setShowViewConfirmModal(true);
  };
  const handleViewConfirm = (confirm: boolean) => {
    if (confirm && organizationId !== null) {
      Cookies.set("OrganizationIdForView", organizationId.toString());
      Cookies.set("OrganizationNameForView", organizationName.toString());
      setSelectedOrganizationId(organizationId);
    }
    setShowViewConfirmModal(false);
  };
  const updateDisplayData = (newData: any) => {
    setDisplayData(newData);
  };

  useEffect(() => {
    FetchCountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const FetchCountData = async () => {
    const requestBody = {
      organization_id: id,
      start_date: moment(startDate).format("DD-MM-YYYY"),
      end_date: moment(endDate).format("DD-MM-YYYY"),
    };

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/all_count_by_organization`,
        requestBody,
      );

      if (response.status === 200) {
        const data = response.data;
        setCountData(data.data);
      } else {
        console.error("Error fetching profile data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    if (userRole) {
      setIsSuperAdmin(
        userRole.some((role: string) => role === "ROLE_SUPER_ADMIN"),
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const fetchOrganization = async () => {
    const organization = await Organization.$query()
      .with(["country", "industriesType"])
      .find(id);
    setData(organization?.$response?.data?.data);
    updateDisplayData(organization?.$response?.data?.data);
  };

  const handleAllProductsDelete = async () => {
    setShowDeleteModal(false);
    try {
      const response = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}api/products_bulk/` + deleteItemId,
      );
      console.log("All products deleted successfully", response.data);
      setToast("success", "All products deleted successfully");
    } catch (error) {
      console.error("Error deleting products:", error);
    }
  };

  const handleNavigate = (key: any) => {
    const newUrl = `${location.pathname}?activeKey=${key}`;
    navigate(newUrl);
  };
  return (
    <>
      <Container fluid>
        <Card className="mt-5">
          <div className="w-90">
            <div>
              <Card.Body className="p-0">
                <div className="d-flex justify-content-end mt-5">
                  {(organizationIdFromCookie || SelectedAccount) && (
                    <Dropdown className="ms-3">
                      <Dropdown.Toggle
                        className="btn addnew-btn btn-sm"
                        variant="outline-primary"
                        id="dropdown-basic"
                        size="sm"
                      >
                        Action
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="custom-dropdown-menu">
                        <Dropdown.Item
                          href="#"
                          className="dropdown-item fs-6"
                          title="Assign permission"
                          onClick={() => openAssignPermissionModal(data.id)}
                        >
                          <Lock className="icon mx-1" />
                          Fix Permission
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          className="dropdown-item fs-6"
                          onClick={(e) => {
                            setDeleteItemId(id);
                            setShowDeleteModal(true);
                            setHandleModalClick(() => null);
                          }}
                        >
                          <Trash3 className="icon" /> Delete
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          className="dropdown-item  fs-6"
                          title="Delete all products"
                          onClick={(e) => {
                            setDeleteItemId(id);
                            setShowDeleteModal(true);
                            setHandleModalClick(() => handleAllProductsDelete);
                          }}
                        >
                          <Images className="icon" /> Delete Products
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {!organizationIdFromCookie && isSuperAdmin && (
                    <button
                      type="button"
                      className="btn addnew-btn ms-3 mb-4 btn-sm"
                      onClick={() => openConfirmModal(id)}
                    >
                      Login as This Organization
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn back-btn ms-3 mb-4 btn-sm"
                    onClick={() => {
                      window.history.back();
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className="row justify-content-between mt-4">
                  <div className="col-lg-2 col-md-6 col-sm-12 col-12 mb-3">
                    <div className="logo-container">
                      {data.logo ? (
                        <Image
                          className="img-fluid"
                          src={data.logo}
                          rounded
                          onError={(e) =>
                            handleImageError(e, fallBackSrc, "100%", "100px")
                          }
                        />
                      ) : (
                        <Skeleton width={80} height={80} />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <h1 className="mb-0">
                        <p>{displayData.name}</p>
                      </h1>
                      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div className="d-flex flex-column flex-md-row align-items-center mb-3 mb-md-0">
                          {displayData.industries_type && (
                            <div className="d-flex align-items-center mx-2">
                              <div className="organization-details">
                                <BuildingsFill className="text-white mb-1" />
                              </div>
                              <p className="mb-0 ms-2">
                                {displayData.industries_type.title}
                              </p>
                            </div>
                          )}
                          <div className="d-flex align-items-center mx-2">
                            <div className="organization-details">
                              <TelephoneFill className="text-white mb-1" />
                            </div>
                            <p className="mb-0 ms-2">
                              {displayData.contact_number}
                            </p>
                          </div>
                          <div className="d-flex align-items-center mx-2">
                            <div className="organization-details">
                              <EnvelopeFill className="text-white mb-1" />
                            </div>
                            <p className="mb-0 ms-2">{displayData.email}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="date-picker-container">
                            <label className="date-picker-label text-secondary mb-0 me-2">
                              Date
                            </label>
                            <div className="date-picker-wrapper bg-white border border-1 d-flex align-items-center">
                              <DatePicker
                                className="border-0 text-center date-picker-input"
                                selected={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd-MM-yyyy"
                                wrapperClassName="custom-datepicker" // Add wrapper class for custom styling
                              />
                              <span className="mx-2">To</span>
                              <DatePicker
                                className="border-0 text-center date-picker-input"
                                selected={endDate}
                                onChange={(date: any) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="dd-MM-yyyy"
                                wrapperClassName="custom-datepicker" // Add wrapper class for custom styling
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <AnalyticsChart countData={countData} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nav-scroll-container">
                  <Nav
                    variant="tabs"
                    className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mt-5"
                    activeKey={activeKey}
                  >
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("overview")}
                        eventKey="overview"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "overview" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2">Overview</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("settings")}
                        eventKey="settings"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "settings" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2">Settings</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("campaign")}
                        eventKey="campaign"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "campaign" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2"> Campaign</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("new-arrivals")}
                        eventKey="new-arrivals"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "new-arrivals" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2">New Arrivals</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("sub-user")}
                        eventKey="sub-user"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "sub-user" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2">Sub User</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("activity-logs")}
                        eventKey="activity-logs"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "activity-logs" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2"> Activity Logs</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => handleNavigate("balance-logs")}
                        eventKey="balance-logs"
                        className={`nav-link text-active-primary me-6 ${
                          activeKey === "balance-logs" ? "active" : ""
                        }`}
                      >
                        <span className="mb-2"> Balance Logs</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Card.Body>
            </div>
          </div>
        </Card>

        {activeKey === "overview" && (
          <Overview
            organizationID={id}
            data={displayData}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        {activeKey === "settings" && (
          <Settings
            id={id}
            OrganizationData={data}
            fetchOrganization={fetchOrganization}
          />
        )}
        {activeKey === "campaign" && <Campaign />}
        {activeKey === "new-arrivals" && <NewArrivals />}
        {activeKey === "sub-user" && <SubUser />}
        {activeKey === "activity-logs" && <ActivityLog id={id} />}
        {activeKey === "balance-logs" && <BalanceLog />}
      </Container>
      <Modal
        centered
        show={showViewConfirmModal}
        onHide={() => setShowViewConfirmModal(false)}
      >
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={() => setShowViewConfirmModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="text-center p-1">
          <div>
            <h3>Confirm View</h3>
            <h5>Are you sure you want to view this organization details?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-4 d-flex justify-content-center">
          <button
            className="btn addnew-btn btn-sm"
            onClick={() => handleViewConfirm(id)}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <AssignPermissionModal
        show={showAssignPermissionModal}
        handleClose={closeAssignPermissionModal}
        organizationId={organizationIdforpermission}
      />
      <CustomDeleteModal
        title={title}
        model={Organization}
        deleteItemId={deleteItemId}
        setDeleteItemId={setDeleteItemId}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleDeleteClick={handleModalClick}
        getData={function (page: number): void {
          throw new Error("Function not implemented.");
        }}
      />
    </>
  );
}

export default View;

import axios from "axios";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { Model } from "@tailflow/laravel-orion/lib/model";
import { LOGOUT } from "../Routes/AppRoutes";

export async function fetchData(
  page: any,
  limit: any,
  inputValues: any,
  setLoading: any,
  setData: any,
  setTotalRows: any,
  navigate: any,
  Model: Model,
  sortField?: any,
  sortOrder?: any,
  include?: string | string[], // Add an optional include parameter
) {
  setLoading(true);

  try {
    let query: any = Model.$query();
    if (include) {
      // Check if include parameter is passed
      query = query.with(include); // Include the related data
    }

    for (const key in inputValues) {
      if (inputValues.hasOwnProperty(key)) {
        const nestedObject = inputValues[key] as { [key: string]: string };
        // eslint-disable-next-line
        for (const subKey in nestedObject) {
          let filterValue = nestedObject.value;
          if (nestedObject.filter_operation === FilterOperator.Like) {
            filterValue = "%" + filterValue + "%";
          }
          query = query.filter(key, nestedObject.filter_operation, filterValue);
        }
      }
    }

    if (sortField && sortOrder) {
      sortField = sortField.toString().trim();
      const idMatch = sortField.match(/row\s*=>\s*row\.(\w+)/);
      if (idMatch && idMatch.length === 2) {
        sortField = idMatch[1];
      }
      query = query.sortBy(sortField, sortOrder);
    }
    const modelData: any = await query.search(limit, page);
    setData(modelData[0]?.$response?.data.data);
    setTotalRows(modelData[0]?.$response?.data.meta.total);
    setLoading(false);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          navigate(LOGOUT);
        }
      }
    }
  }
}

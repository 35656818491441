import { RouteObject } from "react-router-dom";
import List from "../Pages/NotificationTemplate/List";
import AddUpdate from "../Pages/NotificationTemplate/AddUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_NOTIFICATION_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE,
  VIEW_NOTIFICATION_TEMPLATE,
} from "../Utils/PermissionsEnum";

export const NOTIFICATION_TEMPLATE_LIST = "notification-template";
export const NOTIFICATION_TEMPLATE_ADD = "add";
export const NOTIFICATION_TEMPLATE_EDIT = ":id/edit";

const notificationTemplateRoutes: RouteObject[] = [
  {
    path: NOTIFICATION_TEMPLATE_LIST,
    element: (
      <AuthorizedRoute
        element={<List />}
        permission={VIEW_NOTIFICATION_TEMPLATE}
      />
    ),
  },
  {
    path: `${NOTIFICATION_TEMPLATE_LIST}/${NOTIFICATION_TEMPLATE_ADD}`,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_NOTIFICATION_TEMPLATE}
      />
    ),
  },
  {
    path: `${NOTIFICATION_TEMPLATE_LIST}/${NOTIFICATION_TEMPLATE_EDIT}`,

    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_NOTIFICATION_TEMPLATE}
      />
    ),
  },
];

export default notificationTemplateRoutes;

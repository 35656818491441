import { Model } from "@tailflow/laravel-orion/lib/model";

export class OrganizationBalanceLog extends Model<{
  id: number;
  organization_id: number;
  added_balance: string;
  added_by: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  user: any;
}> {
  public $resource(): string {
    return "organization_balance_logs";
  }
}

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../Utils/Toast";
import { getUser } from "../../Utils/Auth";
import axios from "axios";
import { LOGOUT } from "../../Routes/AppRoutes";
import { Container, Form, Card } from "react-bootstrap";
import { useOrganization } from "../../Utils/OrganizationContext";
import useAxiosInstance from "../../Utils/Headers";

function ProductImportFile({ closeModal }: any) {
  let { selectedOrganizationId }: any = useOrganization();
  let user = getUser();
  const [organizationID, setOrganizationID] = useState<any>();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileValue, setFileValue] = useState<File | null>(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    if (selectedOrganizationId) {
      setOrganizationID(selectedOrganizationId);
    } else {
      setOrganizationID(user.organization_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (!fileValue) {
        setError("Please choose a file to upload.");
        return;
      }
      setError("");
      setLoading(true);

      const zipUpload = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/uploadZipWithPreSignURl`,
        { organization_id: organizationID, file: fileValue.name },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      console.log(zipUpload.data);
      console.log("preSigned url:", zipUpload.data.preSigned_url);

      const formData = new FormData();
      formData.append("file", fileValue);
      await axiosInstance.put(zipUpload.data.preSigned_url, fileValue);

      navigate(-1);
      setToast("success", `Product Import Successfully`);
    } catch (error: any) {
      setToast("error", error.response.data.message);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            navigate(LOGOUT);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchProductZipFile = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/download_demo_zip`,
        {
          responseType: "blob",
        },
      );
      const contentDisposition = response.headers["content-disposition"];
      let filename = "Product Import Sample Zip file.zip";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+)"?/);
        if (match) filename = match[1];
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching Product Zip File:", error);
    }
  };

  return (
    <Container>
      <Card className="mt-5">
        <Card.Header>
          <h1 className="mt-4">Product Import</h1>
          <div className="text-end">
            <button
              className="btn download-zip-btn btn-sm border-0"
              onClick={() => fetchProductZipFile()}
            >
              <img
                src="/images/svg/zip-Icon.svg"
                alt="Zip Icon"
                className="me-2"
              />
              <span>Download Product Import Sample Zip</span>
            </button>
            <p className="help-text p-0 m-0">
              For reference, download the product import sample zip file here.
            </p>
          </div>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="file" className="pt-2">
              <Form.Label>Choose File*</Form.Label>
              <Form.Control
                type="file"
                placeholder="Enter Register file"
                accept=".zip"
                onChange={(event: any) => {
                  const selectedFile = event.currentTarget.files[0];
                  setFileValue(selectedFile);
                }}
              />
              <p className="mb-0 fs-6 text-secondary">Upload only zip files.</p>
              {error.length > 0 && <p className="text-danger">{error}</p>}
            </Form.Group>

            <div className="text-end ">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-sm back-btn me-2 mt-4 fs-6 text-white ps-5 pe-5"
                type="button"
              >
                Back
              </button>
              <button
                type="submit"
                className="btn addnew-btn mt-4 btn-sm ms-2"
                disabled={loading}
              >
                {loading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <Card className="zip-help">
        <Card.Body>
          <div className="mb-5">
            <h3>Help</h3>
            <h5>
              Upload Zip file-{">"} In order to create Zip file please follow
              the below mentioned structure.
            </h5>
            <p>
              Eg: Create Product Category folder like "Glossy", inside Glossy,
              create "Product Size folder" like 600x600 or 200x200 etc, inside
              size folder, create "Product Type folder" like Floor, inside
              product type, create "Product Color folder" like White, inside
              color folder, you can add images.
            </p>
          </div>
          <div className="mt-5 ms-5">
            <img
              src="../../../images/svg/Zip_Folder.svg"
              alt="Zip Folder"
              className="img-fluid"
              height={"450px"}
              width={"550px"}
            />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ProductImportFile;

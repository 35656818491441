import React, { useEffect, useState } from "react";
import { setToast } from "../Utils/Toast";
import { formatDistanceToNowStrict } from "date-fns";
import { getCookie } from "typescript-cookie";
import useAxiosInstance from "../Utils/Headers";
import { ArrowRepeat } from "react-bootstrap-icons";
import { Timeline, TimelineEvent } from "react-event-timeline";

function ActivityLogs({ auditable_type, id }: any) {
  const [logsByDate, setLogsByDate] = useState<{ [date: string]: any[] }>({});
  const axiosInstance = useAxiosInstance();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [organizationId, setOrganizationId] = useState<number[]>([]);

  useEffect(() => {
    if (id) {
      getData(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, id, organizationId]);

  const userCookie = getCookie("user");

  useEffect(() => {
    if (userCookie) {
      try {
        const user = JSON.parse(userCookie);
        if (
          Array.isArray(user.user_organizations) &&
          user.user_organizations.length > 0
        ) {
          setOrganizationId(user.user_organizations[0].id);
        } else {
          console.log("user_organizations is empty or not an array");
        }
      } catch (error) {
        console.error("Failed to parse user cookie", error);
      }
    }
  }, [userCookie]);

  const getData = async (page: number) => {
    try {
      const searchData = {
        auditable_type: auditable_type,
        auditable_id: id,
        page: page,
        limit: 10,
      };
      const apiURL = `${process.env.REACT_APP_API_URL}api/audit_logs`;

      const response = await axiosInstance.post(apiURL, searchData);

      const { data } = response;

      const logs = Array.isArray(data.data.data) ? data.data.data : [];
      const lastPage = data.data.last_page;

      groupLogsByDate(logs);

      if (lastPage === page) {
        setHasMore(false);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setToast("error", error.response?.data?.message || "An error occurred");
    }
  };

  const groupLogsByDate = (newLogs: any[]) => {
    setLogsByDate((prevLogs) => {
      const updatedLogs = { ...prevLogs };

      newLogs.forEach((log) => {
        const date = new Date(log.created_at).toDateString();
        if (!updatedLogs[date]) {
          updatedLogs[date] = [];
        }
        if (log.event === "created" || log.event === "updated") {
          if (Object.keys(JSON.parse(log.new_values)).length > 0) {
            const newLog = {
              user: log.user.name,
              log,
            };

            if (!updatedLogs[date].some((entry) => entry.log.id === log.id)) {
              updatedLogs[date].push(newLog);
            }
          }
        } else {
          if (!updatedLogs[date].some((entry) => entry.log.id === log.id)) {
            updatedLogs[date].push({
              user: log.user.first_name + " " + log.user.last_name,
              log,
            });
          }
        }
      });
      setLoading(false);
      return updatedLogs;
    });
  };

  const getRelativeTime = (dateString: string) => {
    const date = new Date(dateString);
    return formatDistanceToNowStrict(date, { addSuffix: true });
  };

  const renderTable = (
    oldValues: any,
    newValues: any,
    className: any,
    title: any,
    date: any,
    userName: any,
    action: any,
  ) => {
    return (
      <div className="activity-table">
        {Object.keys(newValues).map((key) => (
          <React.Fragment key={key}>
            <p className="fs-5">
              <span>
                <strong>
                  {userName}&nbsp;{action}&nbsp;
                </strong>
              </span>
              <span className="blue-text">{key}</span>
            </p>
            <table className={`table-width table table-${className} mx-5 mb-3`}>
              <tbody className="border border-dashed border-2">
                <tr>
                  {action !== "created" && (
                    <td className="mx-1 logs-oldvalues">{oldValues[key]}</td>
                  )}
                  <td className="logs-newvalues">{newValues[key]}</td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const getLogMessage = (log: any, index: any) => {
    const userName = log.user;
    const action = log.log.event;
    const newValues = JSON.parse(log.log.new_values);
    const oldValues = JSON.parse(log.log.old_values);

    const renderDetails = () => {
      switch (action) {
        case "created":
          return renderTable(
            {},
            newValues,
            "success",
            "New Values",
            log.log.created_at,
            userName,
            action,
          );
        case "updated":
          return (
            <div className="d-flex justify-content-between w-100">
              <span className="w-100">
                {renderTable(
                  oldValues,
                  newValues,
                  "info",
                  "Changes",
                  log.log.created_at,
                  userName,
                  action,
                )}
              </span>
            </div>
          );
        case "deleted":
        case "restored":
          return <span>the item</span>;
        default:
          return null;
      }
    };

    return (
      <div key={index}>
        {(action === "created" || action === "updated") && (
          <div className="mb-5">{renderDetails()}</div>
        )}
        {(action === "deleted" || action === "restored") && (
          <>
            <p className="d-flex">
              <span className="me-1 fw-bold">{userName}</span>
              <span className="me-1 text-primary">{action}</span>
              {renderDetails()}
              <span className="ms-auto  text-primary">
                {getRelativeTime(log.log.created_at)}
              </span>
            </p>
          </>
        )}
      </div>
    );
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }
  if (Object.keys(logsByDate).length === 0) {
    return (
      <div className="mb-4 mt-5 text-center text-secondary">
        No activity logs available
      </div>
    );
  }
  const renderIcon = (action: string) => {
    switch (action) {
      case "created":
        return (
          <img
            src="../../images/svg/Create_icon.svg"
            alt=""
            className="create-icon"
          />
        );
      case "updated":
        return (
          <ArrowRepeat
            size={22}
            style={{ marginLeft: "100px" }}
            className="arrow-repeat"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Timeline
        lineStyle={{
          borderLeft: "1px dashed #DBDFE9",
        }}
        lineColor="transparent"
        style={{
          height: "auto",
          padding: "0",
        }}
      >
        {Object.keys(logsByDate).map((date) => (
          <TimelineEvent
            key={date}
            title={date}
            className="custom-timeline-event"
            iconColor="#DBDFE9"
            bubbleStyle={{
              height: "39px",
              width: "39px",
              margin: "-4px",
              marginTop: "-11px",
              backgroundColor: "#ffffff",
              border: "1px solid #DBDFE9",
            }}
            icon={renderIcon(logsByDate[date][0]?.log.event)}
          >
            {logsByDate[date].map((log: any, index: number) => (
              <div className="mb-3 border-0 bg-transparent" key={log.log.id}>
                <div
                  className="p-0"
                  style={{
                    height:
                      log.log.event === "restored" ||
                      log.log.event === "deleted"
                        ? "20px"
                        : "auto",
                  }}
                >
                  <p>{getLogMessage(log, index)}</p>
                </div>
              </div>
            ))}
          </TimelineEvent>
        ))}
      </Timeline>
      {hasMore && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            className="text-primary me-3 cursor-pointer"
            onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          >
            Show More...
          </p>
        </div>
      )}
    </>
  );
}

export default ActivityLogs;

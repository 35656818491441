import { Model } from "@tailflow/laravel-orion/lib/model";
export class Users extends Model<{
  name: string;
  email: string;
  organization_id: number;
  mobile_no: string;
}> {
  public $resource(): string {
    return "users";
  }
}

import { RouteObject } from "react-router-dom";
import List from "../Pages/TileCoverages/List";
import AddUpdate from "../Pages/TileCoverages/AddUpdate";
import AuthorizedRoute from "./AuthorizedRoute";
import {
  ADD_TILE_SIZE,
  UPDATE_TILE_SIZE,
  VIEW_TILE_SIZE,
} from "../Utils/PermissionsEnum";

export const TILE_COVERAGES_LIST = "tile-coverages";
export const TILE_COVERAGES_ADD = "add";
export const TILE_COVERAGES_EDIT = ":id/edit";

const TileCoveragesRoutes: RouteObject[] = [
  {
    path: TILE_COVERAGES_LIST,
    element: <AuthorizedRoute element={<List />} permission={VIEW_TILE_SIZE} />,
  },
  {
    path: `${TILE_COVERAGES_LIST}/${TILE_COVERAGES_ADD}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={ADD_TILE_SIZE} />
    ),
  },
  {
    path: `${TILE_COVERAGES_LIST}/${TILE_COVERAGES_EDIT}`,
    element: (
      <AuthorizedRoute element={<AddUpdate />} permission={UPDATE_TILE_SIZE} />
    ),
  },
];

export default TileCoveragesRoutes;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { setToast } from "../Utils/Toast";
import axios from "axios";
import { LOGOUT } from "../Routes/AppRoutes";
import { Card, Container, Spinner } from "react-bootstrap";
import SelectField from "../Components/AsyncSelect";

interface Field {
  id: string;
  title: string;
  type: string;
  col: number;
  description?: string;
  placeholder?: string;
  options?: { value: string; label: string }[];
  validate?: {
    rule: (value: any) => boolean;
    message: string;
  }[];
  required: boolean;
  color?: string;
  checked?: boolean;
}

interface AddUpdateFormProps {
  formFields: Field[];
  model?: any;
  title: string;
  customComponents?: { [key: string]: React.ReactNode };
  customHandleSubmit?: (formData: any) => void;
  customErrorMessages?: Record<string, string>;
  onFieldChange?: (fieldId: string, value: any) => void;
}

const AddUpdateForm: React.FC<AddUpdateFormProps> = ({
  model,
  formFields,
  title,
  customHandleSubmit,
  customErrorMessages,
  onFieldChange,
}) => {
  const [formData, setFormData] = useState<any>({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>(
    {},
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getFormData(id).then((itemData) => {
        setFormData(itemData);
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const getFormData = async (id: string | number) => {
    try {
      const item = await model.$query().find(id);
      return item.$attributes;
    } catch (error) {
      handleAxiosError(error);
    }
  };

  const handleAxiosError = (error: any) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          navigate(LOGOUT);
        }
        setToast("error", error.response?.data?.message);
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target;

    const val =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: val,
    }));

    // Call onFieldChange if provided
    if (onFieldChange) {
      onFieldChange(name, val);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check for required fields
    const missingFields: string[] = [];
    formFields.forEach((field) => {
      if (field.required && !formData[field.id]) {
        missingFields.push(field.id);
      }
    });

    if (missingFields.length > 0) {
      const messages: Record<string, string> = {};
      missingFields.forEach((field) => {
        messages[field] = `${field} is required.`;
      });
      setErrorMessages(messages);
      return;
    }

    setLoading(true);
    try {
      if (formData.id) {
        let item = await model.$query().find(formData.id);
        item.$attributes = {
          ...item.$attributes,
          ...formData,
        };
        await item.$save();
        setToast("success", `${title} Successfully Updated`);
      } else {
        await model.$query().store(formData);
        setToast("success", `${title} Successfully Added`);
      }
      navigate(-1);
    } catch (error: any) {
      handleAxiosError(error);
      setErrorMessages(error.response?.data?.errors || {});
    } finally {
      setLoading(false);
    }
  };

  const actualHandleSubmit = customHandleSubmit
    ? (e: React.FormEvent) => {
        e.preventDefault();
        customHandleSubmit(formData);
      }
    : handleSubmit;

  const finalErrorMessages = customErrorMessages
    ? customErrorMessages
    : errorMessages;

  return (
    <Container>
      <Card className="mt-5">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <form onSubmit={actualHandleSubmit} className="row p-5">
            {formFields.map((field) => (
              <div key={field.id} className={`col-${field.col}`}>
                <div className="form-group">
                  <label className="ms-2 mt-3" htmlFor={field.id}>
                    {field.title}
                  </label>
                  {field.type === "color" ? (
                    <div className="d-flex align-items-center mt-1 p-1 bg-white rounded-2 border-0">
                      <input
                        type="color"
                        id={field.id}
                        name={field.id}
                        className="p-0 form-control ms-2 color-box"
                        value={formData[field.id] || ""}
                        onChange={(e) => {
                          const colorValue = e.target.value;
                          setFormData((prevData: any) => ({
                            ...prevData,
                            [field.id]: colorValue,
                            [`#${field.id}`]: colorValue,
                          }));

                          // Call onFieldChange if provided
                          if (onFieldChange) {
                            onFieldChange(field.id, colorValue);
                          }
                        }}
                      />
                      <input
                        type="text"
                        id={`#${field.id}`}
                        name={`#${field.id}`}
                        className="form-control form-control-lg form-control-solid border-0"
                        value={formData[`#${field.id}`] || ""}
                        readOnly
                      />
                    </div>
                  ) : field.type === "textarea" ? (
                    <textarea
                      id={field.id}
                      name={field.id}
                      className="form-control form-control-lg form-control-solid mt-1"
                      placeholder={field.placeholder}
                      value={formData[field.id] || ""}
                      onChange={handleInputChange}
                    />
                  ) : field.type === "checkbox" ? (
                    <input
                      type="checkbox"
                      id={field.id}
                      name={field.id}
                      className="form-check-input square-checkbox"
                      checked={formData[field.id] || false}
                      onChange={handleInputChange}
                    />
                  ) : field.type === "select" ? (
                    <SelectField
                      options={field.options || []}
                      value={
                        field.options
                          ? field.options.find(
                              (option) => option.value === formData[field.id],
                            ) || null
                          : null
                      }
                      onChange={(selectedOption: any) => {
                        handleInputChange({
                          target: {
                            name: field.id,
                            value: selectedOption?.value || "",
                          },
                        } as React.ChangeEvent<HTMLSelectElement>);

                        // Call onFieldChange if provided
                        if (onFieldChange) {
                          onFieldChange(field.id, selectedOption?.value || "");
                        }
                      }}
                      error={finalErrorMessages[field.id]}
                    />
                  ) : (
                    <input
                      type={field.type}
                      id={field.id}
                      name={field.id}
                      className="form-control form-control-lg form-control-solid mt-1"
                      placeholder={field.placeholder}
                      value={formData[field.id] || ""}
                      onChange={handleInputChange}
                    />
                  )}
                  {field.description && (
                    <small className="form-text text-muted">
                      {field.description}
                    </small>
                  )}
                  {finalErrorMessages[field.id] && (
                    <div className="text-danger">
                      {finalErrorMessages[field.id]}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-end gap-2">
              <button
                type="button"
                className="btn back-btn mt-4 btn-sm"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn addnew-btn mt-4 btn-sm"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    className="mt-1"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddUpdateForm;

import React, { useEffect, useRef, useState } from "react";
import "jsvectormap/dist/css/jsvectormap.css";
import { Chart } from "chart.js/auto";
import moment from "moment";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import { useNavigate } from "react-router-dom";
import { PRODUCT_VIEW } from "../Routes/ProductsRoutes";
import { ButtonGroup } from "react-bootstrap";
import { Eye } from "react-bootstrap-icons";
import { REAL_TIME_DASHBOARD } from "../Routes/RealTimeDashboard";
import { useOrganization } from "../Utils/OrganizationContext";
import useAxiosInstance from "../Utils/Headers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface OrganizationAnalyticsData {
  total_visitors: number;
  total_ai_images: number;
  total_catalog: number;
  total_products: number;
}
const Dashboard = () => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();
  const currentDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(currentDate.getDate() - 15);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [organizationanalyticsData, setOrganizationAnalyticsData] =
    useState<OrganizationAnalyticsData | null>(null);
  const [visitorData, setVisitorData] = useState([]);
  const [products, setProducts] = useState<any[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  let { selectedOrganizationId, organizationId }: any = useOrganization();

  const axiosInstance = useAxiosInstance();
  const apiUrl = process.env.REACT_APP_API_URL as string;
  useEffect(() => {
    fetchOrganizationData();
    fetchDailyVisitorData();
    fetchTopProductsData();
    fetchTopLocationsData();
    // eslint-disable-next-line
  }, [startDate, endDate, organizationId]);
  // --------------------get all data----------\

  const fetchOrganizationData = async () => {
    if (organizationId) {
      try {
        const response = await axiosInstance.post(
          apiUrl + `api/organization/analytics`,
          {
            start_date: moment(startDate).format("DD-MMM-YYYY"),
            end_date: moment(endDate).format("DD-MMM-YYYY"),
          },
        );

        // Assuming `data` property holds the actual response data
        const data = response.data;

        // Assuming `setOrganizationAnalyticsData` is a function to update state or handle data
        setOrganizationAnalyticsData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const fetchDailyVisitorData = async () => {
    if (organizationId) {
      try {
        const response = await axiosInstance.post(
          apiUrl + `api/visitors/per_day/analytics`,
          {
            start_date: moment(startDate).format("DD-MMM-YYYY"),
            end_date: moment(endDate).format("DD-MMM-YYYY"),
          },
        );
        const data = response.data.data;
        const visitorTotals = data.total_visitors_ai_images.map(
          (item: any) => ({
            date: item.date,
            total: item.total,
          }),
        );
        setVisitorData(visitorTotals);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // =================top products==================
  const fetchTopProductsData = async () => {
    if (organizationId) {
      try {
        const response = await axiosInstance.post(
          apiUrl + `api/visitors/top_product/analytics`,
          {
            start_date: moment(startDate).format("DD-MMM-YYYY"),
            end_date: moment(endDate).format("DD-MMM-YYYY"),
          },
        );
        const { data } = response.data;
        setProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // ===================================
  // =================top products==================
  const fetchTopLocationsData = async () => {
    if (organizationId) {
      try {
        const response = await axiosInstance.post(
          apiUrl + `api/visitors/top_location/analytics`,
          {
            start_date: moment(startDate).format("DD-MMM-YYYY"),
            end_date: moment(endDate).format("DD-MMM-YYYY"),
          },
        );
        const data = response.data.data;
        const newMarkers = data.map((item: any) => ({
          name: item.city,
          coords: [item.latitude, item.longitude],
          Totalvisitors: item.total_visitors,
        }));
        setMarkers(newMarkers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  // ===================================
  // ------------------------------

  // --------------------Total visitors--------------------
  useEffect(() => {
    const salesChart = new Chart("saleschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [19, 10, 15, 8, 6, 10, 13],
            backgroundColor: [
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.2)",
              "rgba(5, 195, 251, 0.2)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderColor: [
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "rgba(5, 195, 251, 0.5)",
              "rgba(5, 195, 251, 0.5)",
              "#05c3fb",
              "#05c3fb",
            ],
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
            ticks: { font: { size: 2 } },
          },
          y: { display: false, ticks: { display: false } },
        },
        elements: { point: { radius: 0 } },
      },
    });

    return () => {
      salesChart.destroy();
    };
  }, [startDate, endDate]);
  // ------------------------------------------------------
  // --------------------Profit chart----------------------
  useEffect(() => {
    const profitchart = new Chart("profitchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              45, 23, 32, 67, 49, 72, 52, 55, 46, 54, 32, 74, 88, 36, 36, 32,
              48, 54,
            ],
            backgroundColor: "transparent",
            borderColor: "#f46ef4",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
            borderWidth: 2, // Adjust the border width as needed
          },
        ],
      },

      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            labels: {},
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            title: {
              display: false,
            },
            grid: {
              display: true,
              color: "transparent",
            },
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
            borderColor: "red",
          },
        },
      },
    });

    return () => {
      profitchart.destroy();
    };
  }, [startDate, endDate]);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const aiimageschart = new Chart("aiimageschart", {
      type: "bar",
      data: {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
          {
            barPercentage: 0.1,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: "Total Sales",
            data: [10, 12, 5, 6, 3, 11, 12],
            backgroundColor: "#4ecc48",
            borderColor: "#4ecc48",
            borderRadius: 10,
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      aiimageschart.destroy();
    };
  }, [startDate, endDate]);
  // ------------------------------------------------------
  // --------------------AI Images chart-------------------
  useEffect(() => {
    const costchart = new Chart("costchart", {
      type: "line",
      data: {
        labels: [
          "Date 1",
          "Date 2",
          "Date 3",
          "Date 4",
          "Date 5",
          "Date 6",
          "Date 7",
          "Date 8",
          "Date 9",
          "Date 10",
          "Date 11",
          "Date 12",
          "Date 13",
          "Date 14",
          "Date 15",
          "Date 16",
          "Date 17",
        ],
        datasets: [
          {
            label: "Total Sales",
            data: [
              28, 56, 36, 32, 48, 54, 37, 58, 66, 53, 21, 24, 14, 45, 0, 32, 67,
              49, 52, 55, 46, 54, 130,
            ],
            backgroundColor: "transparent",
            borderColor: "#f7ba48",
            pointBorderColor: "transparent",
            pointBackgroundColor: "transparent",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });

    return () => {
      costchart.destroy();
    };
  }, [startDate, endDate]);
  // ------------------------------------------------------
  // --------------daily analytics visitors----------------
  useEffect(() => {
    let myChart: Chart<"line"> | null = null; // Explicitly define myChart type

    if (chartRef.current && visitorData) {
      myChart = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: visitorData.map((item: any) => item.date),
          datasets: [
            {
              label: "Total Sales",
              data: visitorData.map((item: any) => item.total),
              borderColor: "#05c3fb",
              pointBackgroundColor: "#fff",
              pointBorderColor: "#05c3fb",
              pointBorderWidth: 0,
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 3,
              fill: "origin",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              labels: {
                usePointStyle: false,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
                color: "rgba(119, 119, 142, 0.08)",
              },
              ticks: {
                autoSkip: true,
                color: "#b0bac9",
              },
            },
            y: {
              ticks: {
                stepSize: 150,
                color: "#b0bac9",
              },
              display: true,
              grid: {
                display: true,
                color: "rgba(142, 156, 173,0.1)",
              },
            },
          },
        },
      });
    }

    return () => {
      if (myChart) {
        myChart.destroy(); // Cleanup the chart when component unmounts
      }
    };
  }, [visitorData, chartRef, startDate, endDate]);

  // ------------------------------------------------------

  // --------------------recent orders chart-------------------
  useEffect(() => {
    const recentorders = new Chart("recentorders", {
      type: "bar",
      data: {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        datasets: [
          {
            barThickness: 8,
            label: "This Month",
            data: [27, 50, 28, 50, 28, 30, 22],
            backgroundColor: "#61c9fc",
            borderWidth: 2,
            hoverBackgroundColor: "#61c9fc",
            hoverBorderWidth: 0,
            borderColor: "#61c9fc",
            hoverBorderColor: "#61c9fc",
            borderRadius: 10,
          },
          {
            barThickness: 8,
            label: "This Month",
            data: [32, 58, 68, 65, 40, 68, 58],
            backgroundColor: "#f38ff3",
            borderWidth: 2,
            hoverBackgroundColor: "#f38ff3",
            hoverBorderWidth: 0,
            borderColor: "#f38ff3",
            hoverBorderColor: "#f38ff3",
            borderRadius: 10,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
              color: "rgba(142, 156, 173,0.1)",
            },
          },
          x: {
            display: false,
            stacked: false,
            grid: {
              color: "rgba(142, 156, 173,0.1)",
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      },
    });
    return () => {
      recentorders.destroy();
    };
  }, []);
  // ------------------------------------------------------
  useEffect(() => {
    if (markers.length > 0) {
      // eslint-disable-next-line
      const map = new jsVectorMap({
        selector: "#map",
        map: "world",
        markersSelectable: true,
        markers: markers,
        // labelName: "Hello Canada",
        showTooltip: false,
        regionStyle: {
          hover: { fill: "blue" },
        },
        onMarkerSelected(index: any, isSelected: any, selectedMarkers: any) {},
        labels: {
          markers: {
            render: function (marker: any) {
              return marker.name;
            },
          },
        },
        markerStyle: {
          hover: {
            stroke: "#DDD",
            strokeWidth: 3,
            fill: "#000",
          },
          selected: {
            fill: "#ff525d",
          },
        },
        markerLabelStyle: {
          initial: {
            fontFamily: "Poppins",
            fontSize: 13,
            fontWeight: 500,
            fill: "#35373e",
          },
        },
      });
    }
  }, [markers]);
  return (
    <div>
      <div className="mb-2">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="date-picker-container">
              <label className="date-picker-label text-secondary mb-0 me-2">
                Date
              </label>
              <div className="date-picker-wrapper bg-white border border-1 d-flex align-items-center">
                <DatePicker
                  className="border-0 text-center date-picker-input"
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="custom-datepicker"
                />
                <span className="mx-2">To</span>
                <DatePicker
                  className="border-0 text-center date-picker-input"
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="custom-datepicker"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
            <button
              className="btn edit-btn w-100 w-md-auto btn-sm"
              onClick={() => {
                if (selectedOrganizationId) {
                  navigate(`/${selectedOrganizationId}/` + REAL_TIME_DASHBOARD);
                } else {
                  navigate(`/` + REAL_TIME_DASHBOARD);
                }
              }}
            >
              Real Time Dashboard
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body border border-dashed border-2 rounded">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Total visitors</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {organizationanalyticsData?.total_visitors}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="saleschart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body border border-dashed border-2 rounded">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Total Ai Images</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {organizationanalyticsData?.total_ai_images}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="profitchart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body border border-dashed border-2 rounded">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Total Catalog</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {organizationanalyticsData?.total_catalog}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="aiimageschart"
                        className="chart-dropshadow catalog-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xxl-3">
            <div className="card overflow-hidden">
              <div className="card-body border border-dashed border-2 rounded">
                <div className="d-flex">
                  <div className="mt-2">
                    <h6 className="fw-normal">Total Products</h6>
                    <h2 className="mb-0 text-dark fw-semibold">
                      {organizationanalyticsData?.total_products}
                    </h2>
                  </div>
                  <div className="ms-auto">
                    <div className="chart-wrapper mt-1">
                      <canvas
                        id="costchart"
                        className="chart-dropshadow"
                      ></canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-xxl-9">
            <div className="card overflow-hidden border border-dashed border-2">
              <div className="card-header">
                <h3 className="card-title">Visitors Analytics</h3>
              </div>
              <div className="card-body">
                <div className="chartjs-wrapper-demo w-100 visitors-analytics">
                  <canvas ref={chartRef} className="chart-dropshadow"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3">
            <div className="card overflow-hidden ">
              <div className="card-body pb-0 bg-recentorder">
                <h3 className="card-title text-fixed-white mb-4">
                  Recent Orders
                </h3>
                <div className="chartjs-wrapper-demo recent-orders">
                  <canvas
                    id="recentorders"
                    className="chart-dropshadow"
                  ></canvas>
                </div>
              </div>
              <div id="back-chart"></div>
              <div className="card-body border border-dashed border-2">
                <div className="d-flex mb-4 mt-3">
                  <div className="avatar avatar-md bg-secondary-transparent text-secondary bradius me-3">
                    <i className="fe fe-check"></i>
                  </div>
                  <div className="">
                    <h6 className="mb-1 fw-semibold fs-14">Delivered Orders</h6>
                    <p className="fw-normal fs-12 mb-1">
                      <span className="text-success">3.5%</span>
                      increased
                    </p>
                  </div>
                  <div className=" ms-auto mb-auto">
                    <p className="fw-bold fs-20 mb-0"> 1,768 </p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="avatar  avatar-md bg-pink-transparent text-pink bradius me-3">
                    <i className="fe fe-x"></i>
                  </div>
                  <div className="">
                    <h6 className="mb-1 fw-semibold fs-14">Cancelled Orders</h6>
                    <p className="fw-normal fs-12 mb-1">
                      <span className="text-success">1.2%</span>
                      increased
                    </p>
                  </div>
                  <div className=" ms-auto mb-auto">
                    <p className="fw-bold fs-20 mb-0"> 3,675 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-4 col-lg-6 col-md-12">
            <div className="card overflow-hidden border border-dashed border-2">
              <div className="card-header">
                <div>
                  <h3 className="card-title">
                    Visitors by Locations with Devices
                  </h3>
                </div>
              </div>
              <div className="card-body p-0 mt-2">
                <div className="">
                  <div id={"map"}></div>
                </div>
                <div className="table-responsive mt-2 text-center table-dashed">
                  <table className="table text-nowrap mb-0">
                    <thead className="text-uppercase">
                      <tr>
                        <th scope="col" className="text-start ps-4">
                          Device
                        </th>
                        <th scope="col" className="">
                          USA
                        </th>
                        <th scope="col" className="">
                          India
                        </th>
                        <th scope="col" className="">
                          Bahrain
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-start p-3 d-flex align-items-center">
                          <span className="sales-icon text-primary bg-primary-transparent">
                            <i className="bi bi-phone"></i>
                          </span>
                          Mobiles
                        </td>
                        <td className="p-3">17%</td>
                        <td className="p-3">22%</td>
                        <td className="p-3">11%</td>
                      </tr>
                      <tr>
                        <td className="text-start p-3 d-flex align-items-center">
                          <span className="sales-icon text-secondary bg-secondary-transparent ">
                            <i className="bi bi-display"></i>
                          </span>
                          Desktops
                        </td>
                        <td className="p-3">34%</td>
                        <td className="p-3">76%</td>
                        <td className="p-3">58%</td>
                      </tr>
                      <tr>
                        <td className="border-bottom-0 text-start p-3 d-flex align-items-center">
                          <span className="sales-icon text-danger bg-danger-transparent">
                            <i className="bi bi-tablet"></i>
                          </span>
                          Tablets
                        </td>
                        <td className="border-bottom-0 p-3">56%</td>
                        <td className="border-bottom-0 p-3">83%</td>
                        <td className="border-bottom-0 p-3">66%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-8 col-lg-6 col-md-12">
            <div className="card overflow-hidden border border-dashed border-2">
              <div className="card-header">
                <h3 className="card-title">Top Product Of Organization</h3>
              </div>
              <table className="table table-bordered bg-white">
                <thead className="bg-white">
                  <tr className="text-center">
                    <th>ID</th>
                    <th>Product Name</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr className="text-center" key={product.id}>
                      <td>{product.id}</td>
                      <td>{product.product_name_slug}</td>
                      <td>
                        <img
                          src={product.image_url}
                          alt={product.title}
                          className="top-product-image"
                        />
                      </td>
                      <td>
                        <ButtonGroup>
                          <button
                            className="btn border-0 btn-sm"
                            title="Vieew Product"
                            onClick={() => {
                              navigate(
                                PRODUCT_VIEW.replace(
                                  ":id",
                                  product.id.toString(),
                                ),
                              );
                            }}
                          >
                            <Eye className="ml-4 fs-5 top-product-view" />
                          </button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import { RouteObject } from "react-router-dom";
import PermissionList from "../Pages/Setting/Permission/List";
import AddUpdate from "../Pages/Setting/Permission/AddUpdate";

export const PERMISSIONS_LIST = "permissions";
export const PERMISSIONS_ADD = "add";
export const PERMISSIONS_EDIT = ":id/edit";

const Permission: RouteObject[] = [
  { path: PERMISSIONS_LIST, element: <PermissionList /> },
  { path: `${PERMISSIONS_LIST}/${PERMISSIONS_ADD}`, element: <AddUpdate /> },
  { path: `${PERMISSIONS_LIST}/${PERMISSIONS_EDIT}`, element: <AddUpdate /> },
];

export default Permission;

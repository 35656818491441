import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Image, Row } from "react-bootstrap";
import useAxiosInstance from "../../Utils/Headers";
import { handleImageError } from "../../Components/Noimage";
import CustomSkeleton from "../../Components/CustomSkeleton";

const AiimagesView = () => {
  const { id, roomId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState({
    output_image: "",
    id: "",
    room: {
      name: "",
    },
    created_at: "",
    deleted_at: "",
    floor_percentage: "",
    floor_tile: {
      product_name: "",
      width: "",
      height: "",
    },
    updated_at: "",
    wall_percentage: "",
    wall_tile: {
      product_name: "",
      height: "",
      width: "",
    },
    ai_image_status: "",
    floor_rotation_angle: "",
    ip_address: "",
    detected_coordinate_data: "",
    organization: {
      name: "",
    },
    parent_id: "",
    uuid: "",
    visitor: {
      email: "",
      mobile_number: "",
    },
  });
  const axiosInstance = useAxiosInstance();

  const specificaiimageofroom = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/rooms/${roomId}/aiImages/${id}?include=organization,visitor,room,floorTile,wallTile`,
      );
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      specificaiimageofroom();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const fallBackSrc = "/images/svg/noimage.svg";

  return (
    <div className="container-fluid">
      <div className="text-end">
        <button
          type="button"
          className="btn back-btn btn-sm mb-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      <Row className="my-3 justify-content-center">
        <Col
          lg={7}
          md={12}
          sm={12}
          className="d-flex justify-content-center card align-items-center mt-3 product-col"
        >
          <div className="w-100">
            {loading ? (
              <CustomSkeleton height={410} />
            ) : (
              data && (
                <Image
                  src={data.output_image}
                  className="aiimage"
                  onError={(e) =>
                    handleImageError(e, fallBackSrc, "100%", "160px")
                  }
                />
              )
            )}
          </div>
        </Col>
        <Col lg={4} md={12} sm={12} className="mt-3 ms-3">
          <Card>
            <>
              {loading ? (
                <CustomSkeleton
                  customInnerStyle={{ marginBottom: "8px" }}
                  count={13}
                  height={25}
                />
              ) : (
                <>
                  <Card.Header>Ai Image details</Card.Header>
                  <Card.Body>
                    <div className="Aiimage-details">
                      <table className="table fs-6">
                        <tbody>
                          <tr>
                            <td className="fw-bold text-dark">
                              Ai Image Status{" "}
                            </td>
                            <td>{data?.ai_image_status}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">Room Name </td>
                            <td>{data?.room?.name}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Organization Name{" "}
                            </td>
                            <td>{data?.organization?.name}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Visitor Email{" "}
                            </td>
                            <td>{data?.visitor?.email}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Visitor Mobile Number
                            </td>
                            <td>{data?.visitor?.mobile_number}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Floor Tile Product Name
                            </td>
                            <td>{data?.floor_tile?.product_name}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Floor Tile Height{" "}
                            </td>
                            <td>{data?.floor_tile?.height}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Floor Tile Width{" "}
                            </td>
                            <td>{data?.floor_tile?.width}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Floor Percentage{" "}
                            </td>
                            <td>{data?.floor_percentage}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Floor Rotation Angle{" "}
                            </td>
                            <td>{data?.floor_rotation_angle}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Wall Tile Product Name
                            </td>
                            <td>{data?.wall_tile?.product_name}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Wall Tile Height{" "}
                            </td>
                            <td>{data?.wall_tile?.height}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Wall Tile Width{" "}
                            </td>
                            <td>{data?.wall_tile?.width}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Wall Percentage{" "}
                            </td>
                            <td>{data?.wall_percentage}</td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">Created Date </td>
                            <td>
                              {moment(data.created_at).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">Updated at </td>
                            <td>
                              {moment(data.updated_at).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">
                              Detected Coordinate Data
                            </td>
                            <td>
                              {moment(data.detected_coordinate_data).format(
                                "DD-MMM-YYYY",
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-bold text-dark">Deleted Date </td>
                            <td>
                              {moment(data.deleted_at).format("DD-MMM-YYYY")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </>
              )}
            </>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AiimagesView;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class ProductType extends Model<{
  title: string;
  description: string;
}> {
  public $resource(): string {
    return "product_types";
  }
}

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner, Image } from "react-bootstrap";
import { useOrganization } from "../../../Utils/OrganizationContext";
import { getToken } from "../../../Utils/Auth";
import { setToast } from "../../../Utils/Toast";
import Select from "react-select";
import useAxiosInstance from "../../../Utils/Headers";
import { Cookies } from "typescript-cookie";
import Skeleton from "react-loading-skeleton";
import { PencilFill } from "react-bootstrap-icons";
import { handleImageError } from "../../../Components/Noimage";

interface SettingsProps {
  id: number;
  OrganizationData: any;
  fetchOrganization: any;
}
interface CountryOption {
  value: number;
  label: string;
}
interface IndustryOption {
  value: number;
  label: string;
}
const Settings: React.FC<SettingsProps> = ({
  id,
  OrganizationData,
  fetchOrganization,
}) => {
  let { selectedOrganizationId }: any = useOrganization();
  const organizationIdFromCookie = Cookies.get("OrganizationIdForView");
  const SelectedAccount = Cookies.get("selectedAccount");
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState({
    address: "",
    balance: "",
    country_id: "",
    city: "",
    country: "",
    created_at: "",
    deleted_at: "",
    id: "",
    industries_type_id: "",
    industries_type: {
      title: "",
    },
    master_user_id: "",
    name: "",
    contact_numberpostal_code: "",
    state: "",
    tax_number: "",
    tax_type_id: "",
    updated_at: "",
    uuid: "",
    organiser_number: "",
    organiser_email: "",
    total_visitors: "",
    total_ai_image_generated: "",
    logo: "",
    catalog_front_page: "",
    catalog_last_page: "",
    email: "",
    theme_fg_color: "",
    theme_bg_color: "",
    front_short_description: "",
    organization_handle: "",
    postal_code: "",
    contact_number: "",
  });
  const [displayData, setDisplayData] = useState({
    address: "",
    balance: "",
    city: "",
    country_id: "",
    country: {
      name: "",
      id: "",
    },
    created_at: "",
    deleted_at: "",
    id: "",
    industries_type_id: "",
    industries_type: {
      title: "",
    },
    name: "",
    state: "",
    total_ai_image_generated: "",
    organization_handle: "",
    logo: "",
    email: "",
    theme_fg_color: "",
    theme_bg_color: "",
    front_short_description: "",
    postal_code: "",
    contact_number: "",
  });
  const [validationError, setValidationError] = useState({
    address: "",
    city: "",
    country: "",
    country_id: "",
    name: "",
    state: "",
    email: "",
    postal_code: "",
    organization_handle: "",
    contact_number: "",
    front_short_description: "",
    industries_type_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null,
  );
  const [countryOptions, setCountryOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState<IndustryOption[]>([]);
  const [selectedIndustry, setSelectedIndustry] =
    useState<IndustryOption | null>(null);
  const fallBackSrc = "/images/svg/noimage.svg";
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  // eslint-disable-next-line
  const [imageUploaded, setImageUploaded] = useState(false);

  function isEditable() {
    return organizationIdFromCookie || SelectedAccount;
  }
  const handleFileInputChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setSelectedImage(selectedFile);
  };
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    handleImageUpload();
    // eslint-disable-next-line
  }, [selectedImage]);

  const handleImageUpload = async () => {
    try {
      if (!selectedImage) {
        return;
      }

      const formData = new FormData();
      formData.append("uuid", data.uuid);
      formData.append("logo", selectedImage);

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/upload_logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setToast("Image uploaded successfully:", response.data.message);

      setImageUploaded(true);
    } catch (error: any) {
      console.error("Error uploading image:", error);
    }
  };
  useEffect(() => {
    if (OrganizationData) {
      setData(OrganizationData);
      setDisplayData({
        ...OrganizationData,
        country: {
          name: OrganizationData.country_name || "",
          id: OrganizationData.country_id || "",
        },
      });
    }
  }, [OrganizationData]);
  useEffect(() => {
    fetchCountry();
    fetchIndustries();
    // eslint-disable-next-line
  }, []);

  const fetchCountry = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/countries?limit=555`,
      );

      const countries = response.data.data.map((country: any) => ({
        value: country.id,
        label: country.name,
      }));

      setCountryOptions(countries);
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const fetchIndustries = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/industries_type?limit=555`,
      );

      const industries = response.data.data.map((industry: any) => ({
        value: industry.id,
        label: industry.title,
      }));

      setIndustryOptions(industries);
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };

  const handleCountryChange = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
    const countryId = selectedOption.value;
    setData((prevData) => ({
      ...prevData,
      country_id: countryId,
    }));
  };

  const handleIndustryChange = (selectedOption: any) => {
    setSelectedIndustry(selectedOption);
    const industryTypeId = selectedOption.value;
    setData((prevData) => ({
      ...prevData,
      industries_type_id: industryTypeId,
    }));
  };
  useEffect(() => {
    if (displayData.country_id && countryOptions.length > 0) {
      const defaultCountry = countryOptions.find(
        (option: any) => option.value === displayData.country_id,
      );
      if (defaultCountry) {
        setSelectedCountry(defaultCountry);
      }
    }
  }, [displayData.country_id, countryOptions]);

  useEffect(() => {
    if (displayData.industries_type_id && industryOptions.length > 0) {
      const defaultIndustry = industryOptions.find(
        (option: any) => option.value === displayData.industries_type_id,
      );
      if (defaultIndustry) {
        setSelectedIndustry(defaultIndustry);
      }
    }
  }, [displayData.industries_type_id, industryOptions]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: "none",
      backgroundColor: "#F9F9F9",
      height: "35px",
      minHeight: "42px",
    }),
  };
  const updateDisplayData = (newData: any) => {
    setDisplayData(newData);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleEditSubmit = async (e: any) => {
    e.preventDefault();

    if (!validateForm(data)) {
      return;
    }
    const payload = {
      country_id: data.country_id,
      industries_type_id: data.industries_type_id,
      address: data.address,
      city: data.city,
      name: data.name,
      state: data.state,
      theme_fg_color: data.theme_fg_color,
      theme_bg_color: data.theme_bg_color,
      front_short_description: data.front_short_description,
      postal_code: data.postal_code,
      contact_number: data.contact_number,
      email: data.email,
    };

    try {
      setLoading(true);
      // Submit form data using axios
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/organizations/${id}?organization_id=${selectedOrganizationId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200) {
        updateDisplayData(data);
        fetchOrganization();
        setToast("success", "Organization Successfully Updated.");
      } else {
        console.error("Error updating data");
        setToast("error", "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      setToast("error", "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (formData: any) => {
    let isValid = true;
    if (!formData.name) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        name: "Name is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        name: "",
      }));
    }

    if (!formData.address) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        address: "Address is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        address: "",
      }));
    }

    if (!formData.state) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        state: "State is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        state: "",
      }));
    }

    if (!formData.city) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        city: "City is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        city: "",
      }));
    }

    if (!formData.front_short_description) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        front_short_description: "Description is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        front_short_description: "",
      }));
    }
    // eslint-disable-next-line
    if (!formData.postal_code || formData.postal_code == 0) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        postal_code: "Postal Code is Required",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        postal_code: "",
      }));
    }

    if (!formData.contact_number) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        contact_number: "Mobile Number is Required",
      }));
      isValid = false;
    } else if (!isValidMobileNumber(formData.contact_number)) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        contact_number: "Mobile Number Must Be 6 Digits",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        contact_number: "",
      }));
    }

    if (!formData.email) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        email: "Email is Required",
      }));
      isValid = false;
    } else if (!isValidEmail(formData.email)) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        email: "Email is Not Valid",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }

    if (!formData.country_id) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        country_id: "Please Select a Country",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        country_id: "",
      }));
    }
    if (!formData.industries_type_id) {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        industries_type_id: "Please Select a Industry Type",
      }));
      isValid = false;
    } else {
      setValidationError((prevErrors) => ({
        ...prevErrors,
        industries_type_id: "",
      }));
    }

    return isValid;
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber: any) => {
    const mobileRegex = /^\d{6,}$/;
    return mobileRegex.test(mobileNumber);
  };
  return (
    <>
      <Card className="mt-5">
        <Card.Header className="align-items-center">
          <h1 className="m-0">Edit Organization Details</h1>
        </Card.Header>
        <Card.Body>
          <div className="edit-details d-flex justify-content-center align-items-center p-2 mb-5 shadow-lg rounded">
            {(organizationIdFromCookie || SelectedAccount) && (
              <div className="shadow catalog-page" onClick={handleUploadClick}>
                <input
                  type="file"
                  accept="image/*"
                  className="d-none"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
                <PencilFill className="text-secondary text-center mt-2" />
              </div>
            )}
            {data.logo ? (
              <Image
                className="img-fluid"
                src={data.logo}
                rounded
                onError={(e) =>
                  handleImageError(e, fallBackSrc, "100%", "100px")
                }
              />
            ) : (
              <Skeleton width={80} height={80} />
            )}
          </div>
          <form className="mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label htmlFor="name" className="fw-semibold lebal-font">
                    Organization Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={data.name}
                    className="form-control form-control-lg form-control-solid  mt-1"
                    placeholder="Enter Your Name"
                    onChange={handleInputChange}
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.name && (
                  <p className="text-danger">{validationError.name}</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label
                    htmlFor="Organization_Name"
                    className="fw-semibold lebal-font"
                  >
                    Organization Handle*
                  </label>
                  <input
                    type="text"
                    id="Organization_Name"
                    name="Organization_Name"
                    value={displayData.organization_handle}
                    disabled
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label
                    htmlFor="contact_number"
                    className="fw-semibold lebal-font"
                  >
                    Contact Number*
                  </label>
                  <input
                    type="tel"
                    id="contact_number"
                    name="contact_number"
                    value={data.contact_number}
                    onChange={handleInputChange}
                    placeholder="Enter Your Mobile Number"
                    className="mt-1 form-control form-control-lg form-control-solid"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.contact_number && (
                  <p className="text-danger">
                    {validationError.contact_number}
                  </p>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label htmlFor="email" className="fw-semibold lebal-font">
                    Email*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={data.email}
                    onChange={handleInputChange}
                    placeholder="Enter Your Email"
                    className="form-control form-control-lg form-control-solid mt-1"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.email && (
                  <p className="text-danger">{validationError.email}</p>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label
                    htmlFor="industry_type"
                    className="fw-semibold lebal-font"
                  >
                    Industry Type*
                  </label>
                  <Select
                    id="industry_type"
                    styles={customStyles}
                    name="industry_type"
                    value={selectedIndustry}
                    onChange={handleIndustryChange}
                    options={industryOptions}
                    isDisabled={!isEditable()}
                  />
                  {validationError.industries_type_id && (
                    <p className="text-danger">
                      {validationError.industries_type_id}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label htmlFor="address" className="fw-semibold lebal-font">
                    Address*
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={data.address}
                    onChange={handleInputChange}
                    placeholder="Enter Your Address"
                    className="form-control form-control-lg form-control-solid mt-1"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.address && (
                  <p className="text-danger">{validationError.address}</p>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label htmlFor="city" className="fw-semibold lebal-font">
                    City*
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={data.city}
                    placeholder="Enter Your City"
                    onChange={handleInputChange}
                    className="form-control form-control-lg form-control-solid mt-1"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.city && (
                  <p className="text-danger">{validationError.city}</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label htmlFor="state" className="fw-semibold lebal-font">
                    State*
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={data.state}
                    placeholder="Enter Your State"
                    onChange={handleInputChange}
                    className="form-control form-control-lg form-control-solid mt-1"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.state && (
                  <p className="text-danger">{validationError.state}</p>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label
                    htmlFor="country"
                    className="fw-semibold lebal-font mb-1"
                  >
                    Country*
                  </label>
                  <Select
                    id="country"
                    styles={customStyles}
                    name="country"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    options={countryOptions}
                    isDisabled={!isEditable()}
                  />
                  {validationError.country_id && (
                    <p className="text-danger">{validationError.country_id}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6">
                  <label
                    htmlFor="postal_code"
                    className="fw-semibold lebal-font"
                  >
                    Postal Code*
                  </label>
                  <input
                    type="number"
                    id="postal_code"
                    name="postal_code"
                    value={data.postal_code}
                    placeholder="Enter Your Postal Code"
                    onChange={handleInputChange}
                    className="form-control form-control-lg form-control-solid mt-1"
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.postal_code && (
                  <p className="text-danger">{validationError.postal_code}</p>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group fs-6">
                      <label
                        htmlFor="theme_fg_color"
                        className="fw-semibold lebal-font"
                      >
                        Foreground Color
                      </label>
                      <div className="d-flex align-items-center mt-1 p-1 bg-white rounded-2">
                        <input
                          type="color"
                          id="theme_fg_color"
                          name="theme_fg_color"
                          value={data.theme_fg_color}
                          onChange={handleInputChange}
                          className="p-0 form-control color-box  bg-fg-color"
                          disabled={!isEditable()}
                        />
                        <input
                          type="text"
                          className="form-control border-0"
                          value={data.theme_fg_color}
                          onChange={handleInputChange}
                          readOnly={!isEditable()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group fs-6">
                      <label
                        htmlFor="theme_bg_color"
                        className="fw-semibold lebal-font"
                      >
                        Background Color
                      </label>
                      <div className="d-flex align-items-center mt-1 p-1 bg-white rounded-2">
                        <input
                          type="color"
                          id="theme_bg_color"
                          name="theme_bg_color"
                          value={data.theme_bg_color}
                          onChange={handleInputChange}
                          className="p-0 form-control color-box"
                          disabled={!isEditable()}
                        />
                        <input
                          type="text"
                          className="form-control border-0"
                          value={data.theme_bg_color}
                          onChange={handleInputChange}
                          readOnly={!isEditable()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group fs-6 mt-1">
                  <label
                    htmlFor="front_short_description"
                    className="fw-semibold lebal-font"
                  >
                    Description*
                  </label>
                  <textarea
                    id="front_short_description"
                    name="front_short_description"
                    value={data.front_short_description}
                    onChange={handleInputChange}
                    className="form-control form-control-lg form-control-solid mt-1 height-42-px"
                    placeholder="Description here"
                    rows={4}
                    readOnly={!isEditable()}
                  />
                </div>
                {validationError.front_short_description && (
                  <p className="text-danger">
                    {validationError.front_short_description}
                  </p>
                )}
              </div>
            </div>
          </form>
          {(organizationIdFromCookie || SelectedAccount) && (
            <div className="float-end mt-5">
              <button
                onClick={() => setData(OrganizationData)}
                className="btn back-btn btn-sm"
              >
                Discard
              </button>
              <button
                type="submit"
                onClick={handleEditSubmit}
                className="btn addnew-btn ms-3 btn-sm"
                disabled={loading}
              >
                {loading ? (
                  <span className="mx-2">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Settings;

import React from "react";
import Select, { OnChangeValue } from "react-select";

interface OptionType {
  label: string;
  value: string;
}

interface SelectFieldProps {
  options: OptionType[];
  value: OptionType | null;
  onChange: (selectedOption: OnChangeValue<OptionType, false>) => void;
  error?: string;
}
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: "none",
    backgroundColor: "#F9F9F9",
    height: "35px",
    minHeight: "42px",
  }),
};
const SelectField: React.FC<SelectFieldProps> = ({
  options,
  value,
  onChange,
  error,
}) => (
  <div className="form-group ">
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      className={error ? "is-invalid select" : "select"}
      isClearable
    />
    {error && <small className="text-danger">{error}</small>}
  </div>
);

export default SelectField;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import NavLink from "react-bootstrap/NavLink";
import NavItem from "react-bootstrap/NavItem";
import Image from "react-bootstrap/Image";
import { getUser } from "../Utils/Auth";
import { useEffect, useState } from "react";
import { INDUSTRIES_TYPES_LIST } from "../Routes/industriesTypesRoutes";
import { NOTIFICATION_TEMPLATE_LIST } from "../Routes/notificationTemplateRoutes";
import { COUNTRY_LIST } from "../Routes/countryRoutes";
import { PROFILE_EDIT } from "../Routes/EditProfileRoutes";
import {
  ORGANIZATION_LIST,
  ORGANIZATION_VIEW,
} from "../Routes/organizationRoutes";
import { PERMISSIONS_LIST } from "../Routes/Permission";
import { PRODUCT_LIST } from "../Routes/ProductsRoutes";
import { PRODUCT_TYPE_LIST } from "../Routes/ProductTypesRoutes";
import { ROLES_LIST } from "../Routes/Role";
import { TAX_TYPES_LIST } from "../Routes/taxTypesRoutes";
import { TILE_COVERAGES_LIST } from "../Routes/TileCoveragesRoutes";
import { VISITOR_LIST } from "../Routes/visitorRoutes";
import { LOGOUT } from "../Routes/AppRoutes";
import OrganizationDropdown from "./OrganizationDropdown";
import { DASHBOARD_ROUTE } from "../Routes/DashboardRoute";
import { useOrganization } from "../Utils/OrganizationContext";
import { getUserRolesFromCookie } from "../Utils/GetRoles";
import { PRODUCT_COLOR_LIST } from "../Routes/ProductColorRoutes";
import { useNotification } from "../Utils/NotificationContext";
import { BoxArrowRight, CardText, Person } from "react-bootstrap-icons";
import { useUser } from "../Utils/UserContext";

interface UserData {
  name: string;
  roles: string[];
}

function NavBarLayout() {
  let { selectedOrganizationId, organizationId }: any = useOrganization();
  const [users, setUsers] = useState<UserData | undefined>(undefined);
  const { user } = useUser();
  const userRoles = getUserRolesFromCookie();
  const [showButton, setShowButton] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isNotificationHovered, setIsNotificationHovered] = useState(false);
  const name = user?.name || users?.name;
  const { hasUnreadNotifications, fetchUnreadNotifications } =
    useNotification();

  useEffect(() => {
    fetchUnreadNotifications();
    // eslint-disable-next-line
  }, [selectedOrganizationId, organizationId]);

  useEffect(() => {
    if (userRoles) {
      setShowButton(userRoles.some((role: string) => role === "ROLE_ADMIN"));
      setIsSuperAdmin(
        userRoles.some((role: string) => role === "ROLE_SUPER_ADMIN"),
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchedUserData = getUser() as UserData | undefined;
    if (fetchedUserData) {
      setUsers(fetchedUserData);
    }
  }, []);

  const selectorga = selectedOrganizationId || organizationId;

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line
  }, [selectorga]);

  const getProfileData = async () => {
    try {
      const fetchedUser = getUser() as UserData | undefined;
      if (fetchedUser) {
        setUsers({ ...fetchedUser, roles: userRoles as any });
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const organizationViewPath = ORGANIZATION_VIEW.replace(":id", selectorga);

  const getDashboardPath = () => {
    if (isSuperAdmin) {
      return "/dashboard";
    } else if (showButton && selectedOrganizationId) {
      return `/${selectedOrganizationId}/dashboard`;
    }
    return "/";
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="border-bottom pb-2 pt-2 bg-white"
    >
      <Container fluid className="fw-semibold">
        <Navbar.Brand as={Link} to={getDashboardPath()}>
          <img
            src="/images/Space.ai logo.png"
            width="130"
            height="60"
            className="d-inline-block align-top ps-2"
            alt="Space Ai"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="ml-4">
          <Nav className="justify-content-end ms-auto navbar-nav fs-6">
            <Nav.Link as={Link} to={DASHBOARD_ROUTE} className="nav-font">
              Dashboard
            </Nav.Link>
            {isSuperAdmin ? (
              <Nav.Link
                as={Link}
                className="nav-font"
                to={
                  selectedOrganizationId
                    ? `${ORGANIZATION_LIST}/${ORGANIZATION_VIEW.replace(
                        ":id",
                        selectedOrganizationId,
                      )}`
                    : ORGANIZATION_LIST
                }
              >
                Organization
              </Nav.Link>
            ) : (
              <Nav.Link
                as={Link}
                className="nav-font"
                to={`${ORGANIZATION_LIST}/${organizationViewPath}`}
              >
                Organization
              </Nav.Link>
            )}
            <Nav.Link as={Link} to={VISITOR_LIST} className="nav-font">
              Visitor
            </Nav.Link>

            <NavDropdown
              title="Content"
              id="basic-nav-dropdown"
              className="content-mega-menu-outer"
            >
              <div className="content-mega-menu-inner rounded p-3">
                <div className="mt-1 mx-3">
                  <h3 className="dropdown-header">Product</h3>
                  <NavDropdown.Item
                    as={Link}
                    to={PRODUCT_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Products
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={PRODUCT_COLOR_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Product Color
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={TILE_COVERAGES_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Tile Coverages
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={NOTIFICATION_TEMPLATE_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Notification Template
                  </NavDropdown.Item>
                </div>
                <div className="mt-1 mx-3">
                  <h4 className="dropdown-header">Type</h4>
                  <NavDropdown.Item
                    as={Link}
                    to={PRODUCT_TYPE_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Products Type
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={TAX_TYPES_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Tax Type
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={INDUSTRIES_TYPES_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Industries Type
                  </NavDropdown.Item>
                </div>
              </div>
            </NavDropdown>

            {showButton && (
              <NavDropdown
                title="Settings"
                id="settings-nav-dropdown"
                className="nav-font"
              >
                <div className="settings-dropdown p-3">
                  <NavDropdown.Item
                    as={Link}
                    to={ROLES_LIST}
                    className="dotted-list-item"
                  >
                    Role
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={PERMISSIONS_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Permission
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={COUNTRY_LIST}
                    className="dotted-list-item mt-2"
                  >
                    Country
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
            )}
          </Nav>

          <Nav className="d-flex justify-content-end ms-auto navbar-nav">
            {showButton && <OrganizationDropdown />}
            <Nav.Link
              as={Link}
              to={
                selectedOrganizationId
                  ? `/${selectedOrganizationId}/notifications`
                  : "/notifications"
              }
              className="p-0 d-flex"
              onMouseEnter={() => setIsNotificationHovered(true)}
              onMouseLeave={() => setIsNotificationHovered(false)}
            >
              <Image
                title="Notification"
                className="notification m-1"
                src={
                  isNotificationHovered
                    ? "/images/svg/notification_blue.svg"
                    : "/images/svg/notification_icon.svg"
                }
              />
              {hasUnreadNotifications.length > 0 && (
                <span className="notification-dot"></span>
              )}
            </Nav.Link>
            <Dropdown as={NavItem} align={{ lg: "end" }} className="p-2">
              <div className="rounded profile">
                <Dropdown.Toggle as={NavLink} className="p-0 text-white fs-3">
                  <Image
                    width={33}
                    height={33}
                    src={`https://ui-avatars.com/api/?background=0d6efd&color=ffffff&name=${
                      name || "Default"
                    }`}
                    roundedCircle
                  />
                </Dropdown.Toggle>
              </div>
              <Dropdown.Menu className="p-3 profile-dropdown">
                <Dropdown.Item title={name} className="nav-font d-flex ps-1">
                  <span>
                    <div>
                      <Image
                        width={22}
                        height={22}
                        src={`https://ui-avatars.com/api/?background=0d6efd&color=ffffff&name=${
                          name || "Default"
                        }`}
                        roundedCircle
                        className="me-2"
                      />
                    </div>
                  </span>
                  <span>{name}</span>
                </Dropdown.Item>
                <Dropdown.Divider className="mt-2" />
                <Dropdown.Item
                  as={Link}
                  to={PROFILE_EDIT}
                  className="mt-2 ps-1"
                >
                  <Person size={18} className="me-1" /> Profile
                </Dropdown.Item>
                <Dropdown.Divider className="mt-2" />
                <Dropdown.Item className="mt-2 ps-1">
                  <CardText size={16} className="me-1" /> Billing
                </Dropdown.Item>
                <Dropdown.Divider className="mt-2" />
                <Dropdown.Item href={LOGOUT} className="mt-2 ps-1  text-danger">
                  <BoxArrowRight size={18} className="me-2 text-danger" />
                  LogOut
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarLayout;

import { Model } from "@tailflow/laravel-orion/lib/model";

export class Organizations extends Model<{
  name: string;
  organization_id: number;
}> {
  public $resource(): string {
    return "organizations";
  }
}

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { setToast } from "../../Utils/Toast";
import Select from "react-select";
import useAxiosInstance from "../../Utils/Headers";
import { Container, Card } from "react-bootstrap";

const CampaignDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({
    title: "",
    organization_id: "",
    track_id: "",
    iframe_id: "",
    creadte_at: "",
    owner_id: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [ownerIdOptions, setOwnerIdOptions] = useState([]);
  const [selectedOwnerId, setSelectedOwnerId] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    generateURL();
  };

  const generateURL = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/campaigns/${id}`,
      );
      setDetailsData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    generateURL();
    // eslint-disable-next-line
  }, []);
  const fetchOwnerIdOptions = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/users`,
      );
      const options = response.data.data.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setOwnerIdOptions(options);
    } catch (error) {
      console.error("Error fetching owner IDs:", error);
    }
  };
  useEffect(() => {
    fetchOwnerIdOptions();
    // eslint-disable-next-line
  }, []);

  const handleCampaignEditSubmit = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_API_URL}api/campaigns/${id}`,
        {
          title: detailsData.title,
          owner_id: selectedOwnerId,
        },
      );
      if (response.ok) {
        setShowModal(false);
        generateURL();
        setToast("success", "Campaign Updated Successfully");
      } else {
        console.error("Error updating data");
        setToast("error", "Something went wrong");
      }
    } catch (error) {
      setToast("error", "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  const handleTitleChange = (e) => {
    setDetailsData({ ...detailsData, title: e.target.value });
  };

  return (
    <>
      <Container className="mt-5">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            Campaign Details
          </Card.Header>
          <Card.Body>
            <table className="table">
              <tbody className="">
                <tr>
                  <td className="">
                    <strong>Title</strong>
                  </td>
                  <td className="">{detailsData.title}</td>
                  <td className="">
                    <strong>Organization Id</strong>
                  </td>
                  <td className="">{detailsData.organization_id}</td>
                </tr>

                <tr>
                  <td className="">
                    <strong>Created At</strong>
                  </td>
                  <td className="">
                    {moment(detailsData.creadte_at).format("DD-MM-YYYY")}
                  </td>
                  <td className="">
                    <strong>Updated At</strong>
                  </td>
                  <td className="">
                    {moment(detailsData.updated_at).format("DD-MM-YYYY")}
                  </td>
                </tr>
                <tr>
                  <td className="">
                    <strong>Track Id</strong>
                  </td>
                  <td className="">{detailsData.track_id}</td>
                </tr>
              </tbody>
            </table>
          </Card.Body>
          <Card.Footer>
            <div className="gap-2 d-flex justify-content-end">
              <button
                type="button"
                className="btn back-btn btn-sm"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </button>
              <button className="btn btn-sm edit-btn" onClick={openModal}>
                Edit
              </button>
            </div>
          </Card.Footer>
        </Card>
      </Container>
      <Modal centered show={showModal} onHide={closeModal}>
        <Modal.Header className="border-0 d-flex justify-content-between align-items-center">
          <Modal.Title>Campaign Edit</Modal.Title>
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon mb-4"
            onClick={closeModal}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="editTitle" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              id="editTitle"
              value={detailsData.title}
              onChange={handleTitleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="ownerId" className="form-label">
              Select Owner
            </label>
            <Select
              id="ownerId"
              options={ownerIdOptions}
              onChange={(selectedOption) =>
                setSelectedOwnerId(selectedOption.value)
              }
              value={ownerIdOptions.find(
                (option) => option.value === selectedOwnerId,
              )}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            type="submit"
            className="btn addnew-btn btn-sm"
            onClick={handleCampaignEditSubmit}
          >
            {loading ? (
              <Spinner
                className=""
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignDetails;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCookie } from "typescript-cookie";

type OrganizationContextType = {
  selectedOrganizationId: string | null;
  organizationId: string | null;
  setSelectedOrganizationId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setOrganizationId: React.Dispatch<React.SetStateAction<string | null>>;
};

const OrganizationContext = createContext<OrganizationContextType | undefined>(
  undefined,
);

export function useOrganization() {
  return useContext(OrganizationContext);
}

export function OrganizationProvider({ children }: any) {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | null
  >(null);

  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const location = useLocation();
  const [isOrganizationPage, setIsOrganizationPage] = useState(false);
  const [organizationView, setOrganizationView] = useState<string | null>(null);

  useEffect(() => {
    const pathMatches = location.pathname.match(
      /^\/organization\/(\d+)\/view$/,
    );
    if (pathMatches) {
      const extractedId = pathMatches[1];
      setOrganizationView(extractedId);
      setIsOrganizationPage(true);
    } else {
      setOrganizationView(null);
      setIsOrganizationPage(false);
    }
  }, [location.pathname]);

  const orgId = getCookie("organizationId");
  useEffect(() => {
    const org = getCookie("selectedAccount");
    if (org) setSelectedOrganizationId(org);
    if (isOrganizationPage && organizationView) {
      setOrganizationId(organizationView);
    } else if (orgId) {
      setOrganizationId(orgId);
    }
  }, [isOrganizationPage, organizationId, organizationView, orgId]);

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganizationId,
        organizationId,
        setSelectedOrganizationId,
        setOrganizationId,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

import { Model } from "@tailflow/laravel-orion/lib/model";

export class TileCoverages extends Model<{
  width: Number;
  height: Number;
  coverage_percentage: Number;
}> {
  static fetch() {
    throw new Error("Method not implemented.");
  }
  public $resource(): string {
    return "tiles_size";
  }
}

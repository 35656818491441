import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircle, Trash3, Eye } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Skeleton from "react-loading-skeleton";
import { useOrganization } from "../../Utils/OrganizationContext";
import { useNotification } from "../../Utils/NotificationContext";
import useAxiosInstance from "../../Utils/Headers";
import { ORGANIZATION_VISITOR_VIEW } from "../../Routes/visitorRoutes";

const greenTextStyle = {
  background: "#F2F6FC",
};

interface Notification {
  id: number;
  compareStatus: boolean;
  data: {
    message: string;
    email: string;
    mobile_number: number;
    visitorId: number;
  };
  created_at: string;
  read_at: Date | null;
}

interface AllNotificationsProps {
  onMarkAsRead: (notificationId: number) => void;
  onDelete: (notificationId: number) => void;
}

const AllNotifications: React.FC<AllNotificationsProps> = ({
  onMarkAsRead,
  onDelete,
}) => {
  const { selectedOrganizationId, organizationId }: any = useOrganization();
  const { updateUnreadNotifications } = useNotification();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const axiosInstance = useAxiosInstance();

  const fetchNotifications = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/search?organization_id=${
          selectedOrganizationId || organizationId
        }&page=${page}`,
        {
          sort: [{ field: "created_at", direction: "desc" }],
        },
      );
      const fetchedNotifications: Notification[] = response.data.data;
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...fetchedNotifications,
      ]);
      setHasMore(fetchedNotifications.length > 0);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, []);

  const handleMarkNotificationAsRead = async (notificationId: number) => {
    try {
      await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL
        }api/notification_read/${notificationId}?organization_id=${
          selectedOrganizationId || organizationId
        }`,
      );
      onMarkAsRead(notificationId);
      updateUnreadNotifications();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleDeleteNotification = async (notificationId: number) => {
    try {
      await axiosInstance.delete(
        `${
          process.env.REACT_APP_API_URL
        }api/notifications/${notificationId}?organization_id=${
          selectedOrganizationId || organizationId
        }`,
      );
      onDelete(notificationId);
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.id !== notificationId,
        ),
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const loadMoreNotifications = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchNotifications(nextPage);
  };

  return (
    <div className="p-0">
      {isLoading && currentPage === 1 ? (
        <Skeleton count={5} height={80} />
      ) : notifications.length === 0 ? (
        <div className="text-center">
          <img
            src="/images/svg/No_data_available.svg"
            alt="No data available"
          />
        </div>
      ) : (
        <>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              style={!notification.read_at ? greenTextStyle : {}}
              className="border d-flex justify-content-between align-items-center p-2 ps-4"
            >
              <div className="d-flex pt-2 ms-3">
                <h4>
                  {notification.data.email}
                  {notification.data.mobile_number}
                </h4>
                <h6 className="text-secondary ms-4 mt-1">
                  {notification.data.message}
                </h6>
              </div>
              <div className="button-container d-flex">
                {!notification.read_at && (
                  <Button
                    variant="success-light"
                    className="btn btn-sm"
                    onClick={() =>
                      handleMarkNotificationAsRead(notification.id)
                    }
                  >
                    <CheckCircle size={15} /> Read
                  </Button>
                )}

                <Link
                  to={ORGANIZATION_VISITOR_VIEW.replace(
                    ":id",
                    notification.data.visitorId?.toString(),
                  )}
                >
                  <Button
                    variant="primary-light"
                    className="btn btn-sm fw-bold ms-2"
                  >
                    <Eye size={15} /> View
                  </Button>
                </Link>

                <Button
                  variant="danger-light"
                  className="btn-sm ms-2 fw-bold me-2"
                  onClick={() => handleDeleteNotification(notification.id)}
                >
                  <Trash3 size={14} /> Delete
                </Button>
              </div>
            </div>
          ))}

          {hasMore && !isLoading && (
            <div className="text-center mt-4 mb-5">
              <button
                className="btn addnew-btn btn-sm"
                onClick={loadMoreNotifications}
              >
                Load More
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllNotifications;

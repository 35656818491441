import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Users } from "../../Models/Users";
import moment from "moment";
import { Button, Card, Modal } from "react-bootstrap";
import { setToast } from "../../Utils/Toast";
import Select from "react-select";
import useAxiosInstance from "../../Utils/Headers";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";

const SubUserView = () => {
  // eslint-disable-next-line
  const { usersID, OrganizationsId }: any = useParams();
  const navigate = useNavigate();
  const [Roles, setRoles] = useState<any[]>([]);
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    balance: "",
    email_verified_at: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    is_blocked: {},
    parent_user_id: "",
    organization_id: "",
  });
  const [showSubUserEditModal, setshowSubUserEditModal] = useState(false);
  const [showRoleAssignModal, setshowRoleAssignModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [actionType, setActionType] = useState<"block" | "unblock">();
  const fetchSubUser = async () => {
    const SubUser = await Users.$query().find(usersID);
    setData(SubUser.$response?.data.data);
  };

  useEffect(() => {
    fetchSubUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubUserEditSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateSubUserForm(data)) {
      return;
    }
    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}api/sub_user/${usersID}`,
        data,
      );

      if (response.status === 200) {
        setshowSubUserEditModal(false);
        setToast("success", "Sub User Updated Successfully");
        fetchSubUser();
      } else {
        console.error("Error updating data");
      }
    } catch (error) {
      console.error(error);
      setToast("error", "Something went wrong");
    }
  };

  const handleCloseSubUserEditModal = (e: any) => {
    e.preventDefault();
    setshowSubUserEditModal(false);
  };

  const validateSubUserForm = (formSubUserData: any) => {
    let isValid = true;
    if (!formSubUserData.name) {
      setToast("error", "Name is required");
      isValid = false;
    }

    if (!formSubUserData.email) {
      setToast("error", "Email is required");
      isValid = false;
    } else if (!isValidEmail(formSubUserData.email)) {
      setToast("error", "Email is not valid");
      isValid = false;
    }
    if (!formSubUserData.mobile_no) {
      setToast("error", "mobile_no is required");
      isValid = false;
    } else if (!isValidMobileNumber(formSubUserData.mobile_no)) {
      setToast("error", "mobile_no must be 10 digits");
      isValid = false;
    }
    return isValid;
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobile_no: any) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile_no);
  };

  const handleSubUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    handleRoleAssign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRoleAssign = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/roles`,
      );

      const rolesData = response.data.data;
      const rolesOptions = rolesData.map((role: any) => ({
        value: role.id,
        label: role.name,
      }));

      setshowRoleAssignModal(false);
      setRoles(rolesOptions);
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };

  const handleSubmitRoleAssign = async () => {
    try {
      if (userRoles.length === 0) {
        setToast("error", "Please select Assign role");
        return;
      }
      const response = await axiosInstance.patch(
        `${process.env.REACT_APP_API_URL}api/users/${usersID}/roles/sync`,
        {
          resources: userRoles.map((role) => role.value),
        },
      );

      if (response.status === 200) {
        setToast("success", "Role assigned successfully");
        setshowRoleAssignModal(false);
      } else {
        console.error("Error assigning role");
        setToast("error", "Error assigning role");
      }
    } catch (error) {
      console.error(error, "Something went wrong");
      setToast("error", "Something went wrong");
    }
  };

  const handleCloseRoleAssign = (e: any) => {
    e.preventDefault();
    setshowRoleAssignModal(false);
  };

  const [userRoles, setUserRoles] = useState<any[]>([]);

  const handleOptionChange = async (selected: any) => {
    if (selected && selected.length > 0) {
      const selectedValues = selected.map((item: any) => ({
        value: item.value,
        label: item.label,
      }));
      setUserRoles(selectedValues);
    } else {
      setUserRoles([]);
    }
  };

  const handleSelectRole = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/users/${usersID}/roles`,
      );
      const selectedRole = response.data.data;
      const selectedRoleOptions = selectedRole.map((roles: any) => ({
        value: roles.id,
        label: roles.name,
      }));
      setUserRoles(selectedRoleOptions);
    } catch (error) {
      console.error(error, "Something went wrong");
    }
  };
  const handleBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/user/${id}/block`,
      );
      setToast("success", response.data.message);
      fetchSubUser();
    } catch (error: any) {
      setToast("error", error);
    }
  };

  const handleUnBlockUser = async (id: number) => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/user/${id}/unblock`,
      );
      setToast("success", response.data.message);
      fetchSubUser();
    } catch (error: any) {
      setToast("error", error);
    }
  };
  const handleActionClick = (userId: number, action: "block" | "unblock") => {
    setSelectedUserId(userId);
    setActionType(action);
    setShowModal(true);
  };

  const handleConfirmAction = () => {
    if (selectedUserId && actionType) {
      if (actionType === "block") {
        handleBlockUser(selectedUserId);
      } else {
        handleUnBlockUser(selectedUserId);
      }
    }
    setShowModal(false);
  };

  return (
    <div className="container-fluid h-100">
      <div className="text-end">
        <button
          type="button"
          className="btn back-btn btn-sm mb-3"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
      </div>
      <div className="row  h-100">
        <div className="col-md-12 col-sm-12 col-xxl-12 col-xl-12 col-lg-12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              SubUser Details
              <div>
                <button
                  className="btn edit-btn ms-2 btn-sm"
                  title="edit Sub User"
                  onClick={() => setshowSubUserEditModal(true)}
                >
                  Edit
                </button>
                <button
                  className="btn ms-2 addnew-btn btn-sm"
                  title="Role Assign"
                  onClick={() => {
                    setshowRoleAssignModal(true);
                    handleSelectRole();
                  }}
                >
                  Role Assign
                </button>
                {data.is_blocked === 0 ? (
                  <Button
                    variant="danger-light"
                    size="sm"
                    className="ms-2 btn-sm btn-font"
                    style={{ width: "99px" }}
                    onClick={() => handleActionClick(usersID, "block")}
                  >
                    <MdBlock className="mb-1" /> Block
                  </Button>
                ) : (
                  <Button
                    variant="success-light"
                    size="sm"
                    className="ms-2 btn-sm btn-font"
                    onClick={() => handleActionClick(usersID, "unblock")}
                  >
                    <CgUnblock className="mb-1" /> UnBlock
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body className="font">
              <div className="row fs-6">
                <div className="col-md-3">
                  <strong className="">Name</strong>
                  <p> {data?.name}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Email</strong>
                  <p> {data?.email}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Mobile No</strong>
                  <p> {data?.mobile_no}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Balance</strong>
                  <p> {data?.balance}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Email Verified At</strong>
                  <p>{data?.email_verified_at}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Parent User Id</strong>
                  <p> {data?.parent_user_id}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Organization Id</strong>
                  <p>{data?.organization_id}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Created At</strong>
                  <p>{moment(data.created_at).format("DD-MMM-YYYY")}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Updated At</strong>
                  <p>{moment(data.updated_at).format("DD-MMM-YYYY")}</p>
                </div>
                <div className="col-md-3">
                  <strong className="">Deleted At</strong>
                  <p>{moment(data.deleted_at).format("DD-MMM-YYYY")}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="container">
          <Modal
            show={showRoleAssignModal}
            dialogClassName="custom-modal"
            centered
            className="mt-4"
          >
            <Modal.Header>
              <Modal.Title>Role Assign</Modal.Title>
            </Modal.Header>
            <Modal.Body className="subuserview-modal">
              <Select
                isMulti
                isClearable
                options={Roles}
                isSearchable
                placeholder="Select Role Assign..."
                onChange={handleOptionChange}
                value={userRoles}
              />
            </Modal.Body>
            <Modal.Footer className="border-0 d-flex justify-content-between">
              <button
                className="btn back-btn btn-sm mb-3"
                onClick={handleCloseRoleAssign}
              >
                Back
              </button>
              <div>
                <button
                  type="submit"
                  className="btn btn-sm text-white btn-success"
                  onClick={handleSubmitRoleAssign}
                >
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <Modal
        show={showSubUserEditModal}
        dialogClassName="custom-modal"
        centered
        className="mt-4"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Edit Sub User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="">
            <div className="form-group mb-3">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={data.name}
                className="form-control form-control-lg form-control-solid"
                onChange={handleSubUserInputChange}
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="mobile_no">Mobile Number:</label>
              <input
                type="tel"
                id="mobile_no"
                name="mobile_no"
                value={data.mobile_no}
                onChange={handleSubUserInputChange}
                className="form-control form-control-lg form-control-solid"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={data.email}
                onChange={handleSubUserInputChange}
                className="form-control form-control-lg form-control-solid"
              />
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="border-0 d-flex justify-content-between">
          <button
            className="btn back-btn btn-sm  mb-3"
            onClick={handleCloseSubUserEditModal}
          >
            Back
          </button>
          <button
            type="submit"
            className="btn btn-sm text-white btn-success"
            onClick={handleSubUserEditSubmit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className="border-0 mb-0 d-flex justify-content-end">
          <img
            src="/images/svg/closeicon.svg"
            alt="close"
            className="custom-close-icon"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="text-center p-1">
          <div>
            <h3>Confirm Action</h3>
            <h5> Are you sure you want to {actionType} this user?</h5>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-1 mb-4 d-flex justify-content-center">
          <button
            className="btn addnew-btn btn-sm"
            onClick={handleConfirmAction}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubUserView;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../Utils/Toast";
import { Organizations } from "../../Models/Organizations";
import axios from "axios";
import { LOGOUT } from "../../Routes/AppRoutes";
import { Container, Form, Row, Col, Card } from "react-bootstrap";
import useAxiosInstance from "../../Utils/Headers";
import SelectField from "../../Components/AsyncSelect";

function ProductImportFile({ closeModal }: any) {
  const [organizations, setOrganizations] = useState<any>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any | null>(null);
  const [productType, setProductType] = useState<any[]>([]);
  const [productCategory, setProductCategory] = useState<any[]>([]);
  const [productSize, setProductSize] = useState<any[]>([]);
  const [productColor, setProductColor] = useState<any[]>([]);
  const [selectedColor, setSelectedColor] = useState<any | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
  const [selectedProductType, setSelectedProductType] = useState<any | null>(
    null,
  );
  const [selectedProductSize, setSelectedProductSize] = useState<any | null>(
    null,
  );
  const [fileValues, setFileValues] = useState<File[]>([]);
  const [isForFloor, setIsForFloor] = useState(false);
  const [isForWall, setIsForWall] = useState(false);
  const [productTypeError, setProductTypeError] = useState("");
  const [productCategoryError, setProductCategoryError] = useState("");
  const [productSizeError, setProductSizeError] = useState("");
  const [productColorError, setProductColorError] = useState("");
  const [organizationsError, setOrganizationsError] = useState<any>();
  const [fileError, setFileError] = useState("");
  const [floorWallError, setFloorWallError] = useState("");

  const axiosInstance = useAxiosInstance();

  const fetchOrganizations = async () => {
    try {
      const posts: any = await Organizations.$query().lookFor("").search();
      const formattedOrganizations = posts.map((org: any) => ({
        value: org.$attributes.id.toString(),
        label: org.$attributes.name.toString(),
      }));
      setOrganizations(formattedOrganizations);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            navigate(LOGOUT);
          }
        }
      }
    }
  };

  const fetchProductColors = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/product_colors`,
      );
      const formattedColors = response.data.data.map((color: any) => ({
        value: color.id,
        label: color.title,
      }));
      setProductColor(formattedColors);
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchProductCategories = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/product_categories`,
      );
      const formattedCategories = response.data.data.map((category: any) => ({
        value: category.id,
        label: category.title,
      }));
      setProductCategory(formattedCategories);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  const fetchProductType = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/product_types`,
      );
      const formattedProductType = response.data.data.map(
        (productype: any) => ({
          value: productype.id,
          label: productype.title,
        }),
      );
      setProductType(formattedProductType);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const fetchProductSize = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}api/tiles_size`,
      );
      const formattedProductSize = response.data.data.map(
        (productsize: any) => ({
          value: productsize.id,
          label: `${productsize.width} * ${productsize.height}`,
        }),
      );
      setProductSize(formattedProductSize);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    fetchProductColors();
    fetchProductCategories();
    fetchProductType();
    fetchProductSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColorChange = (selected: any | null) => {
    setSelectedColor(selected);
  };

  const handleCategoryChange = (selected: any | null) => {
    setSelectedCategory(selected);
  };

  const handleProductTypeChange = (selected: any | null) => {
    setSelectedProductType(selected);
  };

  const handleProductSizeChange = (selected: any | null) => {
    setSelectedProductSize(selected);
  };

  const handleFloorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsForFloor(event.target.checked);
  };

  const handleWallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsForWall(event.target.checked);
  };

  const validateForm = () => {
    let isValid = true;
    if (!selectedOptions?.value) {
      setOrganizationsError("Please select an organization.");
      isValid = false;
    } else {
      setOrganizationsError("");
    }
    if (!selectedProductType) {
      setProductTypeError("Please select a product type.");
      isValid = false;
    } else {
      setProductTypeError("");
    }
    if (!selectedCategory) {
      setProductCategoryError("Please select a product category.");
      isValid = false;
    } else {
      setProductCategoryError("");
    }
    if (!selectedProductSize) {
      setProductSizeError("Please select a product size.");
      isValid = false;
    } else {
      setProductSizeError("");
    }
    if (!selectedColor) {
      setProductColorError("Please select a product color.");
      isValid = false;
    } else {
      setProductColorError("");
    }
    if (fileValues.length === 0) {
      setFileError("Please upload at least one image.");
      isValid = false;
    } else {
      setFileError("");
    }
    if (isForFloor === false && isForWall === false) {
      setFloorWallError("Please select at least one option (Floor or Wall).");
      isValid = false;
    } else {
      setFloorWallError("");
    }
    return isValid;
  };

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setLoading(true);
      if (!validateForm()) {
        setLoading(false);
        return;
      }
      const formData = new FormData();
      formData.append("organization_id", selectedOptions!.value);
      formData.append("product_type_id", selectedProductType!.value);
      formData.append("product_category_id", selectedCategory!.value);
      formData.append("tile_size_id", selectedProductSize!.value);
      formData.append("product_color_id", selectedColor!.value);
      formData.append("is_for_floor", isForFloor ? "1" : "0");
      formData.append("is_for_wall", isForWall ? "1" : "0");

      fileValues.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}api/upload_product`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      navigate(-1);
      setToast("success", `Product Import Successfully`);
    } catch (error: any) {
      setToast("error", error.response.data.message);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            navigate(LOGOUT);
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (selected: any | null) => {
    setSelectedOptions(selected);
  };

  return (
    <Container>
      <Card className="mt-5">
        <Card.Header>Upload Product</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="w-100">
              <Form.Label>Select Organization</Form.Label>
              <SelectField
                options={organizations}
                value={selectedOptions}
                onChange={handleOptionChange}
              />
              {organizationsError && (
                <div className="invalid-feedback d-block mb-2">
                  {organizationsError}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                controlId="productType"
                className="pt-2 upload-product"
              >
                <Form.Label className="fs-6">Product Type</Form.Label>
                <SelectField
                  options={productType}
                  value={selectedProductType}
                  onChange={handleProductTypeChange}
                />
                {productTypeError && (
                  <div className="invalid-feedback d-block mb-2">
                    {productTypeError}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                controlId="productCategory"
                className="pt-2 upload-product"
              >
                <Form.Label className="fs-6">Product Category</Form.Label>
                <SelectField
                  options={productCategory}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                />
                {productCategoryError && (
                  <div className="invalid-feedback d-block mb-2">
                    {productCategoryError}
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                controlId="productSize"
                className="pt-2 upload-product"
              >
                <Form.Label className="fs-6">Product Size</Form.Label>
                <SelectField
                  options={productSize}
                  value={selectedProductSize}
                  onChange={handleProductSizeChange}
                />
                {productSizeError && (
                  <div className="invalid-feedback d-block mb-2">
                    {productSizeError}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                controlId="productColor"
                className="pt-2 upload-product"
              >
                <Form.Label className="fs-6">Product Color</Form.Label>
                <SelectField
                  options={productColor}
                  value={selectedColor}
                  onChange={handleColorChange}
                />
                {productColorError && (
                  <div className="invalid-feedback d-block mb-2">
                    {productColorError}
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="d-flex mt-3">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="isForFloor"
                  checked={isForFloor}
                  onChange={handleFloorChange}
                />
                <label htmlFor="isForFloor">Is for Floor</label>
              </div>

              <div className="custom-checkbox mx-4">
                <input
                  type="checkbox"
                  id="isForWall"
                  checked={isForWall}
                  onChange={handleWallChange}
                />
                <label htmlFor="isForWall">Is for Wall</label>
              </div>
            </div>

            <div>
              {floorWallError && (
                <Form.Text className="text-danger">{floorWallError}</Form.Text>
              )}
            </div>
            <div className="w-100">
              <Form.Group className="pt-2">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/jpeg, image/png, image/jpg, image/gif"
                  multiple
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const selectedImages =
                      event.currentTarget.files &&
                      Array.from(event.currentTarget.files);
                    if (selectedImages) {
                      setFileValues(selectedImages);
                      selectedImages.forEach((selectedImage, index) => {
                        let reader = new FileReader();
                        reader.onload = function (e) {
                          document
                            .getElementById(`preview-${index}`)!
                            .setAttribute("src", e.target!.result as string);
                        };
                        reader.readAsDataURL(selectedImage);
                      });
                    }
                  }}
                />
                {fileError && (
                  <Form.Text className="text-danger">{fileError}</Form.Text>
                )}
              </Form.Group>
              <Row>
                {fileValues.map((file, index) => (
                  <Col key={index} md={6}>
                    <div
                      className="view-image-div"
                      style={{
                        position: "relative",
                        display: file ? "block" : "none",
                      }}
                    >
                      <img
                        id={`preview-${index}`}
                        src=""
                        className="view-image"
                        alt="Preview"
                        style={{
                          display: file ? "block" : "none",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className=" d-flex justify-content-end text-center d-grid gap-2 mb-4">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-sm back-btn me-2 mt-4 fs-6 text-white ps-5 pe-5"
                type="button"
              >
                Back
              </button>
              <button
                type="submit"
                className="mt-4 btn addnew-btn btn-sm ps-5 pe-5"
                disabled={loading}
              >
                {loading ? "Uploading..." : "Submit"}
              </button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ProductImportFile;
